import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '../../../../../themes/Themes.types';

export const AppVersionWrapper = styled.div<ThemedStyledComponent>(
  ({ theme }: ThemedStyledComponent) => css`
    margin-left: ${theme.spacing(1)};
    padding: 6px 4px;

    ${theme.breakpoints.up('sm')} {
      padding: 6px 8px;
    }
  `,
);
