import { throwIfNullable } from '@atrigam/atrigam-types';

import {
  RedirectRoute,
  RedirectRouteOptions,
  RouteParameters,
  RouteQuery,
  RouteTypes,
} from '../Router.types';

interface CreateRedirectRoute {
  // no params, no query
  (options: RedirectRouteOptions<undefined, undefined>): RedirectRoute<undefined, undefined>;

  // only params
  <Parameters extends RouteParameters>(
    options: RedirectRouteOptions<Parameters, undefined>,
  ): RedirectRoute<Parameters, undefined>;

  // only query
  <Query extends RouteQuery>(
    options: RedirectRouteOptions<undefined, Query>,
  ): RedirectRoute<undefined, Query>;

  // params + query
  <Parameters extends RouteParameters, Query extends RouteQuery>(
    options: RedirectRouteOptions<Parameters, Query>,
  ): RedirectRoute<Parameters, Query>;
}

export const createRedirectRoute: CreateRedirectRoute = <
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
>(
  options: RedirectRouteOptions<Parameters, Query>,
): RedirectRoute<Parameters, Query> => {
  let routeName: string | undefined;

  const { pattern, onRedirect, scope } = options;

  return {
    type: RouteTypes.RedirectRoute,
    path: pattern,
    onRedirect,
    scope,
    get name() {
      throwIfNullable('Router.createRoute::Route.name', routeName);
      return routeName;
    },

    // this will be called to set the route name
    // @see createRouter.ts
    setName: (name: string) => {
      routeName = name;
    },
  };
};
