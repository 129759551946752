/* eslint-disable @typescript-eslint/no-explicit-any */
import { Brand, WithoutBrand } from '@atrigam/atrigam-types';
import { Router as Router5, SubscribeState } from 'router5';

// eslint-disable-next-line @typescript-eslint/ban-types
export type RouteParameters = Brand<{}, 'RouteParams'>;
// eslint-disable-next-line @typescript-eslint/ban-types
export type RouteQuery = Brand<{}, 'RouteQuery'>;
// eslint-disable-next-line @typescript-eslint/ban-types
export type RouteStore = Brand<{}, 'RouteStore'>;

// Each route needs to have its `scope` defined in its RouteOptions.
// This scope is used to determine what RouteLayout will be used
// and what RouteScopeData is available for each route.
export enum RouteScope {
  LoggedIn = 'LoggedIn',
  LoggedOut = 'LoggedOut',
}

export enum RouteScopeData {
  Default = 'Default',
}

// set getRouteLayout()
export enum RouteLayout {
  Default = 'Default',
  Unauthorized = 'Unauthorized',
}

// Route Types
export enum RouteTypes {
  Route = 'Route',
  RedirectRoute = 'RedirectRoute',
}

// needs to be in sync with getRouteLayout
export type RouteScopesWithDefaultLayout = RouteScope.LoggedIn;

export type RouteScopesWithUnauthorizedLayout = Exclude<RouteScope, RouteScopesWithDefaultLayout>;

interface RouteLinkOptionsWithParametersAndQuery<
  Parameters extends RouteParameters,
  Query extends RouteQuery,
> {
  params: WithoutBrand<Parameters>;
  query: WithoutBrand<Query>;
  hash?: string;
}

interface RouteLinkOptionsWithParametersNoQuery<Parameters extends RouteParameters> {
  params: WithoutBrand<Parameters>;
  hash?: string;
}

interface RouteLinkOptionsWithQueryNoParameters<Query extends RouteQuery> {
  query: WithoutBrand<Query>;
  hash?: string;
}

interface RouteLinkOptionsNoParametersNoQuery {
  hash?: string;
}

type RouteLinkOptions<
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
> = Parameters extends RouteParameters
  ? Query extends RouteQuery
    ? /* params: yes | query: yes */ RouteLinkOptionsWithParametersAndQuery<Parameters, Query>
    : /* params: yes | query: no */ RouteLinkOptionsWithParametersNoQuery<Parameters>
  : Query extends RouteQuery
    ? /* params: no | query: yes */ RouteLinkOptionsWithQueryNoParameters<Query>
    : /* params: no | query: no */ RouteLinkOptionsNoParametersNoQuery;

export interface RoutePath<
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
> {
  pattern: string;
  getLink: Parameters extends RouteParameters
    ? Query extends RouteQuery
      ? /* params: yes | query: yes */ (linkOptions: RouteLinkOptions<Parameters, Query>) => string
      : /* params: yes | query: no */ (
          linkOptions: RouteLinkOptions<Parameters, undefined>,
        ) => string
    : Query extends RouteQuery
      ? /* params: no | query: yes */ (linkOptions: RouteLinkOptions<undefined, Query>) => string
      : /* params: no | query: no */ (
          linkOptions?: RouteLinkOptions<undefined, undefined>,
        ) => string;
}

export type AnyRoute = Route<any, any, any>;
export type AnyRedirectRoute = RedirectRoute<any, any>;
export type AnyRoutePath = RoutePath<any, any>;
export type AnyRouter = Router;

interface RouteMeta {
  preventContentOverflowAuto: boolean;
  fullWidth: boolean;
  noPadding: boolean;
}

export type PreloadableComponent = React.LazyExoticComponent<React.ComponentType<any>>;
export type RouteComponent = React.ComponentType<any> | PreloadableComponent;

export type RouteOptionsComponents<Scope extends RouteScope> =
  Scope extends RouteScopesWithDefaultLayout
    ? /* only the authorized layout has a full range of slots */ {
        main: RouteComponent;

        breadcrumbs?: RouteComponent | 'none';
        header?: RouteComponent | 'none';
        navi?: RouteComponent | 'none';
      }
    : /* the unauthorized layout can only use the main slot */ {
        main: RouteComponent;
      };

export type AllRouteSlots = keyof RouteOptionsComponents<RouteScopesWithDefaultLayout>;
export type AllRouteComponents = {
  [P in AllRouteSlots]: P extends 'main' ? RouteComponent : RouteComponent | undefined;
};

export type RouteComponents<Scope extends RouteScope> = Scope extends RouteScopesWithDefaultLayout
  ? {
      // AUTHORIZED layout has a full range of slots
      // main is required, every other slot is optional
      [P in AllRouteSlots]: P extends 'main' ? RouteComponent : RouteComponent | undefined;
    }
  : {
      // UNAUTHORIZED layout can only use the main slot
      // every other slot is undefined
      [P in AllRouteSlots]: P extends 'main' ? RouteComponent : undefined;
    };

export interface LifecycleData<
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
> {
  name: string;
  pattern: string;
  parameters: Parameters;
  query: Partial<Query>;
  layout: RouteLayout;
  scope: RouteScope;
  hasScopeData: (scopeData: RouteScopeData) => boolean;

  meta?: Partial<RouteMeta>;
}

export interface LifecycleRedirectData<
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
> {
  name: string;
  pattern: string;
  parameters: Parameters;
  query: Partial<Query>;
}

export interface RouterRedirect<
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
> {
  name: string;
  params: Parameters extends RouteParameters
    ? Query extends RouteQuery
      ? WithoutBrand<Parameters> & WithoutBrand<Query>
      : WithoutBrand<Parameters>
    : Query extends RouteQuery
      ? WithoutBrand<Query>
      : undefined;
}

export type LifecycleCanEnter<
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
> = (
  options: LifecycleData<Parameters, Query>,
) => boolean | RouterRedirect<any, any> | Promise<boolean | RouterRedirect<any, any>>;

export type LifecycleBeforeEnter<
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
  Store extends RouteStore | undefined,
> = (options: LifecycleData<Parameters, Query>) => void | Store | Promise<Store> | Promise<void>;

export type LifecycleAfterLeave<
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
> = (options: LifecycleData<Parameters, Query>) => void;

export type LifecycleRedirect<
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
> = (options: {
  name: string;
  pattern: string;
  parameters: Parameters;
  query: Partial<Query>;
}) => RouterRedirect<any, any> | Promise<RouterRedirect<any, any>>;

export type RouteData<
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
> = Parameters extends RouteParameters
  ? Query extends RouteQuery
    ? /* params: yes | query: yes */ {
        parameters: WithoutBrand<Parameters>;
        query: Partial<WithoutBrand<Query>>;
      }
    : /* params: yes | query: no */ {
        parameters: WithoutBrand<Parameters>;
      }
  : Query extends RouteQuery
    ? /* params: no | query: yes */ {
        query: Partial<WithoutBrand<Query>>;
      }
    : /* params: no | query: no */ never;

interface RouteOptionsCommon<
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
  Store extends RouteStore | undefined,
> {
  pattern: string;
  title: () => string;

  // optional
  meta?: Partial<RouteMeta>;
  canEnter?: LifecycleCanEnter<Parameters, Query>;
  onBeforeEnter?: LifecycleBeforeEnter<Parameters, Query, Store>;
  onAfterLeave?: LifecycleAfterLeave<Parameters, Query>;
  syncDataFromURL?: (data: RouteData<Parameters, Query>) => void;
  preloadableComponents?: Record<string, PreloadableComponent>;
  getBreadcrumbs?: (options: {
    parameters: Record<string, unknown>;
    query: Record<string, unknown>;
  }) => RouteBreadcrumb[];
  getStore?: () => Store;
}

export interface RouteOptionsForAuthorizedLayout<
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
  Store extends RouteStore | undefined,
> extends RouteOptionsCommon<Parameters, Query, Store> {
  components: RouteOptionsComponents<RouteScopesWithDefaultLayout>;
  scope: RouteScopesWithDefaultLayout;
}

export interface RouteOptionsForUnauthorizedLayout<
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
  Store extends RouteStore | undefined,
> extends RouteOptionsCommon<Parameters, Query, Store> {
  components: RouteOptionsComponents<RouteScopesWithUnauthorizedLayout>;
  scope: RouteScopesWithUnauthorizedLayout;
}

export type RouteOptions<
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
  Store extends RouteStore | undefined,
> =
  | RouteOptionsForAuthorizedLayout<Parameters, Query, Store>
  | RouteOptionsForUnauthorizedLayout<Parameters, Query, Store>;

export interface RedirectRouteOptions<
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
> {
  scope: RouteScope;
  pattern: string;
  onRedirect: LifecycleRedirect<Parameters, Query>;
}

export type RoutePathPattern = string | (() => string);

export interface RoutePathOptions<Parameters_ extends RouteParameters | undefined> {
  allowNonSSL?: boolean;
  pattern: RoutePathPattern;
  defaultParameters?: Partial<Parameters_>;
}

export interface RouteBreadcrumb {
  label: string;
  link?: string;
}

export interface Route<
  Parameters_ extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
  Store extends RouteStore | undefined,
> {
  type: RouteTypes.Route;
  name: string;
  path: string;
  components: AllRouteComponents;
  title: () => string;
  preload: () => void;
  scope: RouteScope;
  layout: RouteLayout;
  hasScopeData: (scopeData: RouteScopeData) => boolean;

  meta: undefined | Partial<RouteMeta>;
  canEnter: undefined | LifecycleCanEnter<Parameters_, Query>;
  onBeforeEnter: undefined | LifecycleBeforeEnter<Parameters_, Query, Store>;
  onAfterLeave: undefined | LifecycleAfterLeave<Parameters_, Query>;
  syncDataFromURL: undefined | ((data: RouteData<Parameters_, Query>) => void);

  setName: (name: string) => void;
  getBreadcrumbs?: (options: {
    parameters: Record<string, unknown>;
    query: Record<string, unknown>;
  }) => RouteBreadcrumb[];
  getStore?: () => Store;
}

export interface RedirectRoute<
  Parameters_ extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
> {
  type: RouteTypes.RedirectRoute;
  name: string;
  path: string;
  onRedirect: LifecycleRedirect<Parameters_, Query>;
  setName: (name: string) => void;
  scope: RouteScope;
}

export interface CurrentRoute<
  Routes extends Record<
    string,
    Route<Parameters_, Query, Store> | RedirectRoute<Parameters_, Query>
  >,
  RouteName extends Extract<keyof Routes, string>,
  Parameters_ extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
  Store extends RouteStore | undefined,
> {
  type: RouteTypes;
  name: RouteName;
  pathname: string;
  search: string;
  hash: string;
  pattern: string;
  title: string;
  scope: RouteScope;
  layout: RouteLayout;
  parameters: Record<string, unknown>;
  query: Record<string, unknown>;
  meta: Route<Parameters_, Query, Store>['meta'];
  breadcrumbs?: RouteBreadcrumb[];
  preload: Route<Parameters_, Query, Store>['preload'];
  hasScopeData: Route<Parameters_, Query, Store>['hasScopeData'];
  components: AllRouteComponents;
  getStore?: () => Store;
}

export interface PausedNavigationControls {
  resume: () => void;
  cancel: () => void;
}

export type TryNavigationWhenPausedCallback = (controls: PausedNavigationControls) => void;

export interface RouterOptions<
  Routes extends Record<string, AnyRoute | AnyRedirectRoute>,
  RouteName extends Extract<keyof Routes, string>,
> {
  routes: Routes;
  fallbackRoute: RouteName;
  getTryNavigationWhenPausedCallback: () => TryNavigationWhenPausedCallback | undefined;
}

export interface RouterServiceOptions<
  Routes extends Record<string, AnyRoute | AnyRedirectRoute>,
  RouteName extends Extract<keyof Routes, string>,
> {
  routes: Routes;
  fallbackRoute: RouteName;
}

export type Router = Router5;

export type RouterSubscribeState = SubscribeState;
