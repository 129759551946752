/* eslint-disable @typescript-eslint/naming-convention */
export const atrigamColors = {
  green: {
    '1c': '#f1ef9c',
    '2c': '#e1e66b',
    '3c': '#D4DE25', // from logo
    '4c': '#acb937',
    '5c': '#879639',
    '3a': '#84c443',
    '3b': '#9CD51F', // colorpicking from pdf
    '3d': '#E7DE40', // colorpicking from pdf
    '3e': '#fdde20',
  },
  blue: {
    '1c': '#31308e',
    '2c': '#333366',
    '3c': '#181545',
    '4c': '#181432',
    '5c': '#110e19',
    '3a': '#1b5e46',
    '3b': '#193f46',
    '3d': '#491441',
    '3e': '#6c1542',
  },
};
