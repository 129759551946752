import type { AtrigamUserProfileProfilePicture, URI } from '@atrigam/atrigam-types';
import { action, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

interface Options {
  picture: AtrigamUserProfileProfilePicture;
}

export class UserAvatarModel {
  @persist
  @observable
  id?: string;

  @persist
  @observable
  uri?: URI;

  @persist
  @observable
  miduri?: URI;

  @persist
  @observable
  minuri?: URI;

  @persist
  @observable
  thumburi?: URI;

  constructor(data?: Options) {
    makeObservable(this);

    if (data) {
      this.update(data.picture);
    }
  }

  @action
  update = (picture: AtrigamUserProfileProfilePicture) => {
    this.id = picture.profilePicture.id;
    this.uri = picture.profilePicture.uri;
    this.miduri = picture.profilePicture.miduri;
    this.minuri = picture.profilePicture.minuri;
    this.thumburi = picture.profilePicture.thumburi;
  };
}
