import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamModelsModelFlow,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
} from '@atrigam/atrigam-types';
import { get } from 'firebase/database';

import { getFlowModelQueryReference } from '../queryReferences/getFlowModel.query.reference';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  taskFlow: AtrigamTaskFlowName;
  universe: AtrigamUniverseName;
}

export const getFlowModelQuery = async (options: Options) => {
  const reference = getFlowModelQueryReference(options);

  const snapshot = await get(reference);
  return snapshot.val() as AtrigamModelsModelFlow | undefined;
};
