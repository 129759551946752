import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';

import { LoginPageAsync } from '../../pages/Login/Login.page.async';
import { RouteParameters, RouteQuery, RouteScope } from '../../services/Router/Router.types';
import { createRoute } from '../../services/Router/helpers/createRoute';

import { pattern } from './login.path';

export const LoginRoute = createRoute<RouteParameters, RouteQuery>({
  title: () => 'Login',
  components: {
    main: LoginPageAsync,
  },
  pattern,
  scope: RouteScope.LoggedOut,
  onBeforeEnter: () => {
    void track({
      event: AtrigamAnalyticEvents.LOGIN_EnterLogin,
      screen: AtrigamAnalyticScreens.Login,
    });
  },
});
