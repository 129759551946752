import { List, ListSubheader, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';

import { ThemeContext } from '../../../../../../../../contexts/ThemeContext';
import { reactive } from '../../../../../../../../helpers/reactive';
import { Typography } from '../../../../../../../../primitive/Typography/Typography.style';
import { Registry } from '../../../../../../../../services/Registry/Registry';
import { LockApp } from '../../../LockApp/LockApp';

import { SettingsMenuItemWrapper } from './styles/SettingsMenuItemWrapper.style';
import { SettingsWrapper } from './styles/SettingsWrapper.style';

const SettingsMenuComponent: React.FC = () => {
  const { theme, toggleTheme } = React.useContext(ThemeContext);
  const { isLocked } = Registry.get('appStore');

  return (
    <SettingsWrapper>
      <List>
        <ListSubheader>Settings</ListSubheader>

        <SettingsMenuItemWrapper>
          <Typography variant="body2" id="language" color={isLocked ? 'error' : undefined}>
            Lock App
          </Typography>

          <LockApp showLabel={false} />
        </SettingsMenuItemWrapper>

        <SettingsMenuItemWrapper>
          <Typography variant="body2" id="language">
            Theme
          </Typography>

          <ToggleButtonGroup value={theme} onChange={toggleTheme} size="small" exclusive>
            <ToggleButton value="light" key="light">
              Light
            </ToggleButton>

            <ToggleButton value="dark" key="dark">
              Dark
            </ToggleButton>
          </ToggleButtonGroup>
        </SettingsMenuItemWrapper>
      </List>
    </SettingsWrapper>
  );
};

export const SettingsMenu = reactive(SettingsMenuComponent);
