import queryString from 'query-string';

export const parseSearchQuery = (searchQuery?: string): Record<string, unknown> => {
  if (!searchQuery) {
    return {};
  }

  return queryString.parse(searchQuery, {
    arrayFormat: 'bracket',
  });
};
