import queryString, { Stringifiable, StringifiableRecord } from 'query-string';

import { toJS } from '../../../helpers/toJS';

export const stringifySearchQuery = (search?: Record<string, unknown>): string => {
  if (!search) {
    return '';
  }

  // make sure search object is Stringifiable
  const rawQuery = toJS(search);
  // export type Stringifiable = string | boolean | number | null | undefined;
  const query = Object.entries(rawQuery).reduce((result, [key, value]) => {
    if (isStringifiable(value)) {
      result[key] = value;
    }

    if (Array.isArray(value) && value.every((arrayValue) => isStringifiable(arrayValue))) {
      result[key] = value;
    }

    return result;
  }, {} as StringifiableRecord);

  const searchQuery = queryString.stringify(query, { arrayFormat: 'bracket' });
  if (!searchQuery) {
    return '';
  }

  return `?${searchQuery}`;
};

const isStringifiable = (value: unknown): value is Stringifiable => {
  return value === null || ['string', 'boolean', 'number', 'undefined'].includes(typeof value);
};
