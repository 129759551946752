import { RouteParameters, RouteQuery } from '../../services/Router/Router.types';
import { createRoutePath } from '../../services/Router/helpers/createRoutePath';

export const pattern = '/universeRegistrations';

export interface Query extends RouteQuery {
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  search?: string;
  group?: 'all' | 'production' | 'sandbox';
}

export const UniverseRegistrationListPath = createRoutePath<RouteParameters, Query>({ pattern });
