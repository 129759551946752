export const isSemverGreater = (oldVersion: string, newVersion: string) => {
  const semverA = extractSemver(oldVersion);
  const semverB = extractSemver(newVersion);

  if (!semverA || !semverB) {
    return;
  }

  return (
    (Object.keys(semverA) as (keyof typeof semverA)[]).reduce((status, key) => {
      if (status === 'lower' || status === 'bigger') {
        return status;
      }

      if (semverA[key] === semverB[key]) {
        return 'same';
      }

      if (semverA[key] > semverB[key]) {
        return 'lower';
      }

      return 'bigger';
    }, 'same') === 'bigger'
  );
};

const extractSemver = (version: string) => {
  const match = version.match(/^(\d+)\.(\d+)\.(\d+)$/);
  if (!match) {
    return;
  }

  return {
    major: Number(match[1]),
    minor: Number(match[2]),
    patch: Number(match[3]),
  };
};
