import {
  ATRIGAM_PROFILE_AREA,
  ATRIGAM_PROFILE_ENVIRONMENT,
  ATRIGAM_PROFILE_TASKFLOW,
  ATRIGAM_PROFILE_UNIVERSE,
} from '@atrigam/atrigam-types';

import { getFlowModelQuery } from './getFlowModel.query';

export const getUserProfileFlowModelQuery = () => {
  return getFlowModelQuery({
    area: ATRIGAM_PROFILE_AREA,
    environment: ATRIGAM_PROFILE_ENVIRONMENT,
    taskFlow: ATRIGAM_PROFILE_TASKFLOW,
    universe: ATRIGAM_PROFILE_UNIVERSE,
  });
};
