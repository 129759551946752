/* eslint-disable @typescript-eslint/no-unsafe-return */
import { ObservableMap } from 'mobx';

import {
  RegistryType,
  Services,
  ServiceCreator,
  ServiceInstance,
  InternalRegistryType,
} from '../Registry.types';

import { DestructibleService } from './DestructibleService';

const currentlyCreatingServices = new Set();

export const createRegistry = <S extends Services>(testing: boolean): InternalRegistryType<S> => {
  const serviceCreators = new Map<keyof S, ServiceCreator<keyof S, S>>();
  const serviceInstances = new ObservableMap<keyof S, ServiceInstance<keyof S, S>>();

  const set: RegistryType<S>['set'] = (key, creator) => {
    if (serviceCreators.has(key)) {
      throw new Error(
        `Registry entry "${key as string}" is already set, overwriting is not allowed.`,
      );
    }

    serviceCreators.set(key, creator);
  };

  const get: RegistryType<S>['get'] = (key) => {
    const existingInstance = serviceInstances.get(key);
    if (existingInstance) {
      return existingInstance;
    }

    const createInstance = serviceCreators.get(key);
    if (!createInstance) {
      throw new Error(`Registry entry "${key as string}" was not defined yet.`);
    }

    if (currentlyCreatingServices.has(key)) {
      throw new Error(`Registry is currently creating "${key as string}"!`);
    }

    currentlyCreatingServices.add(key);
    const createdInstance = createInstance();
    currentlyCreatingServices.delete(key);

    if (!createdInstance) {
      throw new Error(`Registry entry "${key as string}" failed to instantiate.`);
    }
    serviceInstances.set(key, createdInstance);

    return createdInstance;
  };

  const hasCreator: InternalRegistryType<S>['hasCreator'] = (key) => {
    // .hasCreator() is only meant to be used in tests
    // this method is forbidden on purpose in prod/dev
    if (!testing) {
      throw new Error(
        `Registry.hasCreator("${key as string}") was called in a non-test environment.`,
      );
    }

    return serviceCreators.has(key);
  };

  const hasInstance: RegistryType<S>['hasInstance'] = (key) => {
    return serviceInstances.has(key);
  };

  const clear: RegistryType<S>['clear'] = (key) => {
    // call `_destruct` on instance if it exists before clearing it
    const instance = serviceInstances.get(key);
    if (instance && typeof (instance as DestructibleService)._destruct === 'function') {
      (instance as DestructibleService)._destruct();
    }

    serviceInstances.delete(key);
  };

  const remove: InternalRegistryType<S>['remove'] = (key) => {
    // .remove() is only meant to be used in tests
    // this method is forbidden on purpose in prod/dev
    if (!testing) {
      throw new Error(`Registry.remove("${key as string}") was called in a non-test environment.`);
    }

    const creator = serviceCreators.get(key);
    if (!creator) {
      return;
    }

    clear(key);

    serviceCreators.delete(key);
  };

  return {
    set,
    get,
    hasCreator,
    hasInstance,
    clear,
    remove,
  };
};
