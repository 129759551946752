import { Drawer, DrawerProps } from '@mui/material';
import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '../../../../../themes/Themes.types';

const drawerWidth = 240;

export const NaviWrapper = styled(Drawer)<ThemedStyledComponent>`
  flex-shrink: 0;
  white-space: nowrap;
  width: ${drawerWidth}px;

  ${({ theme, open }: ThemedStyledComponent & DrawerProps) => {
    if (open) {
      return css`
        &,
        & > .MuiDrawer-paper {
          transition: ${theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          })};
          width: ${drawerWidth}px;
        }
      `;
    }

    return css`
      &,
      & > .MuiDrawer-paper {
        transition: ${theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        })};
        overflow-x: hidden;
        width: calc(${theme.spacing(7)} + 40px);
      }
    `;
  }}
`;
