/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Nullable } from './utility.types';

// see https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#assertion-functions
export function throwIfNullable<T extends any | Nullable>(
  key: string,
  value: T,
): asserts value is NonNullable<T> {
  if (value === null || value === undefined) {
    throw new Error(`Expected "${key}" not to be nullable, but received "${String(value)}."`);
  }
}
