import {
  AtrigamApplicationVersion,
  AtrigamFirestorePlatformStatusVersions,
  throwIfNullable,
} from '@atrigam/atrigam-types';

import { createAtrigamApplicationVersionPath } from '../firestore/paths/createAtrigamApplicationVersionPath';
import { WatcherFunction } from '../types/watchers.types';
import { watcherServiceEu } from '../watcherService';

interface Options {
  app: keyof AtrigamFirestorePlatformStatusVersions;
}

export const watchApplicationVersion: WatcherFunction<AtrigamApplicationVersion, Options> = ({
  app,
  onUpdate,
  onError,
}) => {
  const firestoreService = watcherServiceEu.get();
  const path = createAtrigamApplicationVersionPath({ app });

  return firestoreService.subscribeToDocument<AtrigamApplicationVersion>({
    path,
    onSnapshot: (snapshot, key) => {
      const document = snapshot.data();
      throwIfNullable('application version is not available', document);

      void onUpdate(document, key);
    },
    onError,
  });
};
