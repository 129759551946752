import { UnhandledCaseError } from '@atrigam/atrigam-types';

import { RouteScope, RouteScopeData } from '../Router.types';

interface Options {
  scope: RouteScope;
}

// this established the connection between each RouteScope
// and the RouteScopeData that will be available for each route
//
// Each RouteScope may map to multiple items of RouteScopeData
// that's available for each route.
export const getRouteScopeData = (options: Options): RouteScopeData[] => {
  const { scope } = options;

  switch (scope) {
    case RouteScope.LoggedIn: {
      return [RouteScopeData.Default];
    }

    // no scope data is available
    case RouteScope.LoggedOut: {
      return [];
    }

    default: {
      throw new UnhandledCaseError(scope);
    }
  }
};
