import { UID } from '@atrigam/atrigam-types';

import { watcherServiceAdmin } from '../index.js';
import {
  AtrigamAdminPlatformAdmin,
  AtrigamAdminPlatformAdminPath,
} from '../types/atrigam-admin.js';

interface Options {
  uid: UID;
  onUpdate: (platformAdmin: AtrigamAdminPlatformAdmin | undefined) => void;
}

export const watchPlatformAdmin = ({ uid, onUpdate }: Options) => {
  const firestoreService = watcherServiceAdmin.get();
  const path: AtrigamAdminPlatformAdminPath = `/platformAdmins/${uid}`;

  return firestoreService.subscribeToDocument<AtrigamAdminPlatformAdmin>({
    path,
    onSnapshot: (snapshot) => {
      const platformAdmin = snapshot.data();

      onUpdate(platformAdmin);
    },
  });
};
