import { getBrowser } from './getBrowser';

export interface BrowserInfo {
  readonly name: string;
  readonly version: string | null;
  readonly os: string | null;
}

export const getBrowserInfo = (): BrowserInfo => {
  const browser = getBrowser();

  return {
    name: browser.name,
    version: browser.version,
    os: browser.os,
  };
};
