import styled from 'styled-components';

import { ThemedStyledComponent } from '../../../../../themes/Themes.types';

// todo try to fix the types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ToolbarSpacer = styled.div(({ theme }: ThemedStyledComponent): any => ({
  ...theme.mixins.toolbar,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
