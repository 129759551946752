import { AtrigamApps, AtrigamServerFunction, UID, throwIfNullable } from '@atrigam/atrigam-types';
import { FirebaseApp, initializeApp } from 'firebase/app';

import { firestoreConfig } from './config/firestore.config';
import {
  AtrigamFirestoreActivityBaseUser,
  AtrigamFirestoreActivityMobileConfig,
  AtrigamFirestoreActivityWebConfig,
} from './firestore.types';
import { AtrigamAppEnvironment } from './tracking.types';
import versionJson from './version.json';

interface MobileConfig extends AtrigamFirestoreActivityMobileConfig {
  app: AtrigamApps.Mobile;
  appEnvironment?: AtrigamAppEnvironment;
  appVersion?: string;
}

interface WebConfig extends AtrigamFirestoreActivityWebConfig {
  app: AtrigamApps.Web | AtrigamApps.Administration | AtrigamApps.Modeler;
  appEnvironment?: AtrigamAppEnvironment;
  appVersion?: string;
}

interface ServerFunctionConfig {
  app: AtrigamApps.ServerFunction;
  serverFunction: AtrigamServerFunction;
  region?: string;
  appEnvironment?: AtrigamAppEnvironment;
  appVersion?: string;
}

type AppConfig = MobileConfig | WebConfig | ServerFunctionConfig;

interface UserConfig {
  uid: UID | 'unregistered';
  user?: AtrigamFirestoreActivityBaseUser | null;
}

interface AdditionalConfig {
  isEnabled: boolean;
}

type ContextData = Record<string, unknown>;

export type TrackingConfiguration = AppConfig & UserConfig & AdditionalConfig;

export let trackingConfiguration: (TrackingConfiguration & { trackingVersion: string }) | undefined;

export let firebaseApp: FirebaseApp | undefined;

let setupComplete = false;

export const setupTracking = (options: TrackingConfiguration) => {
  if (setupComplete) {
    throw new Error(
      'Setup can only be run once, use updateTracking if you want to change something!',
    );
  }

  trackingConfiguration = {
    // default to Production
    appEnvironment: AtrigamAppEnvironment.Production,
    ...options,
    trackingVersion: versionJson.version,
  };

  if (
    trackingConfiguration.uid !== 'unregistered' &&
    trackingConfiguration.user &&
    !trackingConfiguration.user.uid
  ) {
    trackingConfiguration.user.uid = trackingConfiguration.uid;
  }

  // initialize app only if enabled
  if (options.isEnabled && !firebaseApp) {
    firebaseApp = initializeApp(firestoreConfig, 'atrigam-tracking');
  }

  setupComplete = true;
};

export const updateTracking = (options: Partial<UserConfig & AdditionalConfig> & ContextData) => {
  throwIfNullable('Cannot updateConfig, use setupTracking first!', trackingConfiguration);

  // initialize app only if enabled
  if (!trackingConfiguration.isEnabled && options.isEnabled && !firebaseApp) {
    firebaseApp = initializeApp(firestoreConfig, 'atrigam-tracking');
  }

  trackingConfiguration = {
    ...trackingConfiguration,
    ...options,
  };

  if (
    trackingConfiguration.uid !== 'unregistered' &&
    trackingConfiguration.user &&
    !trackingConfiguration.user.uid
  ) {
    trackingConfiguration.user.uid = trackingConfiguration.uid;
  }
};
