import { Paper } from '@mui/material';
import styled from 'styled-components';

import { ThemedStyledComponent } from '../../../themes/Themes.types';

export const Content = styled(Paper)<ThemedStyledComponent>(
  ({ theme }: ThemedStyledComponent) => `
  flex: 1;
  padding: ${theme.spacing(4)};
`,
);
