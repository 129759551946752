import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import React from 'react';

import { reactive } from '../../helpers/reactive';

import { useGenericModalState } from './hooks/useGenericModalState';

interface Properties {
  actions: (properties: { onCancel: () => void; onSubmit: () => void }) => React.ReactNode;
  fullWidth?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  message?: string;
  onAfterAll?: () => void;
  onCancel?: () => void;
  onSubmit: () => void;
  title: string;
  trigger: (properties: {
    openModal: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  }) => React.ReactNode;
}

const GenericModalComponent: React.FC<Properties> = ({
  actions,
  fullWidth,
  maxWidth,
  message,
  onAfterAll,
  onCancel,
  onSubmit,
  title,
  trigger,
}) => {
  const { isModalOpen, handleCloseModal, handleOpenModal } = useGenericModalState();

  const handleSubmit = React.useCallback(() => {
    onSubmit();
    handleCloseModal();

    if (onAfterAll) {
      onAfterAll();
    }
  }, [onSubmit, handleCloseModal, onAfterAll]);

  const handleCancel = React.useCallback(() => {
    if (onCancel) {
      onCancel();
    }

    handleCloseModal();

    if (onAfterAll) {
      onAfterAll();
    }
  }, [onCancel, handleCloseModal, onAfterAll]);

  return (
    <>
      {trigger({ openModal: handleOpenModal })}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isModalOpen}
        onClose={() => handleCloseModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            dangerouslySetInnerHTML={message ? { __html: message } : undefined}
          />
        </DialogContent>

        <DialogActions>{actions({ onCancel: handleCancel, onSubmit: handleSubmit })}</DialogActions>
      </Dialog>
    </>
  );
};

export const GenericModal = reactive(GenericModalComponent);
