import React from 'react';

import { reactive } from '../../helpers/reactive';

import { GenericButton, GenericButtonProperties } from './GenericButton';

const PrimaryButtonComponent: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  GenericButtonProperties
> = ({ ...properties }, reference) => (
  <GenericButton color="primary" {...properties} ref={reference} type="submit" />
);

export const PrimaryButton = reactive(React.forwardRef(PrimaryButtonComponent));
