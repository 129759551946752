import { RouteParameters, RouteQuery } from '../../services/Router/Router.types';
import { createRoutePath } from '../../services/Router/helpers/createRoutePath';

export const pattern = '/users';

export interface Query extends RouteQuery {
  sortField?: string;
  rowsPerPage?: string;
  page?: string;
  search?: string;
  startAfter?: string;
  endBefore?: string;
  sortOrder?: 'asc' | 'desc';
}

export const UsersListPath = createRoutePath<RouteParameters, Query>({ pattern });
