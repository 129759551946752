/* eslint-disable @typescript-eslint/naming-convention */
export enum AtrigamAnalyticEvents {
  ADMIN_Update_Profile_Email = 'Update_Profile_Email',
  ADMIN_Update_Profile_PhoneNumber = 'Update_Profile_PhoneNumber',
  APP_ErrorBoundary = 'Error_Boundary',
  APP_NewRelease = 'New_Release',
  APP_NewVersionOnFirestore = 'New_Version_On_Firestore',
  APP_UpdatingApp = 'Updating_App',
  CHAT_LoadMoreChatMessages = 'Load_More_Chat_Messages',
  CHAT_OpenChat = 'Open_Chat',
  CHAT_SendChatMessage = 'Send_Chat_Message',
  COMPLETE_PROFILE_StartCompleteProfile = 'Start_Complete_Profile',
  COPY_TASKFLOW_Copy_Task_Flow = 'Copy_Task_Flow',
  COPY_TASKFLOW_Enter_Copy_Task_Flow_Screen = 'Enter_Copy_Task_Flow_Screen',
  DATA_LINK_ActivateDataLink = 'Activate_DataLink',
  DATA_LINK_CopyDataLinkToClipboard = 'Copy_DataLink_To_Clipboard',
  DATA_LINK_CreateNewDataLink = 'Create_New_DataLink',
  DATA_LINK_DeactivateDataLink = 'Deactivate_DataLink',
  DATA_LINK_DeleteDataLink = 'Delete_DataLink',
  DATA_LINK_DownloadExcel = 'Download_Excel',
  DATA_LINK_OpenDataLinkOverview = 'Open_DataLink_Overview',
  DATA_LINK_SwitchEnvironment = 'Switch_Environment',
  DATA_LINK_UpdateDataLink = 'Update_DataLink',
  DATA_MANAGEMENT_EnterDataManagementPage = 'Enter_Data_Management_Page',
  DATA_MANAGEMENT_WipeTesting = 'Wipe_Testing',
  DATA_MANAGEMENT_WipeStaging = 'Wipe_Staging',
  DATA_MANAGEMENT_WipeProduction = 'Wipe_Production',
  DATA_MANAGEMENT_InviteUsersToTesting = 'Invite_Users_To_Testing',
  DATA_MANAGEMENT_InviteUsersToStaging = 'Invite_Users_To_Staging',
  DATA_MANAGEMENT_PrepareStagingEnvironment = 'Prepare_Staging_Environment',
  DATA_MANAGEMENT_UploadDataToTesting = 'Upload_Data_To_Testing',
  DATA_MANAGEMENT_UploadDataToStaging = 'Upload_Data_To_Staging',
  DATA_MANAGEMENT_UploadDataToProduction = 'Upload_Data_To_Production',
  DATA_MANAGEMENT_ArchiveQueueItem = 'Archive_Queue_Item',
  DATA_MANAGEMENT_OpenWebAppFromOverview = 'Open_WebApp_From_Overview',
  DELEGATION_DelegationOverview = 'Delegation_Overview',
  DELEGATION_GrantRole = 'Grant_Role',
  DELEGATION_RemoveAssignment = 'Remove_Assignment',
  DEMO_EndDemoSelection = 'End_Demo_Selection',
  DEMO_SkipDemoSelection = 'Skip_Demo_Selection',
  DEMO_StartDeliveryDemo = 'Start_Delivery_Demo',
  DEMO_StartDemoSelection = 'Start_Demo_Selection',
  DEMO_StartProductDevelopmentDemo = 'Start_Product_Development_Demo',
  DEPLOY_CreateNewVersion = 'Create_New_Version',
  DEPLOY_DeployToProduction = 'Deploy_To_Production',
  DEPLOY_DeployToStaging = 'Deploy_To_Staging',
  DEPLOY_DeployToTesting = 'Deploy_To_Testing',
  DEPLOY_DiscardVersion = 'Discard_Version',
  DEPLOY_EnterDeployPage = 'Enter_Deploy_Page',
  DISPLAY_CURRENCY_SetDisplayCurrency = 'Set_Display_Currency',
  ENVIRONMENT_SWITCHER_CancelSwitchEnvironment = 'Cancel_Switch_Environment',
  ENVIRONMENT_SWITCHER_SwitchEnvironmentToProduction = 'Switch_Environment_To_Production',
  ENVIRONMENT_SWITCHER_SwitchEnvironmentToStaging = 'Switch_Environment_To_Staging',
  ENVIRONMENT_SWITCHER_SwitchEnvironmentToTesting = 'Switch_Environment_To_Testing',
  EXTENSIONS_AutomatedInviteSuccess = 'Automated_Invite_Success',
  EXTENSIONS_ExecuteExtensionsOnWorkitem = 'Execute_Extensions_On_Workitem',
  FIELD_METADATA_OpenFieldMetaData = 'Open_Field_Meta_Data',
  FIELD_NOTIFICATIONS_ActivateFieldUpdateNotification = 'Activate_FieldUpdateNotification',
  FIELD_NOTIFICATIONS_ActivateNoUpdateNotification = 'Activate_NoUpdate_Notification',
  FIELD_NOTIFICATIONS_DeactivateFieldUpdateNotification = 'Deactivate_FieldUpdateNotification',
  FIELD_NOTIFICATIONS_DeactivateNoUpdateNotification = 'Deactivate_NoUpdate_Notification',
  FIELD_NOTIFICATIONS_OpenFieldNotifications = 'Open_FieldNotifications',
  FIELD_NOTIFICATIONS_RemoveNoUpdateNotificationSchedule = 'Remove_NoUpdate_Notification_Schedule',
  FIELD_NOTIFICATIONS_ScheduledNoUpdateNotificationSentSuccessfully = 'Scheduled_NoUpdate_Notification_Sent_Successfully',
  FLOW_EDITOR_OpenFlowEditor = 'Open_Flow_Editor',
  FLOW_EDITOR_RelabelFlow = 'Relabel_Flow',
  FLOW_EDITOR_RenameFlow = 'Rename_Flow',
  FLOW_EDITOR_UnlockFeatureDataTypes = 'Unlock_Feature_DataTypes',
  FLOW_EDITOR_UnlockFeatureRestrictions = 'Unlock_Feature_Restrictions',
  FLOW_EDITOR_UnlockFeatureRoles = 'Unlock_Feature_Roles',
  FX_RATES_API_SyncFailure = 'SyncFailure',
  FX_RATES_API_SyncSuccess = 'SyncSuccess',
  IMAGE_OpenImage = 'Open_Image',
  IMPERSONATION_Impersonate = 'Impersonate',
  IMPERSONATION_Unimpersonate = 'Unimpersonate',
  INSIGHTS_ViewInsights = 'View_Insights',
  INTERACTION_CreateNewWorkItem = 'Create_New_Work_Item',
  INTERACTION_LoadUpdate = 'Load_Update', // new flow update
  INTERACTION_OpenWorkItem = 'Open_Work_Item',
  INTERACTION_RefreshWorkItem = 'Refresh_Work_Item',
  INTERACTION_SaveValue = 'Save_Value',
  INTERACTION_UnsetData = 'Unset_Data',
  INTERACTION_UploadPhoto = 'Upload_Photo',
  INTERACTION_UploadProfilePicture = 'Upload_Profile_Picture',
  INVITE_ChooseFavorite = 'Choose_Favorite',
  INVITE_ChooseFromAddressbook = 'Choose_From_Addressbook',
  INVITE_CreatePendingInvite = 'Create_Pending_Invite',
  INVITE_InvitePlatformUser = 'Invite_Platform_user',
  INVITE_OpenInviteScreen = 'Open_Invite_Screen',
  KPI_AddKpiFilter = 'Add_Kpi_Filter',
  KPI_CreateKpi = 'Create_Kpi',
  KPI_DeleteKpi = 'Delete_Kpi',
  KPI_RemoveKpiFilter = 'Remove_Kpi_Filter',
  KPI_UpdateKpi = 'Update_Kpi',
  LOGIN_EnterLogin = 'Enter_Login',
  LOGIN_LoginError = 'Login_Error',
  LOGIN_LoginWithEmailAndPassword = 'Login_With_Email_And_Password',
  LOGIN_LoginWithPhonenumber = 'Login_With_Phonenumber',
  LOGIN_LoginWithSSOToken = 'Login_With_SSO_Token',
  LOGIN_ForceLogoutBecauseSSOCookieIsMissing = 'Force_Logout_Because_SSO_Cookie_Is_Missing',
  LOGIN_FirstTimeModelerLogin = 'First_Time_Modeler_Login',
  LOGIN_RequestPasswordResetLink = 'Request_Password_Reset_Link',
  LOGIN_SignupSubmitted = 'Signup_Submitted',
  LOGIN_SignupWithEmailAndPassword = 'Signup_With_Email_And_Password',
  LOGIN_SignupWithPhonenumber = 'Signup_With_Phonenumber',
  LOGIN_SSO_CheckAuthCreateToken = 'SSO_CheckAuth_CreateToken',
  LOGIN_SSO_SignIn = 'SSO_SignIn',
  LOGIN_SSO_SignOut = 'SSO_SignOut',
  LOGIN_TermsAndConditions = 'Terms_And_Conditions',
  LOGIN_UnsupportedBrowser = 'Unsupported_Browser',
  MODELER_FEATURES_ResetUnlockedFeatures = 'Reset_Unlocked_Features',
  NAVIGATION_Logout = 'Logout',
  NAVIGATION_UserHasBeenDeletedAndForcedLogout = 'User_has_been_deleted_and_forced_logout',
  NEW_PROJECT_CreateNewProject = 'Create_New_Project',
  NEW_PROJECT_EnterNewProjectForm = 'Enter_New_Project_Form',
  NOTIFICATION_ExpoPushNotificationReceivedInForeground = 'Expo_Push_Notification_Received_In_Foreground',
  NOTIFICATION_ExpoPushNotificationSentSuccessfully = 'Expo_Push_Notification_Sent_Successfully',
  NOTIFICATION_ExpoPushNotificationTapped = 'Expo_Push_Notification_Tapped',
  NOTIFICATION_FCMNotificationPermissionDenied = 'FCM_Notification_Permission_Denied',
  NOTIFICATION_FCMNotificationReceived = 'FCM_Notification_Received',
  NOTIFICATION_FCMNotificationSentSuccessfully = 'FCM_Notification_Sent_Successfully',
  NOTIFICATION_FCMNotificationTapped = 'FCM_Notification_Tapped',
  NOTIFICATION_FollowNotification = 'Follow_Notification',
  NOTIFICATION_OpenNotificationWindow = 'Open_Notification_Window',
  OVERVIEW_DeleteFlow = 'Delete_Flow',
  OVERVIEW_DeleteUniverse = 'Delete_Universe',
  OVERVIEW_DuplicateFlow = 'Duplicate_Flow',
  OVERVIEW_HandoverFlow = 'Handover_Flow',
  OVERVIEW_ReLabelUniverse = 'ReLabel_Universe',
  OVERVIEW_RemoveUniverse = 'Remove_Universe',
  OVERVIEW_ViewUniverseOverview = 'View_Universe_Overview',
  PHONEAUTH_AddPhoneNumber = 'Add_Phone_Number',
  PUBLISHING_EnterPublishPage = 'Enter_Publish_Page',
  PUBLISHING_Publish = 'Publish_Version',
  PUBLISHING_PublishToProduction = 'Publish_To_Production',
  PUBLISHING_PublishToTesting = 'Publish_To_Testing',
  PUBLISHING_RemoveFromProduction = 'Remove_From_Production',
  PUBLISHING_RemoveFromTesting = 'Remove_From_Testing',
  PUBLISHING_Restore = 'Restore_Version',
  RECORD_DETAIL_AddAllFieldsToRecordDetail = 'Add_All_Fields_To_RecordDetail',
  RECORD_DETAIL_AddFieldToRecordDetail = 'Add_Field_To_RecordDetail',
  RECORD_DETAIL_ClearRecordDetailForRole = 'Clear_RecordDetail_For_Role',
  RECORD_DETAIL_OpenRecordDetail = 'Open_Record_Detail',
  RECORD_DETAIL_OpenRecordDetailOverview = 'Open_RecordDetail_Overview',
  RECORD_DETAIL_RefreshRecordDetail = 'RefreshRecord_Detail',
  RECORD_DETAIL_RemoveFieldFromRecordDetail = 'Remove_Field_From_RecordDetail',
  RECORD_DETAIL_ReorderRecordDetail = 'Reorder_RecordDetail',
  RECORD_DETAIL_ToggleFieldUpdateNotification = 'Toggle_Field_Update_Notification',
  SEND_PUSH_NOTIFICATION_SendingFailed = 'Sending_Failed',
  SEND_PUSH_NOTIFICATION_SendingOk = 'Sending_Ok',
  SHOW_USER_LeaveTeam = 'Leave_Team',
  SHOW_USER_OpenEmail = 'Open_Email',
  SHOW_USER_OpenPhone = 'Open_Phone',
  SHOW_USER_OpenSMS = 'Open_SMS,',
  SHOW_USER_RemoveSubscription = 'Remove_Subscription',
  SHOW_USER_ShowUser = 'Show_User',
  SUBSCRIPTIONS_ArchiveSubscription = 'Archive_Subscription',
  SUBSCRIPTIONS_DeclineSubscription = 'Decline_Subscription',
  SUBSCRIPTIONS_OpenArchived = 'Open_Archived',
  SUBSCRIPTIONS_OpenSubscriptions = 'Open_Subscriptions',
  SUBSCRIPTIONS_RefreshSubscriptions = 'Refresh_Subscriptions',
  SUBSCRIPTIONS_SubscribeInvite = 'Subscribe_Invite',
  SUBSCRIPTIONS_TryOpenPending = 'Try_Open_Pending',
  SUBSCRIPTIONS_UnarchiveSubscription = 'Unarchive_Subscription',
  SUBSCRIPTIONS_UnsubscribeSubscription = 'Unsubscribe_Subscription',
  TUTORIAL_EndTutorial = 'End_Tutorial',
  TUTORIAL_StartTutorial = 'Start_Tutorial',
  VERIFY_EMAIL_ResendEmailVerification = 'Resend_Email_Verification',
  VERIFY_EMAIL_SendVerificationLink = 'Send_Verification_Link',
  VERIFY_EMAIL_UnverifyEmail = 'Unverify_Email',
  VERIFY_EMAIL_VerifyLater = 'Verify_Later',
  WORKITEM_HEADER_OpenWorkItemHeader = 'Open_Work_Item_Header',
}

export enum AtrigamAnalyticScreens {
  Admin = 'Admin',
  App = 'App', // no specific screen, just the app
  Chat = 'Chat',
  CompleteProfile = 'Complete_Profile',
  DataLink = 'DataLink',
  DataManagement = 'DataManagement',
  Delegation = 'Delegation',
  Demo = 'Demo',
  Deploy = 'Deploy',
  DisplayCurrency = 'DisplayCurrency',
  EnvironmentSwitcher = 'Environment_Switcher',
  Extensions = 'Extensions',
  FieldMetaData = 'FieldMetaData',
  FieldNotifications = 'FieldNotifications',
  FieldUpdate = 'FieldUpdate',
  FlowEditor = 'FlowEditor',
  FxRatesAPI = 'FxRatesAPI',
  Image = 'Image',
  Impersonation = 'Impersonation',
  Insights = 'Insights',
  Interaction = 'Interaction',
  Invite = 'Invite',
  Kpi = 'Kpi',
  Login = 'Login',
  ModelerFeatures = 'ModelerFeatures',
  Navigation = 'Navigation',
  NewProject = 'NewProject',
  Notification = 'Notification',
  Overview = 'Overview',
  PhoneAuth = 'PhoneAuth',
  Profile = 'Profile',
  Publishing = 'Publishing',
  RecordDetail = 'RecordDetail',
  SendPushNotification = 'Send_Push_Notification',
  ShowUser = 'ShowUser',
  Subscriptions = 'Subscriptions',
  Tutorial = 'Tutorial',
  VerifyEmail = 'Verify_Email',
  WorkItemHeader = 'WorkItemHeader',
}

export enum AtrigamAppEnvironment {
  Production = 'production',
  Development = 'development',
  Testing = 'testing',
  Beta = 'beta',
}
