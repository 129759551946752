import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
  AtrigamModelsModelFlowPath,
  createAtrigamUniverseAreaTaskFlow,
  createAtrigamDatabaseUniverse,
} from '@atrigam/atrigam-types';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  taskFlow: AtrigamTaskFlowName;
  universe: AtrigamUniverseName;
}

export const createAtrigamModelsModelFlowPath = ({
  area,
  environment,
  taskFlow,
  universe,
}: Options): AtrigamModelsModelFlowPath => {
  const universeAreaFlow = createAtrigamUniverseAreaTaskFlow({ universe, area, taskFlow });
  const databaseUniverse = createAtrigamDatabaseUniverse({ universe });

  return `/atrigam-model-${environment}/${databaseUniverse}/flows/${universeAreaFlow}`;
};
