import { Box, Breadcrumbs as MaterialBreadcrumbs } from '@mui/material';

import { reactive } from '../../../../helpers/reactive';
import { Link } from '../../../../primitive/Link/Link';
import { Typography } from '../../../../primitive/Typography/Typography.style';
import { Registry } from '../../../../services/Registry/Registry';

const BreadcrumbsComponent: React.FC = () => {
  const router = Registry.get('router');

  if (!router.isReady || !router.currentRoute.breadcrumbs) {
    return null;
  }

  return (
    <Box paddingBottom={2}>
      <MaterialBreadcrumbs aria-label="breadcrumb">
        {router.currentRoute.breadcrumbs.map(({ link, label }) => {
          if (link) {
            return (
              <Link to={link} key={label} color="secondary">
                {label}
              </Link>
            );
          }

          return (
            <Typography color="textPrimary" key={label}>
              {label}
            </Typography>
          );
        })}
      </MaterialBreadcrumbs>
    </Box>
  );
};

export const Breadcrumbs = reactive(BreadcrumbsComponent);
