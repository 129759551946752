import { track, trackingConfiguration, updateTracking } from '@atrigam/atrigam-tracking';

import { userPageStoreFactory } from '../../pages/User/stores/User.page.store.factory';
import { routes } from '../../routes';
import { AppStore } from '../../stores/AppStore/App.store';
import { UserStore } from '../../stores/UserStore/User.store';
import { LocalStorage } from '../LocalStorage/LocalStorage';
import { RouterService } from '../Router/Router.service';
import { SnackbarService } from '../Snackbar/Snackbar.service';

import { Registry } from './Registry';

export const setupRegistry = () => {
  Registry.set('storage', () => new LocalStorage());

  Registry.set(
    'router',
    () =>
      new RouterService({
        routes,
        fallbackRoute: 'HomeRoute',
      }),
  );

  Registry.set('snackbar', () => {
    return new SnackbarService();
  });

  // dev helpers
  Registry.set('tracking', () => ({
    updateTracking,
    track,
    trackingConfiguration,
  }));

  // stores
  Registry.set('appStore', () => new AppStore());
  Registry.set('userStore', () => new UserStore());

  // page stores
  Registry.set('userPageStore', () => userPageStoreFactory.create());
};
