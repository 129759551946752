/* eslint-disable @typescript-eslint/naming-convention */
import { TinyColor } from '@ctrl/tinycolor';
import { PaletteOptions, TypeBackground, TypeText, alpha, createTheme } from '@mui/material/styles';
// eslint-disable-next-line import/no-unassigned-import
import type {} from '@mui/x-data-grid/themeAugmentation';

import { atrigamColors } from './atrigamColors';
import {
  chartStyles,
  colorChartBlue,
  colorChartBrown,
  colorChartGray,
  colorChartGreen,
  colorChartLightBlue,
  colorChartLightBrown,
  colorChartLightGray,
  colorChartLightGreen,
  colorChartLightLime,
  colorChartLightOrange,
  colorChartLightPink,
  colorChartLightRed,
  colorChartLightTurquoise,
  colorChartLightViolet,
  colorChartLime,
  colorChartOrange,
  colorChartPink,
  colorChartRed,
  colorChartTurquoise,
  colorChartViolet,
} from './chartStyles';

const primary = '#D4DE25';
const secondary = '#6DB089';
const tertiary = '#eae8e8';
const warning = '#fdde20';
const error = '#ea4848';
const success = '#84c443';
const info = '#00aeff';

const textPrimary = '#FCFCFC';
const textPrimaryContrast = '#000000';
const textSecondaryContrast = '#EEEEEE';

const lightenRate = 7.5;
const darkenRate = 15;

const ODD_OPACITY = 0.2;

export const darkTheme = createTheme({
  // @ts-expect-error we want the logo
  logo: '/logoDark.svg',
  logoIcon: '/icon.svg',
  palette: {
    mode: 'dark' as const,
    primary: {
      main: primary,
      light: new TinyColor(primary).lighten(lightenRate).toHexString(),
      dark: new TinyColor(primary).darken(darkenRate).toHexString(),
      contrastText: textPrimaryContrast,
    },
    secondary: {
      main: secondary,
      light: new TinyColor(secondary).lighten(lightenRate).toHexString(),
      dark: new TinyColor(secondary).darken(darkenRate).toHexString(),
      contrastText: textPrimaryContrast,
    },
    tertiary: {
      main: tertiary,
      light: new TinyColor(tertiary).lighten(lightenRate).toHexString(),
      dark: new TinyColor(tertiary).darken(darkenRate).toHexString(),
      contrastText: '#000',
    },
    warning: {
      main: warning,
      light: new TinyColor(warning).lighten(lightenRate).toHexString(),
      dark: new TinyColor(warning).darken(darkenRate).toHexString(),
      contrastText: textPrimaryContrast,
    },
    error: {
      main: error,
      light: new TinyColor(error).lighten(lightenRate).toHexString(),
      dark: new TinyColor(error).darken(darkenRate).toHexString(),
      contrastText: textPrimaryContrast,
    },
    success: {
      main: success,
      light: new TinyColor(success).lighten(lightenRate).toHexString(),
      dark: new TinyColor(success).darken(darkenRate).toHexString(),
      contrastText: textPrimaryContrast,
    },
    info: {
      main: info,
      light: new TinyColor(info).lighten(lightenRate).toHexString(),
      dark: new TinyColor(info).darken(darkenRate).toHexString(),
      contrastText: textPrimaryContrast,
    },
    text: {
      primary: textPrimary,
      secondary: '#EEEEEE',
      tertiary: '#EEEEEE',
      hint: '#B9B9B9',
      disabled: 'hsl(0, 0%, 60%)',
    } as Partial<TypeText>,
    background: {
      default: '#303030',
      paper: '#424242',
      light: '#1c1c1c',
    } as Partial<TypeBackground>,
  } as PaletteOptions,
  environmentHeader: {
    production: '#212121',
    staging: atrigamColors.blue['2c'],
    testing: atrigamColors.blue['3e'],
  },
  chat: {
    bubble: {
      default: {
        background: '#424242',
        text: textPrimary,
      },
      primary: {
        background: primary,
        text: textPrimaryContrast,
      },
    },
  },
  chart: {
    ...chartStyles,
    colors: {
      1: {
        primary: colorChartBlue,
        primaryContrast: textSecondaryContrast,
        secondary: colorChartLightBlue,
        secondaryContrast: textPrimaryContrast,
      },
      2: {
        primary: colorChartOrange,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightOrange,
        secondaryContrast: textPrimaryContrast,
      },
      3: {
        primary: colorChartGreen,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightGreen,
        secondaryContrast: textPrimaryContrast,
      },
      4: {
        primary: colorChartLime,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightLime,
        secondaryContrast: textPrimaryContrast,
      },
      5: {
        primary: colorChartRed,
        primaryContrast: textSecondaryContrast,
        secondary: colorChartLightRed,
        secondaryContrast: textPrimaryContrast,
      },
      6: {
        primary: colorChartViolet,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightViolet,
        secondaryContrast: textPrimaryContrast,
      },
      7: {
        primary: colorChartBrown,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightBrown,
        secondaryContrast: textPrimaryContrast,
      },
      8: {
        primary: colorChartPink,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightPink,
        secondaryContrast: textPrimaryContrast,
      },
      9: {
        primary: colorChartGray,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightGray,
        secondaryContrast: textPrimaryContrast,
      },
      10: {
        primary: colorChartTurquoise,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightTurquoise,
        secondaryContrast: textPrimaryContrast,
      },
    },
  },
  customShadows: {
    widget: '0px 3px 11px 0px #000000, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide: '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          '&.MuiBadge-root > .MuiBadge-badge': {
            color: textPrimaryContrast,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#B2B2B21A !important',
            '&:focus': {
              backgroundColor: '#B2B2B21A',
            },
          },
        },
        // button: {
        //   '&:hover, &:focus': {
        //     backgroundColor: '#B2B2B21A',
        //   },
        // },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            backgroundColor: '#B2B2B21A !important',
            '&:focus': {
              backgroundColor: '#B2B2B21A',
            },
          },
        },
        button: {
          '&:hover, &:focus': {
            backgroundColor: '#B2B2B21A',
          },
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        child: {
          backgroundColor: 'white',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeaderTitle: {
          fontWeight: 'bold',
        },
        cell: {
          '&.olderVersion': {
            color: new TinyColor(warning).darken(darkenRate).toHexString(),
          },
          '&.noModelerModel': {
            color: new TinyColor(error).lighten(lightenRate).toHexString(),
          },
        },
        row: {
          '&.even': {
            backgroundColor: '#424242',
          },
          '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(primary, ODD_OPACITY),
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
  },
  typography: {
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.64rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.285rem',
    },
    h6: {
      fontSize: '1.142rem',
    },
  },
});
