// Atrigam Domain: App

// different AtrigamApp Areas
export enum AtrigamApps {
  Administration = 'administration',
  Mobile = 'mobile',
  Modeler = 'modeler',
  ServerFunction = 'server-function',
  Web = 'web',
}

// the real apps we have. not the area
export enum AtrigamPlatformApps {
  Administration = 'administration',
  Android = 'android',
  // eslint-disable-next-line unicorn/prevent-abbreviations
  iOS = 'ios',
  Mobile = 'mobile',
  Modeler = 'modeler',
  ServerFunctionAdmin = 'server-function-admin',
  ServerFunctionEU = 'server-function-eu',
  ServerFunctionUS = 'server-function-us',
  WebClient = 'webclient',
  Website = 'website',
}

export enum AtrigamAppStatus {
  Open = 'open',
  Locked = 'locked',
}

export enum AtrigamClient {
  Mobile = 'mobile',
  Web = 'web',
}

export enum AtrigamServerFunction {
  Admin = 'admin',
  EU = 'eu',
  US = 'us',
}
