// methods like c*nsole.log are typically used in development
// -> this is why our linter warns us about not leaving c*nsole.method calls in our
//
// however: sometimes we desire to actually log something and leave in our code.
// this convenience export works around two issues.
// 1) having to disable lint warnings for each of those occurences
// 2) clearing up the codebase so we can easily search for "c*nsole.log"
//    and remove those dev logs

// TL;DR:
// logger should only be used for messages that SHOULD be logged in production too
// dev logging should still use c*nsole.method
export const logger = console;
