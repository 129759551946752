import { ListItemButton } from '@mui/material';
import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '../../../../../../../themes/Themes.types';

interface Properties {
  $isActive: boolean;
  $isNavigationOpen: boolean;
  component?: 'a';
  href?: string;
}

export const NaviItemWrapper = styled(ListItemButton)<Properties & ThemedStyledComponent>`
  text-decoration: none;
  &:hover,
  &:focus {
    background-color: ${({ theme }: ThemedStyledComponent) => theme.palette.background.light};
  }

  & .MuiListItemIcon-root {
    ${({ theme }: ThemedStyledComponent) => css`
      margin-right: ${theme.spacing(1)};
      color: ${theme.palette.text.secondary};
      transition: ${theme.transitions.create('color')};
      width: 24px;
      display: flex;
      justify-content: center;
    `}
  }

  & .MuiListItemText-root {
    ${({ theme }: ThemedStyledComponent) => css`
      padding: 0;
      color: ${theme.palette.text.secondary};
      transition: ${theme.transitions.create(['opacity', 'color'])};
      font-size: 16px;
    `}

    ${({ $isNavigationOpen }) => {
      if (!$isNavigationOpen) {
        return css`
          opacity: 0;
        `;
      }
    }}
  }

  ${({ $isActive, theme }: Properties & ThemedStyledComponent) => {
    if ($isActive) {
      return css`
        background-color: ${theme.palette.background.light};

        & .MuiListItemIcon-root {
          color: ${theme.palette.primary.main};
        }

        & .MuiListItemText-root {
          color: ${theme.palette.text.primary};
        }
      `;
    }
  }}
`;
