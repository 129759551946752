import {
  AtrigamEnvironment,
  AtrigamModelsModel,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
} from '@atrigam/atrigam-types';
import { get } from 'firebase/database';

import { getUniverseModelQueryReference } from '../queryReferences/getUniverseModel.query.reference';

interface Options {
  environment: AtrigamEnvironment;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const getUniverseModelQuery = async (options: Options) => {
  const reference = getUniverseModelQueryReference(options);
  const snapshot = await get(reference);

  return snapshot.val() as AtrigamModelsModel | undefined;
};
