import { getApp } from 'firebase/app';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';

import { APP_NAME_EU } from '../clientSDK.constants';

export const getCurrentFirestoreUserQuery = (): Promise<User | null> => {
  const auth = getAuth(getApp(APP_NAME_EU));
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject,
    );
  });
};
