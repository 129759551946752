import { updateTracking } from '@atrigam/atrigam-tracking';
import { getCurrentFirestoreAdminUserQuery } from '@atrigam/server-functions-admin-client';
import { getCurrentFirestoreUserQuery, getUserQuery } from '@atrigam/server-functions-eu-client';

import { logoutUser } from '../../../api/logoutUser';
import { getCurrentFirestoreBackupUserQuery } from '../../../firestore/atrigam-backup/queries/getCurrentFirestoreBackupUser.query';
import { createMobxHydrator } from '../../../helpers/createMobxHydrator';
import { sentry } from '../../../services/Sentry/helpers/initializeSentry';
import { UserStore } from '../User.store';

export const persistUserStore = async (userStore: UserStore) => {
  const hydrate = createMobxHydrator({ jsonify: false });

  const { user, persistedVersion: persistanceVersion } = await hydrate('UserStore', userStore);

  // got user data
  if (!user) {
    userStore.setInitializationFinished();
    return;
  }

  const uid = user.uid;

  // verify user is valid
  const firestoreUser = await getCurrentFirestoreUserQuery();
  const firestoreAdminUser = await getCurrentFirestoreAdminUserQuery();
  const firestoreBackupUser = await getCurrentFirestoreBackupUserQuery();

  // user is missing or uid did not match, logout
  if (!uid || !firestoreUser || !firestoreAdminUser || !firestoreBackupUser) {
    sentry.log({
      error: new Error('user is missing or did not match'),
      context: {
        uid,
        firestoreUser,
        firestoreAdminUser,
        firestoreBackupUser,
      },
    });

    await logoutUser({});
    userStore.setInitializationFinished();
    return;
  }

  // persistance data is old
  if (persistanceVersion !== UserStore.PERSISTANCE_VERSION) {
    const userData = await getUserQuery({ uid });
    if (!userData) {
      sentry.log({
        error: new Error('user data is missing'),
        context: {
          uid,
        },
      });
      await logoutUser({});
      userStore.setInitializationFinished();
      return;
    }

    userStore.updateUser({ user: userData, forceUpdate: true });
  }

  // check if user is platformAdmin
  await userStore.validatePlatformAdmin();

  sentry.setUser({
    id: uid,
    email: userStore.user?.email,
    phoneNumber: userStore.user?.phoneNumber,
    username: userStore.user?.name,
  });

  // tracking package
  updateTracking({
    uid,
    user: {
      avatar: userStore.user?.avatar ?? null,
      email: userStore.user?.email ?? null,
      firstname: userStore.user?.firstname ?? null,
      lastname: userStore.user?.lastname ?? null,
      phoneNumber: userStore.user?.phoneNumber ?? null,
      isTestUser: userStore.user?.isTestUser ?? null,
    },
  });

  // and also watch node for changes
  userStore.startWatchers();
  userStore.setInitializationFinished();
};
