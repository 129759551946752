import {
  AtrigamEnvironment,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  createAtrigamDatabaseUniverseEnvironment,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { DocumentData, DocumentReference, doc, getDoc, getFirestore } from 'firebase/firestore';

import { BACKUP_APP_NAME } from '../atrigam-backup.types';
import { AtrigamUniverseBackupConfiguration } from '../backup.types';

interface Options {
  environment: AtrigamEnvironment;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const getBackupConfigurationQuery = async ({ environment, universe }: Options) => {
  const database = getFirestore(getApp(BACKUP_APP_NAME));

  const databaseUniverseEnvironment = createAtrigamDatabaseUniverseEnvironment({
    environment,
    universe,
  });
  const path = `/configuration/${databaseUniverseEnvironment}`;

  const reference = doc(database, path) as DocumentReference<
    AtrigamUniverseBackupConfiguration,
    DocumentData
  >;

  const document_ = await getDoc(reference);
  return document_.data();
};
