import { DestructibleService } from '@atrigam/atrigam-service-registry';
import {
  AtrigamAnalyticEvents,
  AtrigamAnalyticScreens,
  track,
  updateTracking,
} from '@atrigam/atrigam-tracking';
import { AtrigamUser, throwIfNullable } from '@atrigam/atrigam-types';
import {
  AtrigamAdminPlatformAdmin,
  watchPlatformAdmin,
} from '@atrigam/server-functions-admin-client';
import { isPlatformAdminQuery, watchUser } from '@atrigam/server-functions-eu-client';
import { action, computed, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { logoutUser } from '../../api/logoutUser';
import { hasDateChanged } from '../../helpers/hasDateChanged';
import { logger } from '../../helpers/logger';
import { Registry } from '../../services/Registry/Registry';
import { sentry } from '../../services/Sentry/helpers/initializeSentry';

import { persistUserStore } from './helpers/persistUserStore';
import { watchAuthState } from './helpers/watchAuthState';
import { UserModel } from './models/User.model';

export class UserStore extends DestructibleService {
  static PERSISTANCE_VERSION = 5;

  @persist('object', UserModel)
  @observable
  user?: UserModel;

  @persist
  @observable
  persistedVersion?: number;

  @observable
  initialized = false;

  @observable
  isChangingLoginState = false;

  @observable
  isPlatformAdmin = false;

  @observable
  private watchers = observable.map<string, () => void>();

  constructor() {
    super();

    makeObservable(this);

    void persistUserStore(this);
    watchAuthState(this);
  }

  @computed
  get uid() {
    throwIfNullable('user.uid cannot be undefined', this.user?.uid);
    return this.user.uid;
  }

  @computed
  get isAuthenticated() {
    return this.user?.uid !== undefined;
  }

  @computed
  get userOrFail() {
    throwIfNullable('user cannot be undefined', this.user);
    return this.user;
  }

  @action
  setChangingLoginState = (changing: boolean) => {
    this.isChangingLoginState = changing;
  };

  @action
  setUser = async ({ user }: { user: AtrigamUser | UserModel }) => {
    if (user instanceof UserModel) {
      this.user = user;
    } else {
      this.user = new UserModel({ user });
    }
    this.persistedVersion = UserStore.PERSISTANCE_VERSION;

    await this.validatePlatformAdmin();

    sentry.setUser({
      id: this.uid,
      email: this.user?.email,
      username: this.user?.name,
      phoneNumber: this.user?.phoneNumber,
    });

    // tracking package
    updateTracking({
      uid: this.uid,
      user: {
        avatar: this.user.avatar ?? null,
        email: this.user.email ?? null,
        firstname: this.user.firstname ?? null,
        lastname: this.user.lastname ?? null,
        phoneNumber: this.user.phoneNumber ?? null,
        isTestUser: this.user.isTestUser ?? null,
      },
    });

    this.startWatchers();
  };

  @action
  startWatchers = () => {
    this.watchers.set(
      'watchUser',
      watchUser({
        uid: this.uid,

        onUpdate: (user) => {
          // user has been deleted, logout
          if (!user) {
            logger.log('user has been deleted. Loggin out...');

            // also track user has been forced logout
            void track({
              event: AtrigamAnalyticEvents.NAVIGATION_UserHasBeenDeletedAndForcedLogout,
              screen: AtrigamAnalyticScreens.Navigation,
            });

            // also track user has been forced logout
            Registry.get('snackbar').addNotification({
              message: 'User has been deleted',
              options: {
                variant: 'error',
                persist: false,
              },
            });

            void logoutUser({});
            return;
          }

          this.updateUser({ user });
        },

        onError: () => {
          if (Registry.get('userStore').isChangingLoginState) {
            return false;
          }

          return true;
        },
      }),
    );
    this.watchers.set(
      'watchPlatformAdmins',
      watchPlatformAdmin({ uid: this.uid, onUpdate: this.updatePlatformAdmin }),
    );
  };

  @action
  stopWatchers = () => {
    [...this.watchers.values()].forEach((disposer) => {
      disposer();
    });

    this.watchers.clear();
  };

  @action
  clearUser = () => {
    this.stopWatchers();

    // this will also persist
    this.user = undefined;
    this.isPlatformAdmin = false;
    this.persistedVersion = undefined;

    sentry.setUser(null);

    // tracking package
    updateTracking({
      uid: 'unregistered',
      user: null,
    });

    return;
  };

  @action
  updateUser = ({ user, forceUpdate = false }: { user: AtrigamUser; forceUpdate?: boolean }) => {
    if (!this.user) {
      return;
    }

    if (
      !forceUpdate &&
      !hasDateChanged(this.user.updatedAt, user.updatedAt) &&
      !hasDateChanged(this.user.profileUpdatedAt, user.profileUpdatedAt)
    ) {
      return;
    }

    this.user.updateUser(user);
    this.persistedVersion = UserStore.PERSISTANCE_VERSION;
    void this.validatePlatformAdmin();
  };

  @action
  updatePlatformAdmin = (platformAdmin?: AtrigamAdminPlatformAdmin) => {
    if (!this.user) {
      return;
    }

    if (!platformAdmin?.active) {
      logger.log('user is not an admin anymore. Logging out...');

      // also track user has been forced logout
      Registry.get('snackbar').addNotification({
        message: 'User lost administration privileges',
        options: {
          variant: 'error',
          persist: false,
        },
      });

      void logoutUser({});

      return;
    }

    this.isPlatformAdmin = platformAdmin.active;
  };

  @action
  setInitializationFinished = () => {
    this.initialized = true;
  };

  @action
  validatePlatformAdmin = async () => {
    if (!this.isAuthenticated || !this.user) {
      this.isPlatformAdmin = false;
      return;
    }

    this.isPlatformAdmin = await isPlatformAdminQuery({ uid: this.uid });
  };
}
