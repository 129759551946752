import { useTheme } from '@mui/material';
import React, { Suspense } from 'react';

import { reactive } from '../../helpers/reactive';
import { RouteSlot } from '../../patterns/RouteSlot/RouteSlot';
import { LazyPlaceholder } from '../../primitive/LazyPlaceholder/LazyPlaceholder';
import { Logo } from '../../primitive/Logo/Logo';
import { Registry } from '../../services/Registry/Registry';
import { AtrigamTheme } from '../../typings/atrigamTheme';

import { Main } from './features/Main/Main';
import { Content } from './styles/Content.style';
import { LayoutWrapper } from './styles/LayoutWrapper.style';

interface Properties {
  // add possibility to override the slot for usage in lockscreen
  disableSlot?: boolean;
}

const UnauthorizedLayoutComponent: React.FC<React.PropsWithChildren<Properties>> = ({
  disableSlot = false,
  children,
}) => {
  const router = Registry.get('router');
  const theme = useTheme<AtrigamTheme>();

  const meta = router.currentRoute.meta;

  const fullWidth = meta?.fullWidth ?? false;
  const logoWidth = fullWidth ? '75px' : '30px';
  const logoHeight = fullWidth ? '300px' : '90px';
  const logoStyle = React.useMemo(
    () => ({
      display: 'block',
      margin: 'auto',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      width: fullWidth ? '300px' : '90px',
    }),
    [fullWidth, theme],
  );

  return (
    <>
      <LayoutWrapper fullWidth={fullWidth}>
        <Content>
          <Logo width={logoWidth} height={logoHeight} style={logoStyle} />

          <Suspense fallback={<LazyPlaceholder />}>
            <Main>
              {!disableSlot && <RouteSlot slot="main" />}

              {children}
            </Main>
          </Suspense>
        </Content>
      </LayoutWrapper>
    </>
  );
};

export const UnauthorizedLayout = reactive(UnauthorizedLayoutComponent);
