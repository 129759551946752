import { KPIsPageAsync } from '../../pages/KPIs/KPIs.page.async';
import { RouteScope } from '../../services/Router/Router.types';
import { createRoute } from '../../services/Router/helpers/createRoute';

import { pattern } from './kpis.path';

export const KPIsRoute = createRoute({
  title: () => 'KPIs',
  components: {
    main: KPIsPageAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,
  meta: {},
});
