import { createGlobalStyle, css } from 'styled-components';

/**
 * copied over the styles we wanted to use from
 *
 * @see https://animate.style/
 */
export const AnimationsStyles = createGlobalStyle(
  () => css`
    @keyframes bounceIn {
      from,
      20%,
      40%,
      60%,
      80%,
      to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
      }

      20% {
        transform: scale3d(1.1, 1.1, 1.1);
      }

      40% {
        transform: scale3d(0.9, 0.9, 0.9);
      }

      60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
      }

      80% {
        transform: scale3d(0.97, 0.97, 0.97);
      }

      to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
      }
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  `,
);
