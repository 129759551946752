import { throwIfNullable } from '@atrigam/atrigam-types';
import { collection, doc, setDoc, getFirestore } from 'firebase/firestore';

import {
  AtrigamFirestoreActivity,
  AtrigamFirestoreActivityCollectionPath,
} from '../firestore.types';
import { getNowFirestoreTimestamp } from '../helpers/getNowFirestoreTimestamp';
import { mapUndefinedToNullValue } from '../helpers/mapUndefinedToNullValue';
import { firebaseApp } from '../setup';

export const createActivityEntryMutation = async (
  data: Omit<AtrigamFirestoreActivity, 'timestamp'>,
) => {
  throwIfNullable('FirestoreDatabase cannot be undefined', firebaseApp);
  const database = getFirestore(firebaseApp);

  const path: AtrigamFirestoreActivityCollectionPath = '/activity';
  const reference = doc(collection(database, path));
  const timestamp = getNowFirestoreTimestamp();

  // handle data.environment
  if (data.environment) {
    data.env = data.environment;
    delete data.environment;
  }

  await setDoc(reference, mapUndefinedToNullValue({ ...data, timestamp }));
};
