import {
  AtrigamEnvironment,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamModelsModelPath,
  createAtrigamDatabaseUniverse,
} from '@atrigam/atrigam-types';

interface Options {
  environment: AtrigamEnvironment;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const createAtrigamModelsModelPath = ({
  environment,
  universe,
}: Options): AtrigamModelsModelPath => {
  const databaseUniverse = createAtrigamDatabaseUniverse({ universe });
  return `/atrigam-model-${environment}/${databaseUniverse}`;
};
