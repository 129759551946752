/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { toJS as mobxToJS } from 'mobx';

// eslint-disable-next-line @typescript-eslint/ban-types
export function toJS(query?: undefined | null): {};
export function toJS<T extends Record<string, any>>(query: T): T;
export function toJS<T extends Record<string, any>>(query?: T | undefined | null) {
  if (!query) {
    return {};
  }

  return mobxToJS(query);
}
