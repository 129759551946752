import { AtrigamModelsModelFlow, AtrigamUser, throwIfNullable } from '@atrigam/atrigam-types';

import { UserPageStore } from './User.page.store';

const createUserPageStoreFactory = () => {
  let user: AtrigamUser | undefined;
  let model: AtrigamModelsModelFlow | undefined;

  return {
    set: (options: { user: AtrigamUser; model: AtrigamModelsModelFlow }) => {
      user = options.user;
      model = options.model;
    },

    create: () => {
      throwIfNullable('user cannot be undefined', user);
      throwIfNullable('model cannot be undefined', model);

      return new UserPageStore({ user, model });
    },

    reset: () => {
      user = undefined;
      model = undefined;
    },
  };
};

export const userPageStoreFactory = createUserPageStoreFactory();
