import { UnhandledCaseError } from '@atrigam/atrigam-types';

import { RouteLayout, RouteScope } from '../Router.types';

interface Options {
  scope: RouteScope;
}

// this established the connection between each RouteScope
// and the RouteLayout that should be used by each route
export const getRouteLayout = (options: Options): RouteLayout => {
  const { scope } = options;

  switch (scope) {
    // this needs to be in sync with `RouteScopesWithAuthorizedLayout` from `Router.types`
    case RouteScope.LoggedIn: {
      return RouteLayout.Default;
    }

    case RouteScope.LoggedOut: {
      return RouteLayout.Unauthorized;
    }

    default: {
      throw new UnhandledCaseError(scope);
    }
  }
};
