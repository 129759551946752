const WEBHOOK_CHANNEL_DEV_URL =
  'https://atrigamcom.webhook.office.com/webhookb2/0a4b7d84-5d7b-4d97-a9f0-7b34ad568782@738e1bc3-82c4-412a-abad-3fb13460feb2/IncomingWebhook/36d27511c50b44a2a18350726773fb7b/6844ce94-4799-4faa-9bfb-2fc0dafe6d40';

interface Options {
  title: string;
  message: string;
}

// eslint-disable-next-line unicorn/prevent-abbreviations
export const sendTeamsMessageToDevChannel = async ({ title, message }: Options) => {
  const card = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '@type': 'MessageCard',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '@context': 'http://schema.org/extensions',
    themeColor: '0072C6', // light blue
    summary: 'Summary description',
    sections: [
      {
        activityTitle: title,
        text: message,
      },
    ],
  };

  const body = JSON.stringify(card);

  await fetch(WEBHOOK_CHANNEL_DEV_URL, {
    method: 'POST',
    headers: {
      // Accept: 'application/json, text/plain, */*',
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': '*',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'content-type': 'application/vnd.microsoft.teams.card.o365connector',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'content-length': `${body.length}`,
    },
    body,
    mode: 'no-cors',
  });
};
