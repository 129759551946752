import { UID } from '@atrigam/atrigam-types';

import { RouteParameters, RouteQuery } from '../../services/Router/Router.types';
import { createRoutePath } from '../../services/Router/helpers/createRoutePath';

export interface Parameters extends RouteParameters {
  uid: UID;
}

export const pattern = '/users/:uid';

export const UserPath = createRoutePath<Parameters, RouteQuery>({ pattern });
