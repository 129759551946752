import { Button } from '@mui/material';
import { autorun } from 'mobx';
import { SnackbarKey, useSnackbar } from 'notistack';
import React from 'react';

import { reactive } from '../../../../helpers/reactive';
import { Registry } from '../../../../services/Registry/Registry';

const SnackbarNotifierComponent: React.FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    notifications,
    notificationHasBeenSeen,
    removableNotifications,
    notificationHasBeenRemoved,
  } = Registry.get('snackbar');

  const displayed = React.useRef<SnackbarKey[]>([]);

  const storeDisplayed = React.useCallback(
    (id: SnackbarKey) => {
      displayed.current.push(id);
    },
    [displayed],
  );

  const onClose = React.useCallback(
    (key: SnackbarKey) => () => {
      closeSnackbar(key);
      notificationHasBeenSeen(key);
    },
    [closeSnackbar, notificationHasBeenSeen],
  );

  React.useEffect(() => {
    autorun(() => {
      notifications.forEach((notification) => {
        // Do nothing if snackbar is already displayed
        if (displayed.current.includes(notification.key)) {
          return;
        }

        // check if actions are present
        if (!notification.options?.action && !notification.options?.persist) {
          //
          const action = (
            <Button onClick={onClose(notification.key)} color="primary" size="small">
              Close
            </Button>
          );

          notification.options = {
            ...notification.options,
            action,
          };
        }

        // add key to options
        if (!notification.options?.key) {
          notification.options = {
            ...notification.options,
            key: notification.key,
          };
        }

        // remove closed notifications from displayed array
        if (!notification.options?.onClose) {
          notification.options = {
            ...notification.options,
            onClose: (_event, _reason, key) => {
              displayed.current = displayed.current.filter((n) => n !== key);
            },
          };
        }

        // Display snackbar using notistack
        let Message = notification.message;
        if (typeof notification.message === 'string') {
          Message = (
            <span
              dangerouslySetInnerHTML={{
                __html: notification.message,
              }}
            />
          );
        }

        enqueueSnackbar(Message, { ...notification.options, preventDuplicate: true });

        // Keep track of snackbars that we've displayed
        storeDisplayed(notification.key);

        // Dispatch action to remove snackbar from mobx store
        notificationHasBeenSeen(notification.key);
      });

      removableNotifications.forEach((key) => {
        closeSnackbar(key);
        notificationHasBeenRemoved(key);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, removableNotifications]);

  return null;
};

export const SnackbarNotifier = reactive(SnackbarNotifierComponent);
