import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '../../../../../themes/Themes.types';

interface Properties {
  $noPadding: boolean;
}

export const MainWrapper = styled.main(
  ({ theme, $noPadding }: ThemedStyledComponent & Properties) => css`
    flex: 1;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: ${$noPadding ? 0 : theme.spacing(3)};
    padding-top: ${$noPadding ? 0 : theme.spacing(3)};
    padding-left: ${$noPadding ? 0 : theme.spacing(3)};
  `,
);
