import { css, keyframes } from 'styled-components';

export const hideOverflowingText = ({ width }: { width: string }) => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${width};
`;

export const spinningAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform:rotate(360deg);
  }
`;

export const spin = ({ spinning = true }: { spinning?: boolean } = {}) =>
  spinning
    ? css`
        animation: ${spinningAnimation} 0.4s infinite linear;
      `
    : null;
