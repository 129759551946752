import {
  AtrigamEnvironment,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
} from '@atrigam/atrigam-types';
import { ref } from 'firebase/database';

import { getAtrigamModelsDatabase } from '../helpers/getAtrigamModelsDatabase';
import { createAtrigamModelsModelPath } from '../paths/createAtrigamModelsModelPath';

interface Options {
  environment: AtrigamEnvironment;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const getUniverseModelQueryReference = (options: Options) => {
  const database = getAtrigamModelsDatabase();
  const path = createAtrigamModelsModelPath(options);
  return ref(database, path);
};
