import { DateTime } from 'luxon';

import { FirestoreClientTimestampObject } from '../date.types';

export const getDateTimeFromFirestoreTimestampLikeObject = ({
  seconds,
  nanoseconds,
}: FirestoreClientTimestampObject) => {
  return DateTime.fromMillis(Math.floor(nanoseconds / 1_000_000) + seconds * 1000);
};
