import { ErrorInfo } from 'react';

import { sentry } from '../../../services/Sentry/helpers/initializeSentry';

export const handleErrorBoundaryError = (error: Error, info: ErrorInfo) => {
  sentry.log({
    context: {
      componentStack: (info.componentStack ?? '').trim(),
    },
    error,
  });

  // todo redirect to home?
};
