import {
  AtrigamUniverseNodeName,
  AtrigamUniverseName,
  AtrigamUniverseRegistrationPath,
  createAtrigamUniverseNodeName,
} from '@atrigam/atrigam-types';

interface Options {
  universe: AtrigamUniverseNodeName | AtrigamUniverseName;
}

export const createAtrigamUniverseRegistrationPath = ({
  universe,
}: Options): AtrigamUniverseRegistrationPath => {
  const universeNodeName = createAtrigamUniverseNodeName(universe);
  return `/universeRegistration/${universeNodeName}`;
};
