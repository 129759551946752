import { Snackbar } from '@mui/material';
import React from 'react';

import { reactive } from '../../../../helpers/reactive';
import { PrimaryButton } from '../../../../primitive/Button/PrimaryButton';
import { Registry } from '../../../../services/Registry/Registry';
import { SentryCategories } from '../../../../services/Sentry/Sentry.types';
import { sentry } from '../../../../services/Sentry/helpers/initializeSentry';

import { useAutoReloadCountdown } from './hooks/useAutoReloadCountdown';
import { useServiceWorkerUpdate } from './hooks/useServiceWorkerUpdate';

const AUTORELOAD_TIMEOUT = 30 * 60 * 1000; // 30 minutes
const COUNTDOWN_THRESHOLD = 5 * 60 * 1000; // 5 minutes

const UpdateAvailableNotificationComponent: React.FC = () => {
  const { newVersionAvailable: updateAvailable, appVersion } = Registry.get('appStore');
  const { setEndTime, humanReadableLeftTime, timeLeft } = useAutoReloadCountdown();
  const timerReference = React.useRef<number>();

  const serviceWorkerRegistration = React.useRef<ServiceWorkerRegistration>();
  const { needRefresh, updateServiceWorker } = useServiceWorkerUpdate({
    serviceWorkerRegistration,
  });

  const reloadPage = React.useCallback(
    (automaticReload: boolean) => {
      sentry.addBreadcrumb({
        category: SentryCategories.Router,
        message: automaticReload
          ? 'Automatic reloading of page because of new version after countdown.'
          : 'User reloads page because of new version',
        data: {
          oldVersion: appVersion,
          newVersion: updateAvailable ?? 'none',
        },
        level: 'info',
      });

      // reloading
      void updateServiceWorker(true);
      // window.location.reload();
    },
    [appVersion, updateAvailable, updateServiceWorker],
  );

  const handleClick = React.useCallback(() => reloadPage(false), [reloadPage]);

  React.useEffect(() => {
    // no update -> no timer
    if (!needRefresh) {
      clearTimeout(timerReference.current);
      setEndTime(0);
      return;
    }

    // already timing
    if (timerReference.current) {
      return;
    }

    timerReference.current = window.setTimeout(() => reloadPage(true), AUTORELOAD_TIMEOUT);
    setEndTime(Date.now() + AUTORELOAD_TIMEOUT);
  }, [reloadPage, setEndTime, needRefresh]);

  // if we get an updateAvailable then immediately update serviceWorker
  // no need to wait till 30 seconds have passed.. speeds up everything
  React.useEffect(() => {
    if (!updateAvailable || !serviceWorkerRegistration.current) {
      return;
    }

    void serviceWorkerRegistration.current.update();
  }, [updateAvailable]);

  // clear timeout if reloading
  React.useEffect(() => {
    return () => {
      clearTimeout(timerReference.current);
    };
  }, []);

  const showTimeout = timeLeft > 0 && timeLeft < COUNTDOWN_THRESHOLD;
  const countdownMessage = showTimeout ? `<br/>Autoreload in ${humanReadableLeftTime}` : '';

  return (
    <Snackbar
      open={needRefresh}
      message={
        <span
          dangerouslySetInnerHTML={{
            __html: `A new version is available!${countdownMessage}`,
          }}
        />
      }
      data-test-id="screens-new-version-snackbar"
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <PrimaryButton size="small" onClick={handleClick} variant="text">
          Reload
        </PrimaryButton>
      }
    />
  );
};

export const UpdateAvailableNotification = reactive(UpdateAvailableNotificationComponent);
