import { AtrigamUniverseName, AtrigamUniverseNodeName } from '@atrigam/atrigam-types';
import { getDoc } from 'firebase/firestore';

import { getUniverseRegistrationQueryReference } from '../queryReferences/getUniverseRegistration.query.reference';

interface Options {
  universe: AtrigamUniverseNodeName | AtrigamUniverseName;
}

export const getUniverseRegistrationQuery = async ({ universe }: Options) => {
  const reference = getUniverseRegistrationQueryReference({ universe });
  const document = await getDoc(reference);

  return document.data();
};
