import { AtrigamEnvironment } from '@atrigam/atrigam-types';
import * as Sentry from '@sentry/react';

import { logger } from '../../helpers/logger';

export type SentryConfig =
  | {
      dsn: string;
      environment?: string;
      release: string;
    }
  | false;

type Context = Record<string, unknown>;

interface Properties {
  config: SentryConfig;
}

interface ErrorLogOptions {
  error: Error;
  context?: Context;
}

interface DebugLogOptions {
  message: string;
  context?: Context;
}

export class SentryService {
  //  generate unique transactionId and set as Sentry tag
  //  @see https://blog.sentry.io/2019/04/04/trace-errors-through-stack-using-unique-identifiers-in-sentry
  readonly transactionID = Math.random().toString(36).slice(2, 9);

  private _isEnabled = false;

  constructor({ config }: Properties) {
    if (config) {
      if (config.dsn) {
        //  setup
        Sentry.init({
          dsn: config.dsn,
          integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
              maskAllText: false,
            }),
          ],
          environment: config.environment,
          release: config.release,

          //  We recommend adjusting this value in production, or using tracesSampler
          //  for finer control
          tracesSampleRate: 1,

          //  Capture Replay for 10% of all sessions,
          //  plus for 100% of sessions with an error
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1,

          //  beforeSend(event) {
          //  Check if it is an exception, and if so, show the report dialog
          //   if (event.exception) {
          //     SentryBrowser.showReportDialog({ eventId: event.event_id });
          //   }
          //   return event;
          //  },

          //  ignore errors which we handle ourselves
          ignoreErrors: [
            'TypeError: Failed to fetch',
            'TypeError: NetworkError when attempting to fetch resource.',
          ],

          //  add transaction id as tag
          initialScope: (scope) => {
            scope.setTag('transactionId', this.transactionID);
            return scope;
          },
        });

        this._isEnabled = true;
        return;
      }

      throw new Error('Sentry setup failed.');
    }
  }

  get isEnabled() {
    return this._isEnabled;
  }

  log = ({ error, context }: ErrorLogOptions) => {
    /*  istanbul ignore else */
    if (this._isEnabled) {
      Sentry.withScope((scope) => {
        if (context) {
          scope.setExtras(context);
        }

        Sentry.captureException(error);
      });
    }

    logger.group('Sentry.log');
    logger.error(error);

    if (context) {
      logger.log('^ Error-Context:', context);
    }

    logger.groupEnd();
  };

  debug = ({ message, context }: DebugLogOptions) => {
    /*  istanbul ignore else */
    if (this._isEnabled) {
      Sentry.withScope((scope) => {
        if (context) {
          scope.setExtras(context);
        }

        Sentry.captureMessage(message, 'debug');
      });
    }

    logger.group('Sentry.log');
    logger.log(message);

    if (context) {
      logger.log('^ Debug-Context:', context);
    }

    logger.groupEnd();
  };

  addBreadcrumb = (breadcrumb: Sentry.Breadcrumb) => {
    /*  istanbul ignore else */
    if (this._isEnabled) {
      Sentry.addBreadcrumb(breadcrumb);
    }
  };

  setAtrigamEnvironment = (environment: AtrigamEnvironment) => {
    /*  istanbul ignore else */
    if (this._isEnabled) {
      Sentry.setTag('atrigamEnvironment', environment);
    }
  };

  setUser = (user: Sentry.User | null) => {
    /*  istanbul ignore else */
    if (this._isEnabled) {
      Sentry.setUser(user);
    }
  };
}
