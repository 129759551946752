import { AtrigamFirestoreActivity } from './firestore.types';
import { createActivityEntryMutation } from './mutations/createActivityEntry.mutation';
import { trackingConfiguration } from './setup';

export type TrackOptions = Omit<
  AtrigamFirestoreActivity,
  'app' | 'appVersion' | 'uid' | 'Browser' | 'build' | 'OS' | 'appOwnership'
>;

export const track = async (data: TrackOptions) => {
  if (!trackingConfiguration) {
    throw new Error('Setup Tracking first!');
  }

  // is tracking been disabled
  const { isEnabled, ...config } = trackingConfiguration;
  if (!isEnabled) {
    return;
  }

  await createActivityEntryMutation({ ...config, ...data });
};
