import {
  AtrigamTaskFlowId,
  AtrigamTaskFlowName,
  AtrigamUniverseAreaTaskFlow,
} from '../taskFlow.types';
import { AtrigamAreaId, AtrigamAreaName, AtrigamUniverseName } from '../universe.types';

interface Options {
  area: AtrigamAreaName | AtrigamAreaId;
  taskFlow: AtrigamTaskFlowName | AtrigamTaskFlowId;
  universe: AtrigamUniverseName;
}

export const createAtrigamUniverseAreaTaskFlow = ({ universe, area, taskFlow }: Options) =>
  `${universe}:${area}:${taskFlow}` as AtrigamUniverseAreaTaskFlow;
