import {
  AtrigamEnvironment,
  AtrigamModelsModel,
  AtrigamUniverseRegistrationClientRole,
  AtrigamUniverseRegistrationModelerUser,
  AtrigamUser,
  UID,
  type AtrigamUniverseRegistration,
} from '@atrigam/atrigam-types';
import { action, computed, makeObservable, observable } from 'mobx';

import { AtrigamUniverseBackupConfiguration } from '../../../firestore/atrigam-backup/backup.types';

interface Options {
  universeRegistration: AtrigamUniverseRegistration;
  userMap: Map<UID, AtrigamUser>;
  modelerUsers: AtrigamUniverseRegistrationModelerUser[];
  clientRoles: AtrigamUniverseRegistrationClientRole[];
  universeEnvironmentMap: Map<AtrigamEnvironment, AtrigamModelsModel | undefined>;
  loadedClientUsers: boolean;
  clientRolesUserCount: number;
  backupConfigurationMap: Map<AtrigamEnvironment, AtrigamUniverseBackupConfiguration>;
}

export class UniverseRegistrationPageStore {
  @observable
  universeRegistration: AtrigamUniverseRegistration;

  @observable
  userMap = observable.map<UID, AtrigamUser>();

  @observable
  modelerUsers: AtrigamUniverseRegistrationModelerUser[] = [];

  @observable
  clientRoles: AtrigamUniverseRegistrationClientRole[] = [];

  @observable
  universeEnvironmentMap = observable.map<AtrigamEnvironment, AtrigamModelsModel | undefined>();

  @observable
  loadedClientUsers = false;

  @observable
  clientRolesUserCount = 0;

  @observable
  backupConfigurationMap = observable.map<AtrigamEnvironment, AtrigamUniverseBackupConfiguration>();

  constructor({
    universeRegistration,
    userMap,
    modelerUsers,
    clientRoles,
    universeEnvironmentMap,
    loadedClientUsers,
    clientRolesUserCount,
    backupConfigurationMap,
  }: Options) {
    makeObservable(this);

    this.universeRegistration = universeRegistration;
    this.modelerUsers = modelerUsers;
    this.clientRoles = clientRoles;
    this.loadedClientUsers = loadedClientUsers;
    this.clientRolesUserCount = clientRolesUserCount;

    [...userMap.values()].forEach((user) => {
      this.userMap.set(user.uid, user);
    });

    universeEnvironmentMap.forEach((universeModel, environment) => {
      this.universeEnvironmentMap.set(environment, universeModel);
    });

    backupConfigurationMap.forEach((backupConfiguration, environment) => {
      this.backupConfigurationMap.set(environment, backupConfiguration);
    });
  }

  @computed
  get universeModels() {
    return [
      AtrigamEnvironment.Modeler,
      AtrigamEnvironment.Testing,
      AtrigamEnvironment.Staging,
      AtrigamEnvironment.Production,
    ].map((environment) => {
      const universeModel = this.universeEnvironmentMap.get(environment);

      const taskFlowModelList = universeModel?.flows ? Object.values(universeModel.flows) : [];
      taskFlowModelList.sort((a, b) => a.flow.localeCompare(b.flow));

      return {
        environment,
        hasModel: universeModel !== undefined,
        taskFlowModelList,
      };
    });
  }

  @action
  setIsTestUniverse = (isTestUniverse: boolean) => {
    this.universeRegistration.isTestUniverse = isTestUniverse;
  };

  @action
  setLoadedClientUsers = (hasLoaded: boolean) => {
    this.loadedClientUsers = hasLoaded;
  };

  @action
  updateBackupConfiguration = ({
    environment,
    backupConfiguration,
  }: {
    environment: AtrigamEnvironment;
    backupConfiguration: AtrigamUniverseBackupConfiguration;
  }) => {
    this.backupConfigurationMap.set(environment, backupConfiguration);
  };

  @action
  deleteBackupConfiguration = ({ environment }: { environment: AtrigamEnvironment }) => {
    this.backupConfigurationMap.delete(environment);
  };
}
