import { Box, CircularProgress, Toolbar } from '@mui/material';
import React, { Suspense } from 'react';

import { PageLoadingContext } from '../../contexts/PageLoadingContext';
import { reactive } from '../../helpers/reactive';
import { RouteSlot } from '../../patterns/RouteSlot/RouteSlot';
import { LazyPlaceholder } from '../../primitive/LazyPlaceholder/LazyPlaceholder';
import { Typography } from '../../primitive/Typography/Typography.style';

import { NaviContext } from './contexts/NaviContext';
import { Main } from './features/Main/Main';
import { BackdropWrapper } from './styles/BackdropWrapper.style';
import { Content } from './styles/Content.style';
import { LayoutWrapper } from './styles/LayoutWrapper.style';

const DefaultLayoutComponent: React.FC = () => {
  const { isPageLoading, pageLoadingMessage } = React.useContext(PageLoadingContext);
  const [isNaviOpened, setIsNaviOpened] = React.useState(false);
  const toggleNavi = React.useCallback(() => {
    setIsNaviOpened(!isNaviOpened);
  }, [isNaviOpened, setIsNaviOpened]);

  const naviContextValue = React.useMemo(
    () => ({
      isOpen: isNaviOpened,
      toggleNavi,
    }),
    [isNaviOpened, toggleNavi],
  );

  return (
    <NaviContext.Provider value={naviContextValue}>
      <LayoutWrapper>
        <Suspense fallback={<LazyPlaceholder />}>
          <RouteSlot slot="header" />
        </Suspense>

        <Box display="flex" flexDirection="row">
          <Suspense fallback={<LazyPlaceholder />}>
            <RouteSlot slot="navi" />
          </Suspense>

          <Content>
            <Toolbar />
            <Suspense fallback={<LazyPlaceholder />}>
              <Main>
                <BackdropWrapper open={isPageLoading}>
                  <CircularProgress size={24} />{' '}
                  <Typography variant="h6" color="secondary">
                    {pageLoadingMessage}
                  </Typography>
                </BackdropWrapper>

                <RouteSlot slot="breadcrumbs" />
                <RouteSlot slot="main" />
              </Main>
            </Suspense>
          </Content>
        </Box>
      </LayoutWrapper>
    </NaviContext.Provider>
  );
};

export const DefaultLayout = reactive(DefaultLayoutComponent);
