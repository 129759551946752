// routes
import { ActivityLogRoute } from './routes/activityLog/activityLog.route';
import { HomeRoute } from './routes/home/home.route';
import { KPIsRoute } from './routes/kpis/kpis.route';
import { LoginRoute } from './routes/login/login.route';
import { LogoutRoute } from './routes/logout/logout.route';
import { NotFoundRoute } from './routes/notFound/notFound.route';
import { UniverseRegistrationRoute } from './routes/universeRegistration/universeRegistration.route';
import { UniverseRegistrationListRoute } from './routes/universeRegistrationList/universeRegistrationList.route';
import { UserRoute } from './routes/user/user.route';
import { UsersListRoute } from './routes/usersList/usersList.route';

export const routes = {
  // Unauthorized Routes
  LoginRoute,
  LogoutRoute,

  // Authorized Routes
  ActivityLogRoute,
  HomeRoute,
  KPIsRoute,
  UserRoute,
  UsersListRoute,
  UniverseRegistrationRoute,
  UniverseRegistrationListRoute,

  // Not found route
  NotFoundRoute,
};
