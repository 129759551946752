import React from 'react';

export const useGenericModalState = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const handleOpenModal = React.useCallback(
    (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (event) {
        event.preventDefault();
      }

      setModalOpen(true);
      return false;
    },
    [setModalOpen],
  );

  const handleCloseModal = React.useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>) => {
      if (event) {
        event.stopPropagation();
      }

      setModalOpen(false);
    },
    [setModalOpen],
  );

  return {
    isModalOpen,
    handleOpenModal,
    handleCloseModal,
  };
};
