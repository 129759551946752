import { ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';

import { reactive } from '../../../../../../helpers/reactive';
import { useActiveState } from '../../../../../../primitive/Link/hooks/useActiveState';
import { usePreloading } from '../../../../../../primitive/Link/hooks/usePreloading';
import { Registry } from '../../../../../../services/Registry/Registry';
import { AnyRoutePath } from '../../../../../../services/Router/Router.types';
import { NaviContext } from '../../../../contexts/NaviContext';

import { NaviItemWrapper } from './styles/NaviItemWrapper.style';

export interface Properties {
  activeRoutes?: AnyRoutePath[];
  to: string;
  icon: React.ReactElement;
  label: string;
}

const NaviItemComponent: React.FC<Properties> = ({ activeRoutes, icon, label, to }) => {
  const { isOpen } = React.useContext(NaviContext);

  const router = Registry.get('router');

  const { isActive } = useActiveState({
    to,
    activeRoutes,
  });

  const { onMouseOver, onMouseOut } = usePreloading({ to });

  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      // use router navigation
      event.preventDefault();
      void router.goTo({ url: to });
    },
    [router, to],
  );

  return (
    <NaviItemWrapper
      onClick={onClick}
      $isActive={isActive}
      $isNavigationOpen={isOpen}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      component="a"
      href={to}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </NaviItemWrapper>
  );
};

export const NaviItem = reactive(NaviItemComponent);
