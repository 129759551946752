import { Services, RegistryType, MockableRegistryType } from '../Registry.types';

import { createMockableRegistry } from './createMockableRegistry';
import { createRegistry } from './createRegistry';

type Result<S extends Services> = RegistryType<S> | MockableRegistryType<S>;

export const initializeRegistry = <S extends Services>(testing: boolean): Result<S> => {
  // we only want mocking capabilities when testing
  if (testing) {
    return createMockableRegistry<S>(testing);
  }

  return createRegistry<S>(testing);
};
