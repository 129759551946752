import { useTheme } from '@mui/material';

import { reactive } from '../../helpers/reactive';
import { AtrigamTheme } from '../../typings/atrigamTheme';

interface Properties {
  height?: number | string;
  width?: number | string;
  style?: React.CSSProperties;
}

const LogoComponent: React.FC<Properties> = ({ height = 40, width = 120, style }) => {
  const theme = useTheme<AtrigamTheme>();

  return <img src={theme.logo} alt="logo" height={height} width={width} style={style} />;
};

export const Logo = reactive(LogoComponent);
