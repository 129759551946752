import {
  BarChart,
  DashboardOutlined,
  FilterListOutlined,
  Group,
  Public,
} from '@mui/icons-material';
import { List } from '@mui/material';
import React from 'react';

import { reactive } from '../../../../helpers/reactive';
import { ActivityLogPath } from '../../../../routes/activityLog/activityLog.path';
import { HomePath } from '../../../../routes/home/home.path';
import { KPIsPath } from '../../../../routes/kpis/kpis.path';
import { UniverseRegistrationPath } from '../../../../routes/universeRegistration/universeRegistration.path';
import { UniverseRegistrationListPath } from '../../../../routes/universeRegistrationList/universeRegistrationList.path';
import { UserPath } from '../../../../routes/user/user.path';
import { UsersListPath } from '../../../../routes/usersList/usersList.path';
import { NaviContext } from '../../contexts/NaviContext';

import { MobileBackButton } from './features/MobileBackButton/MobileBackButton';
import { NaviItem } from './features/NaviItem/NaviItem';
import { useWindowResize } from './hooks/useWindowResize';
import { NaviWrapper } from './styles/NaviWrapper.style';
import { ToolbarSpacer } from './styles/ToolbarSpacer.style';

const NaviComponent: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { isOpen, toggleNavi } = React.useContext(NaviContext);
  const { isPermanent } = useWindowResize();

  const variant = isPermanent ? 'permanent' : 'temporary';

  // close navigation if we are clicking on a nav item
  React.useEffect(() => {
    return () => {
      if (isOpen) {
        toggleNavi();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NaviWrapper open={isOpen} variant={variant}>
      <ToolbarSpacer />

      <MobileBackButton toggleNavi={toggleNavi} />

      <List>
        <NaviItem
          to={HomePath.getLink()}
          activeRoutes={[HomePath]}
          icon={<DashboardOutlined />}
          label="Overview"
        />

        <NaviItem
          to={ActivityLogPath.getLink({ params: {}, query: {} })}
          activeRoutes={[ActivityLogPath]}
          icon={<FilterListOutlined />}
          label="Activity Log"
        />

        <NaviItem
          to={KPIsPath.getLink({ params: {}, query: {} })}
          activeRoutes={[KPIsPath]}
          icon={<BarChart />}
          label="KPIs"
        />

        <NaviItem
          to={UsersListPath.getLink({ params: {}, query: {} })}
          activeRoutes={[UsersListPath, UserPath]}
          icon={<Group />}
          label="Users"
        />

        <NaviItem
          to={UniverseRegistrationListPath.getLink({ params: {}, query: {} })}
          activeRoutes={[UniverseRegistrationListPath, UniverseRegistrationPath]}
          icon={<Public />}
          label="Universe Registrations"
        />
      </List>

      {children}
    </NaviWrapper>
  );
};

export const Navi = reactive(NaviComponent);

/**
 * {
    id: 50,
    label: "Overview",
    link: "/app/overview",
    hasUniverses: true,
    icon: <HomeIcon />,
  },
 */
