// Atrigam Domain: Universe

import { FirestoreTimestamp } from '../utilities/date.types';
import { Brand } from '../utilities/utility.types';

import { Currency } from './common.types';
import { AtrigamObjectName } from './objectModel.types';
import { AtrigamTaskFlowId, AtrigamTaskFlowName } from './taskFlow.types';
import { UID } from './user.types';
import { AtrigamWorkItemSchemaId } from './workItem.schema.types';
import { AtrigamWorkItemId } from './workItem.types';

export type AtrigamAreaName = Brand<string, 'AreaName'>;
export type AtrigamAreaId = Brand<string, 'AreaId'>;

export type AtrigamUniverseName = Brand<string, 'UniverseName'>;
export type AtrigamUniverseNodeName = Brand<string, 'UniverseNameLowerCase'>;
export type AtrigamUniverseId = Brand<string, 'UniverseId'>;

export type AtrigamDatabaseUniverseEnvironment = Brand<string, 'DatabaseUniverseEnvironment'>;
export type AtrigamDatabaseUniverse = Brand<string, 'DatabaseUniverse'>;

export type AtrigamRole = Brand<string, 'Role'>;
export type AtrigamRoleId = Brand<string, 'RoleId'>;

export enum AtrigamEnvironment {
  Modeler = 'modeler',
  Production = 'production',
  Testing = 'testing',
  Staging = 'staging',
}

export enum AtrigamRoleAssignmentType {
  Granted = 'granted',
  Revoked = 'revoked',
}

export enum AtrigamRoleAssignmentOrigin {
  Modeler = 'modeler',
  Invite = 'invite',
}

// Universe Registration

export interface AtrigamUniverseRegistration {
  createdAt?: FirestoreTimestamp;
  createdBy?: UID;
  isSandboxUniverse?: boolean;
  isTestUniverse?: boolean;
  registered: boolean;
  reserved?: boolean;
  universeDB: AtrigamDatabaseUniverse;
  universeLabel: string;
  universeName: AtrigamUniverseName;
  universeNodeName: AtrigamUniverseNodeName;
  updatedAt: FirestoreTimestamp;
  updatedBy?: UID;
  // TODO: new fields AT2181
  isRegistered?: boolean;
  isReserved?: boolean;
  isUndeletable?: boolean;
  universeId?: AtrigamUniverseId;
}

export interface AtrigamUniverseRegistrationClientRole {
  area: AtrigamAreaName;
  assignedBy?: UID;
  createdAt: FirestoreTimestamp;
  environment: AtrigamEnvironment;
  flow: AtrigamTaskFlowName;
  objectName: AtrigamObjectName;
  revokedBy?: UID;
  role: AtrigamRole;
  roleAssignmentOrigin?: AtrigamRoleAssignmentOrigin;
  uid: UID;
  universe: AtrigamUniverseName;
  // TODO: new fields AT2181
  areaId?: AtrigamAreaId;
  taskFlowId?: AtrigamTaskFlowId;
  schemaId?: AtrigamWorkItemSchemaId;
  roleId?: AtrigamRoleId;
  universeId?: AtrigamUniverseId;
}

export interface AtrigamUniverseRegistrationClientRolesHistory {
  area: AtrigamAreaName;
  assignedBy?: UID;
  clientRoleNode: AtrigamWorkItemId;
  createdAt: FirestoreTimestamp;
  environment: AtrigamEnvironment;
  flow: AtrigamTaskFlowName;
  objectName?: AtrigamObjectName;
  revokedBy?: UID;
  role: AtrigamRole;
  roleAssignmentType: AtrigamRoleAssignmentType;
  roleAssignmentOrigin?: AtrigamRoleAssignmentOrigin;
  uid: UID;
  universe: AtrigamUniverseName;
  // TODO: new fields AT2181
  areaId?: AtrigamAreaId;
  taskFlowId?: AtrigamTaskFlowId;
  schemaId?: AtrigamWorkItemSchemaId;
  roleId?: AtrigamRoleId;
  universeId?: AtrigamUniverseId;
}

export interface AtrigamUniverseRegistrationModelerUser {
  permissions?: string[];
  uid: UID;
  createdAt: FirestoreTimestamp;
}

export enum AtrigamUniverseRegistrationApiConfigurationType {
  CreateOrUpdateWorkItem = 'CreateOrUpdateWorkItem',
  XotigoPocProgramPlan = 'XotigoPocProgramPlan',
}

export type AtrigamUniverseRegistrationApiConfiguration =
  | AtrigamUniverseRegistrationApiConfigurationCreateOrUpdateWorkItem
  | AtrigamUniverseRegistrationApiConfigurationXotigoPocProgramPlan;

export interface AtrigamUniverseRegistrationApiConfigurationBase {
  id: string;
  isEnabled: boolean;
  areaId: AtrigamAreaId;
  createdAt: FirestoreTimestamp;
  taskFlowId: AtrigamTaskFlowId;
  universeId: AtrigamUniverseId;
  universeName: AtrigamUniverseName;
}

export interface AtrigamUniverseRegistrationApiConfigurationCreateOrUpdateWorkItem
  extends AtrigamUniverseRegistrationApiConfigurationBase {
  apiType: AtrigamUniverseRegistrationApiConfigurationType.CreateOrUpdateWorkItem;
  authToken: string;
  fieldMapping: AtrigamUniverseRegistrationApiConfigurationFieldMapping[];
  uniqueSourceFieldId: string;
}

export interface AtrigamUniverseRegistrationApiConfigurationXotigoPocProgramPlan
  extends AtrigamUniverseRegistrationApiConfigurationBase {
  apiType: AtrigamUniverseRegistrationApiConfigurationType.XotigoPocProgramPlan;
  objectName: AtrigamObjectName;
  fieldMapping: AtrigamUniverseRegistrationApiConfigurationFieldMapping[];
  uniqueSourceFieldId: string;
  salesForceApiUrlTemplate: string;
  salesForceAuthApiUrl: string;
  salesForceAuthClientId: string;
  salesForceAuthClientSecret: string;
  salesForceAuthUserName: string;
  salesForceAuthUserPassword: string;
  formatOptions?: {
    currency?: Currency;
  };
}

export interface AtrigamUniverseRegistrationApiConfigurationFieldMapping {
  sourceName: string;
  targetName: string;
  // todo parseData config
}
