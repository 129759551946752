import { Breadcrumbs } from '../../../layouts/DefaultLayout/features/Breadcrumbs/Breadcrumbs';
import { Header } from '../../../layouts/DefaultLayout/features/Header/Header';
import { Navi } from '../../../layouts/DefaultLayout/features/Navi/Navi';
import {
  RouteComponents,
  RouteOptionsComponents,
  RouteScopesWithDefaultLayout,
} from '../Router.types';

const defaultSlotComponents: Omit<RouteComponents<RouteScopesWithDefaultLayout>, 'main'> = {
  breadcrumbs: Breadcrumbs,
  header: Header,
  navi: Navi,
};

const getSlotComponent = <Scope extends RouteScopesWithDefaultLayout>(options: {
  slot: Exclude<keyof RouteComponents<RouteScopesWithDefaultLayout>, 'main'>;
  routeComponents: RouteOptionsComponents<Scope>;
}) => {
  const { slot, routeComponents } = options;
  const component = routeComponents[slot];

  // we don't want to render this slot at all for this route
  // none -> undefined
  if (component === 'none') {
    return;
  }

  // this slot renders the provided component for this route
  // component -> component
  if (component !== undefined) {
    return component;
  }

  // we did not provide any value for this slot so we're using the default slot component
  // undefined -> default component
  return defaultSlotComponents[slot];
};

interface Options<Scope extends RouteScopesWithDefaultLayout> {
  routeComponents: RouteOptionsComponents<Scope>;
}

export const getRouteComponentsForDefaultLayout = <Scope extends RouteScopesWithDefaultLayout>(
  options: Options<Scope>,
): RouteComponents<RouteScopesWithDefaultLayout> => {
  const { routeComponents } = options;

  const components: RouteComponents<RouteScopesWithDefaultLayout> = {
    // main slot is always required
    main: routeComponents.main,

    // handle optional slots
    breadcrumbs: getSlotComponent({ slot: 'breadcrumbs', routeComponents }),
    header: getSlotComponent({ slot: 'header', routeComponents }),
    navi: getSlotComponent({ slot: 'navi', routeComponents }),
  };

  return components;
};
