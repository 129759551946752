import { UsersListPageAsync } from '../../pages/UsersList/UsersList.page.async';
import { RouteScope } from '../../services/Router/Router.types';
import { createRoute } from '../../services/Router/helpers/createRoute';

import { pattern } from './usersList.path';

export const UsersListRoute = createRoute({
  title: () => 'Users Administration',
  components: {
    main: UsersListPageAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,
  meta: {
    noPadding: true,
  },
});
