import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider } from 'styled-components';

import { logoutUser } from '../../api/logoutUser';
import { PageLoadingContext } from '../../contexts/PageLoadingContext';
import { ThemeContext } from '../../contexts/ThemeContext';
import { reactive } from '../../helpers/reactive';
import { Layouts } from '../../layouts/Layouts';

import { GlobalStyleCollection } from './GlobalStyleCollection';
import { ErrorFallback } from './features/ErrorFallback/ErrorFallback';
import { RouterTransition } from './features/RouterTransition/RouterTransition';
import { SnackbarNotifier } from './features/SnackbarNotifier/SnackbarNotifier';
import { ThemedSnackbarProvider } from './features/ThemedSnackbarProvider/ThemedSnackbarProvider';
import { UpdateAvailableNotification } from './features/UpdateAvailableNotification/UpdateAvailableNotification';
import { handleErrorBoundaryError } from './helpers/handleErrorBoundaryError';
import { usePageLoading } from './hooks/usePageLoading';
import { useRemoveInitialLoader } from './hooks/useRemoveInitialLoader';
import { useTheme } from './hooks/useTheme';

const AppComponent = () => {
  const { currentTheme, themeContextValue, isThemeInitialized } = useTheme();
  const { pageLoadingContextValue } = usePageLoading();
  useRemoveInitialLoader();

  const handleCrashRecovery = React.useCallback(async () => {
    await logoutUser({});
  }, []);

  // need to make sure the router is ready for everything outside Layouts
  // (as Layouts takes care, Toasts .. etc. not!)
  if (!isThemeInitialized) {
    return null;
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={handleErrorBoundaryError}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onReset={handleCrashRecovery}
    >
      <ThemeContext.Provider value={themeContextValue}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={currentTheme}>
            <ThemeProvider theme={currentTheme}>
              <PageLoadingContext.Provider value={pageLoadingContextValue}>
                <ThemedSnackbarProvider>
                  <GlobalStyleCollection />

                  <RouterTransition />
                  <SnackbarNotifier />
                  <UpdateAvailableNotification />

                  <Layouts />
                </ThemedSnackbarProvider>
              </PageLoadingContext.Provider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </ThemeContext.Provider>
    </ErrorBoundary>
  );
};

export const App = reactive(AppComponent);
