import { watchAuthStateChanged } from '@atrigam/server-functions-eu-client';

import { logoutUser } from '../../../api/logoutUser';
import { logger } from '../../../helpers/logger';
import { Registry } from '../../../services/Registry/Registry';
import { UserStore } from '../User.store';

let firstTime = true;

export const watchAuthState = (userStore: UserStore) => {
  // handle if user gets logged out in another tab
  const unsubscribeAuth = watchAuthStateChanged({
    onUpdate: (user) => {
      if (firstTime) {
        firstTime = false;
        return;
      }

      if (!user && userStore.user && !userStore.isChangingLoginState) {
        logger.log('user is not logged in anymore. Logging out...');

        Registry.get('snackbar').addNotification({
          message: 'Session is invalid. Please login again.',
          options: {
            variant: 'error',
            persist: false,
          },
        });

        void logoutUser({});
      }
    },
  });
  userStore.disposers.push(unsubscribeAuth);
};
