import { getApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';

import { APP_NAME_EU } from '../clientSDK.constants';
import { WatcherFunction } from '../types/watchers.types';

export const watchAuthStateChanged: WatcherFunction<User> = ({ onUpdate, onError }) => {
  const auth = getAuth(getApp(APP_NAME_EU));

  return onAuthStateChanged(
    auth,
    (user) => {
      // eslint-disable-next-line unicorn/no-useless-undefined
      void onUpdate(user ?? undefined, undefined);
    },
    (error) => {
      if (onError && !onError(error)) {
        return;
      }

      throw error;
    },
  );
};
