import { useTheme } from '@mui/material';
import React from 'react';

export const useWindowResize = () => {
  const theme = useTheme();
  const [isPermanent, setIsPermanent] = React.useState(true);

  const handleWindowWidthChange = React.useCallback(() => {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setIsPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setIsPermanent(true);
    }
  }, [theme, isPermanent, setIsPermanent]);

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return () => {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  return { isPermanent };
};
