import {
  AtrigamFirestoreUniverseRegistrationsRegistrationModelerUsersCollectionPath,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamUniverseRegistrationModelerUser,
  createAtrigamUniverseNodeName,
} from '@atrigam/atrigam-types';
import { APP_NAME_EU } from '@atrigam/server-functions-eu-client';
import { getApp } from 'firebase/app';
import { collection, getDocs, getFirestore, orderBy, query } from 'firebase/firestore';

interface Options {
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

export const getUniverseRegistrationModelerUsersQuery = async ({ universe }: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const universeNodeName = createAtrigamUniverseNodeName(universe);
  const path: AtrigamFirestoreUniverseRegistrationsRegistrationModelerUsersCollectionPath = `/universeRegistration/${universeNodeName}/modelerUsers`;
  const reference = collection(database, path);

  let query_ = query(reference);

  query_ = query(query_, orderBy('createdAt', 'asc'));

  const collection_ = await getDocs(query_);

  return collection_.docs.map(
    (snapshot) => snapshot.data() as AtrigamUniverseRegistrationModelerUser,
  );
};
