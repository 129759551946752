import { AtrigamUser, UID } from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { DocumentData, DocumentReference, doc, getFirestore } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamUserPath } from '../paths/createAtrigamUserPath';

interface Options {
  uid: UID;
}

export const getUserQueryReference = ({ uid }: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamUserPath({ uid });
  return doc(database, path) as DocumentReference<AtrigamUser, DocumentData>;
};
