import styled from 'styled-components';

import { ThemedStyledComponent } from '../../../themes/Themes.types';

interface Properties extends ThemedStyledComponent {
  fullWidth: boolean;
}

export const LayoutWrapper = styled.div<Properties>(
  ({ fullWidth, theme }: Properties) => `
  display: flex;
  margin: auto;
  margin-top: 0px;
  width: 100%;
  max-width: 100%;

  ${theme.breakpoints.up('sm')} {
    margin-top: ${theme.spacing(20)};
    width: ${fullWidth ? '80%' : theme.spacing(42)};
  } ;

  ${theme.breakpoints.down('md')} {
    & > .MuiPaper-root {
      box-shadow: none;
      display: flex;
      flex-direction: column;
    }
  } ;
  `,
);
