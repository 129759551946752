import { VictoryThemeDefinition } from 'victory';

export const colorChartBlue = '#1f77b4';
export const colorChartLightBlue = '#aec7e8';
export const colorChartOrange = '#ff7f0e';
export const colorChartLightOrange = '#ffbb78';
export const colorChartGreen = '#2ca02c';
export const colorChartLightGreen = '#98df8a';
export const colorChartRed = '#d62728';
export const colorChartLightRed = '#ff9896';
export const colorChartViolet = '#9467bd';
export const colorChartLightViolet = '#c5b0d5';
export const colorChartBrown = '#8c564b';
export const colorChartLightBrown = '#c49c94';
export const colorChartPink = '#e377c2';
export const colorChartLightPink = '#f7b6d2';
export const colorChartGray = '#7f7f7f';
export const colorChartLightGray = '#c7c7c7';
export const colorChartLime = '#bcbd22';
export const colorChartLightLime = '#dbdb8d';
export const colorChartTurquoise = '#17becf';
export const colorChartLightTurquoise = '#9edae5';

const blueGrey50 = '#ECEFF1';
// const blueGrey300 = '#90A4AE';
const blueGrey700 = '#455A64';
const grey900 = '#212121';

const colors = [
  colorChartBlue,
  colorChartOrange,
  colorChartGreen,
  colorChartRed,
  colorChartViolet,
  colorChartBrown,
  colorChartPink,
  colorChartGray,
  colorChartLime,
  colorChartTurquoise,
];

const chartBaseProperties = {
  width: 450 * 5,
  height: 450,
  padding: { top: 50, left: 100, right: 100, bottom: 180 },
};

const padding = 8;
const strokeLinecap = 'round';
const strokeLinejoin = 'round';

const chartBaseLabelStyles = {
  fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
  fontSize: 26,
  letterSpacing: 'normal',
  padding,
  fill: '#a1a1a1',
  stroke: 'transparent',
  strokeWidth: 0,
};

export const chartStyles: VictoryThemeDefinition = {
  area: {
    ...chartBaseProperties,
    style: {
      data: {
        fill: colorChartBlue,
      },
      labels: chartBaseLabelStyles,
    },
  },
  axis: {
    ...chartBaseProperties,
    style: {
      axis: {
        fill: 'transparent',
        stroke: '#ccc',
        strokeLinecap,
        strokeLinejoin,
      },
      axisLabel: {
        ...chartBaseLabelStyles,
        textAnchor: 'middle',
        padding,
        stroke: 'transparent',
      },
      grid: {
        fill: 'none',
        stroke: '#a1a1a1',
        // strokeDasharray: '10, 5',
        strokeLinecap,
        strokeLinejoin,
        strokeWidth: 0.25,
        pointerEvents: 'painted',
      },
      ticks: {
        fill: 'transparent',
        size: 5,
        stroke: '#ccc',
        strokeWidth: 1,
        strokeLinecap,
        strokeLinejoin,
      },
      tickLabels: {
        ...chartBaseLabelStyles,
        fontSize: 24,
      },
    },
  },
  polarDependentAxis: {
    style: {
      ticks: {
        fill: 'transparent',
        size: 1,
        stroke: 'transparent',
      },
    },
  },
  bar: {
    ...chartBaseProperties,
    style: {
      data: {
        fill: colorChartBlue,
        padding,
        stroke: colorChartBlue,
        strokeWidth: 2,
      },
      labels: chartBaseLabelStyles,
    },
  },
  boxplot: {
    ...chartBaseProperties,
    style: {
      max: { padding, stroke: colorChartBlue, strokeWidth: 1 },
      maxLabels: { ...chartBaseLabelStyles, padding: 3 },
      median: { padding, stroke: colorChartBlue, strokeWidth: 1 },
      medianLabels: { ...chartBaseLabelStyles, padding: 3 },
      min: { padding, stroke: colorChartBlue, strokeWidth: 1 },
      minLabels: { ...chartBaseLabelStyles, padding: 3 },
      q1: { padding, fill: colorChartBlue },
      q1Labels: { ...chartBaseLabelStyles, padding: 3 },
      q3: { padding, fill: colorChartBlue },
      q3Labels: { ...chartBaseLabelStyles, padding: 3 },
    },
    boxWidth: 20,
  },
  candlestick: {
    ...chartBaseProperties,
    style: {
      data: {
        stroke: colorChartBlue,
      },
      labels: { ...chartBaseLabelStyles, padding: 5 },
    },
    candleColors: {
      positive: '#ffffff',
      negative: colorChartBlue,
    },
  },
  chart: { ...chartBaseProperties, domainPadding: { x: 100 } },
  errorbar: {
    ...chartBaseProperties,
    borderWidth: 8,
    style: {
      data: {
        fill: 'transparent',
        opacity: 1,
        stroke: blueGrey700,
        strokeWidth: 2,
      },
      labels: chartBaseLabelStyles,
    },
  },
  group: {
    ...chartBaseProperties,
    colorScale: colors,
  },
  histogram: {
    ...chartBaseProperties,
    style: {
      data: {
        fill: blueGrey700,
        stroke: grey900,
        strokeWidth: 2,
      },
      labels: chartBaseLabelStyles,
    },
  },
  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: 'vertical',
    titleOrientation: 'top',
    style: {
      data: {
        type: 'circle',
      },
      labels: chartBaseLabelStyles,
      title: { ...chartBaseLabelStyles, padding: 5 },
    },
  },
  line: {
    ...chartBaseProperties,
    style: {
      data: {
        fill: 'transparent',
        opacity: 1,
        stroke: colorChartBlue,
        strokeWidth: 2,
      },
      labels: chartBaseLabelStyles,
    },
  },
  pie: {
    ...chartBaseProperties,
    colorScale: colors,
    style: {
      data: {
        padding,
        stroke: blueGrey50,
        strokeWidth: 1,
      },
      labels: { ...chartBaseLabelStyles, padding: 20 },
    },
  },
  scatter: {
    ...chartBaseProperties,
    style: {
      data: {
        fill: colorChartLightBlue,
        opacity: 0.5,
        stroke: colorChartBlue,
        strokeWidth: 3,
      },
      labels: chartBaseLabelStyles,
    },
  },
  stack: {
    ...chartBaseProperties,
    colorScale: colors,
  },
  tooltip: {
    style: {
      ...chartBaseLabelStyles,
      fill: '#eee',
      pointerEvents: 'none',
      fontSize: 30,
    },
    flyoutStyle: {
      stroke: 'transparent',
      strokeWidth: 1,
      fill: colorChartBlue,
      pointerEvents: 'none',
    },
    flyoutPadding: 5,
    cornerRadius: 5,
    pointerLength: 10,
  },
  voronoi: {
    ...chartBaseProperties,
    style: {
      data: {
        fill: 'transparent',
        stroke: 'transparent',
        strokeWidth: 0,
      },
      labels: { ...chartBaseProperties, padding: 5, pointerEvents: 'none' },
      flyout: {
        stroke: grey900,
        strokeWidth: 1,
        fill: '#f0f0f0',
        pointerEvents: 'none',
      },
    },
  },
};
