import { IEqualsComparer, IReactionPublic, comparer, reaction, toJS } from 'mobx';

export const withReaction = <T>(options: {
  name: string;
  data: () => T;
  onChange: (data: T, previousData: T | undefined, reaction: IReactionPublic) => void;
  debounce?: number;
  fireImmediately?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  equals?: IEqualsComparer<any>;
}) => {
  const debounce = options.debounce ?? 300;
  const fireImmediately = options.fireImmediately ?? false;
  const equals = options.equals ?? comparer.structural;

  return reaction(() => toJS(options.data()), options.onChange, {
    name: `${options.name} withReaction`,
    delay: debounce,
    fireImmediately,
    equals,
  });
};
