// Universes Data via Firestore
export const firestoreConfig = {
  apiKey: 'AIzaSyA9pfdDaEJZ6RhU2GDkmMfGncXuxsAfTrk',
  authDomain: 'atrigam-eu.firebaseapp.com',
  projectId: 'atrigam-eu',
  storageBucket: 'atrigam-eu.appspot.com',
  messagingSenderId: '694979860485',
  persistence: false,
  databaseURL: 'https://atrigam-eu.firebaseio.com',
};
