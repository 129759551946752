import { State } from 'router5';

/**
 * router5 saves meta information in the state.meta including the route param keys
 */
export const extractParameterKeysFromRouteStateMeta = (state: State) => {
  if (!state.meta?.params[state.name]) {
    return [];
  }

  return Object.keys(state.meta.params[state.name] as Record<string, unknown>);
};
