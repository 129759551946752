import {
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
  AtrigamUniverseRegistration,
} from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { DocumentData, DocumentReference, doc, getFirestore } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamUniverseRegistrationPath } from '../paths/createAtrigamUniverseRegistrationPath';

interface Options {
  universe: AtrigamUniverseNodeName | AtrigamUniverseName;
}

export const getUniverseRegistrationQueryReference = ({ universe }: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamUniverseRegistrationPath({ universe });
  return doc(database, path) as DocumentReference<AtrigamUniverseRegistration, DocumentData>;
};
