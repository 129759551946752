/* eslint-disable @typescript-eslint/naming-convention */
import { TinyColor } from '@ctrl/tinycolor';
import { PaletteOptions, TypeBackground, TypeText, alpha, createTheme } from '@mui/material/styles';
// eslint-disable-next-line import/no-unassigned-import
import type {} from '@mui/x-data-grid/themeAugmentation';

import { atrigamColors } from './atrigamColors';
import {
  chartStyles,
  colorChartBlue,
  colorChartBrown,
  colorChartGray,
  colorChartGreen,
  colorChartLightBlue,
  colorChartLightBrown,
  colorChartLightGray,
  colorChartLightGreen,
  colorChartLightLime,
  colorChartLightOrange,
  colorChartLightPink,
  colorChartLightRed,
  colorChartLightTurquoise,
  colorChartLightViolet,
  colorChartLime,
  colorChartOrange,
  colorChartPink,
  colorChartRed,
  colorChartTurquoise,
  colorChartViolet,
} from './chartStyles';

const primary = '#D4DE25'; // green - 3c
const secondary = '#1b5e46'; // blue - 3a
const tertiary = '#e0e0e0';
const warning = '#fdde20'; // green - 3e
const error = '#ea4848';
const success = '#84c443'; // green - 3a
const info = '#00aeff';

const textPrimary = '#4A4A4A';
const textPrimaryContrast = '#FFFFFF';
const textSecondaryContrast = '#6E6E6E';

const lightenRate = 7.5;
const darkenRate = 15;

const ODD_OPACITY = 0.2;

export const lightTheme = createTheme({
  // @ts-expect-error we want the logo
  logo: '/logoLight.svg',
  logoIcon: '/icon.svg',
  palette: {
    mode: 'light' as const,
    primary: {
      main: primary,
      light: new TinyColor(primary).lighten(lightenRate).toHexString(),
      dark: new TinyColor(primary).darken(darkenRate).toHexString(),
      contrastText: textPrimaryContrast,
    },
    secondary: {
      main: secondary,
      light: new TinyColor(secondary).lighten(lightenRate).toHexString(),
      dark: new TinyColor(secondary).darken(darkenRate).toHexString(),
      contrastText: textPrimaryContrast,
    },
    tertiary: {
      main: tertiary,
      light: new TinyColor(tertiary).lighten(lightenRate).toHexString(),
      dark: new TinyColor(tertiary).darken(darkenRate).toHexString(),
      contrastText: textSecondaryContrast,
    },
    warning: {
      main: warning,
      light: new TinyColor(warning).lighten(lightenRate).toHexString(),
      dark: new TinyColor(warning).darken(darkenRate).toHexString(),
      contrastText: textPrimaryContrast,
    },
    error: {
      main: error,
      light: new TinyColor(error).lighten(lightenRate).toHexString(),
      dark: new TinyColor(error).darken(darkenRate).toHexString(),
      contrastText: textPrimaryContrast,
    },
    success: {
      main: success,
      light: new TinyColor(success).lighten(lightenRate).toHexString(),
      dark: new TinyColor(success).darken(darkenRate).toHexString(),
      contrastText: textPrimaryContrast,
    },
    info: {
      main: info,
      light: new TinyColor(info).lighten(lightenRate).toHexString(),
      dark: new TinyColor(info).darken(darkenRate).toHexString(),
      contrastText: textPrimaryContrast,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondaryContrast,
      tertiary: textSecondaryContrast,
      hint: '#B9B9B9',
      disabled: 'hsl(0, 0%, 40%)',
    } as Partial<TypeText>,
    background: {
      default: '#fafafa',
      light: '#f3f3f3',
      paper: '#fff',
    } as Partial<TypeBackground>,
  } as PaletteOptions,
  environmentHeader: {
    production: '#f5f5f5',
    staging: atrigamColors.green['1c'],
    testing: atrigamColors.green['3d'],
  },
  chat: {
    bubble: {
      default: {
        background: tertiary,
        text: textPrimary,
      },
      primary: {
        background: new TinyColor(primary).lighten(lightenRate).toHexString(),
        text: textPrimary,
      },
    },
  },
  chart: {
    ...chartStyles,
    colors: {
      1: {
        primary: colorChartBlue,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightBlue,
        secondaryContrast: textSecondaryContrast,
      },
      2: {
        primary: colorChartOrange,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightOrange,
        secondaryContrast: textSecondaryContrast,
      },
      3: {
        primary: colorChartGreen,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightGreen,
        secondaryContrast: textSecondaryContrast,
      },
      4: {
        primary: colorChartLime,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightLime,
        secondaryContrast: textSecondaryContrast,
      },
      5: {
        primary: colorChartRed,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightRed,
        secondaryContrast: textSecondaryContrast,
      },
      6: {
        primary: colorChartViolet,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightViolet,
        secondaryContrast: textPrimaryContrast,
      },
      7: {
        primary: colorChartBrown,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightBrown,
        secondaryContrast: textPrimaryContrast,
      },
      8: {
        primary: colorChartPink,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightPink,
        secondaryContrast: textSecondaryContrast,
      },
      9: {
        primary: colorChartGray,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightGray,
        secondaryContrast: textSecondaryContrast,
      },
      10: {
        primary: colorChartTurquoise,
        primaryContrast: textPrimaryContrast,
        secondary: colorChartLightTurquoise,
        secondaryContrast: textSecondaryContrast,
      },
    },
  },
  customShadows: {
    widget: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide: '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#F3F5FF !important',
            '&:focus': {
              backgroundColor: '#F3F5FF',
            },
          },
        },
        // button: {
        //   '&:hover, &:focus': {
        //     backgroundColor: '#F3F5FF',
        //   },
        // },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#F3F5FF !important',
            '&:focus': {
              backgroundColor: '#F3F5FF',
            },
          },
        },
        button: {
          '&:hover, &:focus': {
            backgroundColor: '#F3F5FF',
          },
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        child: {
          backgroundColor: 'white',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        toolbarContainer: {
          '& button': {
            color: secondary,
          },
        },
        columnHeaderTitle: {
          fontWeight: 'bold',
        },
        cell: {
          '&.olderVersion': {
            color: new TinyColor(warning).darken(darkenRate).toHexString(),
          },
          '&.noModelerModel': {
            color: error,
          },
        },
        row: {
          '&.even': {
            backgroundColor: '#f3f3f3',
          },
          '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(primary, ODD_OPACITY),
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
  },
  typography: {
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.64rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.285rem',
    },
    h6: {
      fontSize: '1.142rem',
    },
  },
});
