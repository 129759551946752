import {
  AtrigamFirestorePlatformStatusDocument,
  AtrigamFirestorePlatformStatusPath,
} from '@atrigam/atrigam-types';
import { APP_NAME_EU, mapUndefinedToNullValue } from '@atrigam/server-functions-eu-client';
import { doc, getFirestore, updateDoc } from '@firebase/firestore';
import { getApp } from 'firebase/app';

export const updatePlatformStatusMutation = async (
  status: AtrigamFirestorePlatformStatusDocument,
) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path: AtrigamFirestorePlatformStatusPath = '/platform/status';
  const reference = doc(database, path);

  await updateDoc(reference, mapUndefinedToNullValue({ ...status }));
};
