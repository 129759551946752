import { action, makeObservable, observable } from 'mobx';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

interface Notification {
  key: SnackbarKey;
  message: SnackbarMessage;
  options?: OptionsObject;
}

export class SnackbarService {
  @observable
  notifications: Notification[] = [];

  @observable
  removableNotifications: SnackbarKey[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  addNotification = (notification: { message: SnackbarMessage; options?: OptionsObject }) => {
    this.notifications.push({
      ...notification,
      key: notification.options?.key ?? Date.now() + Math.random(),
    });
  };

  @action
  notificationHasBeenSeen = (key: SnackbarKey) => {
    this.notifications = this.notifications.filter((notification) => notification.key !== key);
  };

  @action
  stopNotification = (key: SnackbarKey) => {
    this.removableNotifications.push(key);
  };

  @action
  notificationHasBeenRemoved = (key: SnackbarKey) => {
    this.removableNotifications = this.removableNotifications.filter((notify) => notify !== key);
  };
}
