import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '../../../themes/Themes.types';

export const Content = styled.div<ThemedStyledComponent>(
  ({ theme }: ThemedStyledComponent) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-width: 0;

    background: ${theme.palette.background.default};
  `,
);
