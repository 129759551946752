import {
  AtrigamClient,
  AtrigamSalutation,
  AtrigamUniverseName,
  AtrigamUser,
  getISO,
  type AtrigamPhoneNumber,
  type AtrigamWorkItemId,
  type Email,
  type ISODateTime,
  type UID,
} from '@atrigam/atrigam-types';
import { action, computed, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { createAcronymForUser } from '../../../helpers/createAcronymForUser';
import { getName } from '../../../helpers/getName';
import { getSmallestFromImage } from '../../../helpers/getSmallestFromImage';

import { UserAvatarModel } from './UserAvatar.model';

interface Options {
  user: AtrigamUser;
}

export class UserModel {
  // auth fields
  @persist
  @observable
  isEmailVerified = false;

  // platform fields

  @persist
  @observable
  uid?: UID;

  @persist('list')
  @observable
  clientUniverses: AtrigamUniverseName[] = [];

  @persist
  @observable
  createdAt?: ISODateTime;

  @persist
  @observable
  email?: Email;

  // isPowerUser

  @persist
  @observable
  isTestUser = false;

  @persist
  @observable
  lastSeenOnline?: ISODateTime;

  @persist
  @observable
  lastSignedIn?: ISODateTime;

  @persist
  @observable
  lastUsedClient?: AtrigamClient;

  @persist
  @observable
  phoneNumber?: AtrigamPhoneNumber;

  // @persist
  // @observable
  // pushToken?: AtrigamPushToken;

  @persist
  @observable
  profileNode?: AtrigamWorkItemId;

  // TODO FCM TOKENS!
  // fcmTokens: AtrigamPushToken[] = [];

  @persist
  @observable
  updatedAt?: ISODateTime;

  @persist
  @observable
  verificationLinkResent?: boolean;

  @persist
  @observable
  verificationLinkResentAt?: ISODateTime;

  // verificationToken?: string

  // profile fields

  @persist
  @observable
  company?: string;

  @persist
  @observable
  firstname?: string;

  @persist
  @observable
  lastname?: string;

  @persist
  @observable
  jobtitle?: string;

  @persist('list')
  @observable
  modelerUniverses: AtrigamUniverseName[] = [];

  // profileFromInvite?: boolean;

  @persist('object', UserAvatarModel)
  @observable
  profilePicture?: UserAvatarModel;

  @persist
  @observable
  salutation?: AtrigamSalutation;

  @persist
  @observable
  termsAndConditionsAccepted = false;

  @persist
  @observable
  tutorialDone = false;

  @persist
  @observable
  verifyEmailLater?: ISODateTime;

  @persist
  @observable
  profileUpdatedAt?: ISODateTime;

  constructor(data?: Options) {
    makeObservable(this);

    if (data?.user) {
      this.uid = data.user.uid;
      this.email = data.user.email;
      this.isEmailVerified = data.user.isEmailVerified;
      this.phoneNumber = data.user.phoneNumber ?? undefined;
      this.isTestUser = data.user.isTestUser ?? false;

      this.updateUser(data.user);
    }
  }

  @computed
  get avatar() {
    if (!this.profilePicture) {
      return;
    }

    return getSmallestFromImage(this.profilePicture);
  }

  @computed
  get initials() {
    return createAcronymForUser(this);
  }

  @computed
  get name() {
    return getName({
      company: this.company,
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      phoneNumber: this.phoneNumber,
    });
  }

  @action
  updateUser = (user: AtrigamUser) => {
    this.isEmailVerified = user.isEmailVerified;
    this.clientUniverses = user.clientUniverses ?? [];
    this.createdAt = getISO(user.createdAt);
    this.email = user.email;
    this.lastSeenOnline = user.lastSeenOnline ? getISO(user.lastSeenOnline) : undefined;
    this.lastSignedIn = user.lastSignedIn ? getISO(user.lastSignedIn) : undefined;
    this.lastUsedClient = user.lastUsedClient;
    this.phoneNumber = user.phoneNumber ?? undefined;
    // this.pushToken = user.pushToken;
    this.profileNode = user.profileNode;
    this.updatedAt = getISO(user.updatedAt);
    this.verificationLinkResent = user.verificationLinkResent ?? undefined;
    this.verificationLinkResentAt = user.verificationLinkResentAt
      ? getISO(user.verificationLinkResentAt)
      : undefined;

    // this.fcmTokens = [];
    // if (user.fcmTokens) {
    //   this.fcmTokens = Object.keys(user.fcmTokens) as AtrigamPushToken[];
    // }

    this.company = user.company;
    this.firstname = user.firstname;
    this.lastname = user.lastname;
    this.jobtitle = user.jobtitle;
    this.modelerUniverses = user.modelerUniverses ?? [];
    this.profilePicture = user.profilePicture
      ? new UserAvatarModel({ picture: user.profilePicture })
      : undefined;
    this.salutation = user.salutation;
    this.termsAndConditionsAccepted = user.termsAndConditionsAccepted ?? false;
    this.tutorialDone = user.tutorialDone ?? false;
    this.verifyEmailLater = user.verifyEmailLater ? getISO(user.verifyEmailLater) : undefined;
    this.profileUpdatedAt = user.profileUpdatedAt ? getISO(user.profileUpdatedAt) : undefined;
  };
}
