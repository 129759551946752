import { AtrigamAdmin, UID } from '@atrigam/atrigam-types';
import { getApp } from 'firebase/app';
import { DocumentData, DocumentReference, doc, getFirestore } from 'firebase/firestore';

import { APP_NAME_EU } from '../../clientSDK.constants';
import { createAtrigamAdminPath } from '../paths/createAtrigamAdminPath';

interface Options {
  uid: UID;
}

export const getPlatformAdminQueryReference = ({ uid }: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path = createAtrigamAdminPath({ uid });
  return doc(database, path) as DocumentReference<AtrigamAdmin, DocumentData>;
};
