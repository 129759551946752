import { RouteParameters, RouteQuery } from '../../services/Router/Router.types';
import { createRoutePath } from '../../services/Router/helpers/createRoutePath';

export const pattern = '/kpis';

export interface Query extends RouteQuery {
  weeks?: string;
  compare?: string;
}

export const KPIsPath = createRoutePath<RouteParameters, Query>({ pattern });
