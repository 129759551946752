import { AtrigamPhoneNumber, Email, UID } from '@atrigam/atrigam-types';

interface Options {
  uid?: UID;
  email?: Email;
  phoneNumber?: AtrigamPhoneNumber;
  username?: string;
  firstname?: string;
  lastname?: string;
}

export const getReducedUserDataSet = (options?: Options) =>
  options
    ? {
        uid: options.uid ?? null,
        email: options.email ?? null,
        phoneNumber: options.phoneNumber ?? null,
        username: options.username ?? null,
        firstname: options.firstname ?? null,
        lastname: options.lastname ?? null,
      }
    : {};
