import {
  AtrigamDatabaseUniverseEnvironment,
  AtrigamEnvironment,
  AtrigamUniverseId,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
} from '../universe.types';

import { createAtrigamDatabaseUniverse } from './createAtrigamDatabaseUniverse';

interface Options {
  environment: AtrigamEnvironment;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName | AtrigamUniverseId;
}

export const createAtrigamDatabaseUniverseEnvironment = ({ environment, universe }: Options) => {
  return [createAtrigamDatabaseUniverse({ universe }), environment].join(
    '-',
  ) as AtrigamDatabaseUniverseEnvironment;
};
