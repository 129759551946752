import { UID } from '@atrigam/atrigam-types';
import { getDoc } from 'firebase/firestore';

import { getPlatformAdminQueryReference } from '../queryReferences/getPlatformAdmin.query.reference';

interface Options {
  uid: UID;
}

export const isPlatformAdminQuery = async ({ uid }: Options) => {
  const reference = getPlatformAdminQueryReference({ uid });

  try {
    const document = await getDoc(reference).catch(() => {
      // catch any error
    });

    if (!document) {
      return false;
    }

    const admin = document.data();

    return admin?.active === true;
  } catch {
    // catch any error
  }
  return false;
};
