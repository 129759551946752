import {
  APP_NAME_ADMIN as ADMIN_APP_NAME,
  watcherServiceAdmin,
} from '@atrigam/server-functions-admin-client';
import {
  APP_NAME_EU,
  FirebaseWatcherServiceOptions,
  watcherServiceEu,
} from '@atrigam/server-functions-eu-client';
import { initializeApp } from '@firebase/app';
import {
  enablePersistentCacheIndexAutoCreation,
  getPersistentCacheIndexManager,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore';

import { firestoreConfig } from '../../configuration/firestore.config';
import { BACKUP_APP_NAME } from '../../firestore/atrigam-backup/atrigam-backup.types';
import { IS_DEV } from '../../helpers/mode';
import { sentry } from '../Sentry/helpers/initializeSentry';

// paths that are allowed to have duplicate subscription paths
const ALLOWED_DUPLICATE_PATHS = ['/universes/atrigam-atrigam-production/objects/user/data/'];

export const initializeFirebase = () => {
  const euApp = initializeApp(firestoreConfig.firestoreEUConfig, APP_NAME_EU);
  const euFirestore = initializeFirestore(euApp, {
    localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
  });

  // enable offline indices for performance
  const indexManager = getPersistentCacheIndexManager(euFirestore);
  if (indexManager) {
    enablePersistentCacheIndexAutoCreation(indexManager);
  }

  watcherServiceEu.initialize({
    app: euApp,
    allowedDuplicatePaths: ALLOWED_DUPLICATE_PATHS,
    isDev: IS_DEV,
    logError,
  });

  // admin instance
  const adminApp = initializeApp(firestoreConfig.firestoreAdminConfig, ADMIN_APP_NAME);
  watcherServiceAdmin.initialize({
    app: adminApp,
    allowedDuplicatePaths: ALLOWED_DUPLICATE_PATHS,
    isDev: IS_DEV,
    logError,
  });

  // backup instance
  const backupApp = initializeApp(firestoreConfig.firestoreBackupConfig, BACKUP_APP_NAME);
  initializeFirestore(backupApp, {});
};

const logError: FirebaseWatcherServiceOptions['logError'] = ({ error, context }) => {
  sentry.log({ error, context });
};
