import { Backdrop, Typography } from '@mui/material';

import { reactive } from '../../../../helpers/reactive';
import { Registry } from '../../../../services/Registry/Registry';

const RouterTransitionComponent: React.FC = () => {
  const router = Registry.get('router');
  if (!router.isReady) {
    return null;
  }

  const message = router.transitionMessage;
  const isOpen = router.inTransition && message !== undefined;

  return (
    <Backdrop open={isOpen}>
      <Typography variant="h1" color="white">
        {message}
      </Typography>
    </Backdrop>
  );
};

export const RouterTransition = reactive(RouterTransitionComponent);
