import {
  AtrigamEnvironment,
  AtrigamModelsModel,
  AtrigamUniverseRegistration,
  AtrigamUser,
  UID,
  throwIfNullable,
} from '@atrigam/atrigam-types';
import {
  getUniverseModelQuery,
  getUniverseRegistrationQuery,
  getUserQuery,
} from '@atrigam/server-functions-eu-client';

import { AtrigamUniverseBackupConfiguration } from '../../firestore/atrigam-backup/backup.types';
import { getBackupConfigurationQuery } from '../../firestore/atrigam-backup/queries/getBackupConfiguration.query';
import { getUserCacheQuery } from '../../firestore/atrigam-eu/cachedQuery/getUser.cache.query';
import { getUniverseRegistrationClientRolesQuery } from '../../firestore/atrigam-eu/queries/getUniverseRegistrationClientUsersList.query';
import { getUniverseRegistrationModelerUsersQuery } from '../../firestore/atrigam-eu/queries/getUniverseRegistrationModelerUsersList.query';
import { UniverseRegistrationPageAsync } from '../../pages/UniverseRegistration/UniverseRegistration.page.async';
import { UniverseRegistrationPageStore } from '../../pages/UniverseRegistration/stores/UniverseRegistration.page.store';
import { RouteQuery, RouteScope, RouteStore } from '../../services/Router/Router.types';
import { createRedirect } from '../../services/Router/helpers/createRedirect';
import { createRoute } from '../../services/Router/helpers/createRoute';
import { UniverseRegistrationListPath } from '../universeRegistrationList/universeRegistrationList.path';

import { Parameters, pattern } from './universeRegistration.path';

let universeRegistration: AtrigamUniverseRegistration | undefined;

interface Store extends RouteStore {
  pageStore: UniverseRegistrationPageStore;
}

let store: Store | undefined;

const userMap = new Map<UID, AtrigamUser>();

export const UniverseRegistrationRoute = createRoute<Parameters, RouteQuery, Store>({
  title: () => 'Universe Registration Administration',
  getBreadcrumbs: ({ parameters, query }) => {
    const { universe } = parameters as unknown as Parameters;

    return [
      {
        label: 'Universe Registrations',
        link: UniverseRegistrationListPath.getLink({ params: {}, query }),
      },
      {
        label: universe,
      },
    ];
  },
  components: {
    main: UniverseRegistrationPageAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,
  canEnter: async ({ parameters: { universe } }) => {
    universeRegistration = await getUniverseRegistrationQuery({ universe });

    if (!universeRegistration) {
      return createRedirect({ name: 'UniverseRegistrationListRoute', params: {} });
    }

    return true;
  },
  onBeforeEnter: async () => {
    throwIfNullable('universeRegistration cannot be undefined', universeRegistration);

    // get models
    const universeEnvironmentMap = new Map<AtrigamEnvironment, AtrigamModelsModel | undefined>();
    const backupConfigurationMap = new Map<
      AtrigamEnvironment,
      AtrigamUniverseBackupConfiguration
    >();
    await Promise.all(
      [
        AtrigamEnvironment.Modeler,
        AtrigamEnvironment.Testing,
        AtrigamEnvironment.Staging,
        AtrigamEnvironment.Production,
      ].map(async (environment) => {
        const [universeModel, backupConfiguration] = await Promise.all([
          getUniverseModelQuery({
            environment,
            universe: universeRegistration!.universeName,
          }),
          getBackupConfigurationQuery({
            environment,
            universe: universeRegistration!.universeName,
          }),
        ]);

        universeEnvironmentMap.set(environment, universeModel);
        if (backupConfiguration) {
          backupConfigurationMap.set(environment, backupConfiguration);
        }
      }),
    );

    // prepare list of users to fetch
    const uidList = new Set<UID>();
    if (universeRegistration.createdBy) {
      uidList.add(universeRegistration.createdBy);
    }
    if (universeRegistration.updatedBy) {
      uidList.add(universeRegistration.updatedBy);
    }

    // modeler users
    const modelerUsers = await getUniverseRegistrationModelerUsersQuery({
      universe: universeRegistration.universeNodeName,
    });
    modelerUsers.forEach(({ uid }) => {
      uidList.add(uid);
    });

    // client roles
    const clientRoles = await getUniverseRegistrationClientRolesQuery({
      universe: universeRegistration.universeNodeName,
    });

    const clientRolesUidSet = new Set<UID>(clientRoles.map(({ uid }) => uid));

    let loadedClientUsers = false;
    if (clientRolesUidSet.size < 25) {
      [...clientRolesUidSet].forEach((uid) => {
        uidList.add(uid);
      });
      loadedClientUsers = true;
    }

    await Promise.all(
      [...uidList].map(async (uid) => {
        let user = await getUserCacheQuery({ uid });
        if (!user) {
          user = await getUserQuery({ uid });
        }
        if (user) {
          userMap.set(uid, user);
        }
      }),
    );

    const pageStore = new UniverseRegistrationPageStore({
      universeRegistration,
      userMap,
      modelerUsers,
      clientRoles,
      universeEnvironmentMap,
      loadedClientUsers,
      clientRolesUserCount: clientRolesUidSet.size,
      backupConfigurationMap,
    });
    store = { pageStore } as Store;
  },
  onAfterLeave: () => {
    store = undefined;
  },
  getStore: () => store!,
});
