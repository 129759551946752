/* eslint-disable @typescript-eslint/no-explicit-any */
import { throwIfNullable } from '@atrigam/atrigam-types';
import { produce } from 'immer';
import { action, computed, makeObservable, observable } from 'mobx';
import nprogress from 'nprogress';

import { IS_DEBUG, IS_DEV, IS_TEST } from '../../helpers/mode';
import { Registry } from '../Registry/Registry';

import {
  AnyRoutePath,
  RedirectRoute,
  Route,
  RouteParameters,
  RouteQuery,
  RouteStore,
  Router,
  RouterServiceOptions,
  RouterSubscribeState,
  TryNavigationWhenPausedCallback,
  type CurrentRoute,
} from './Router.types';
import { areStatesEqual } from './helpers/areStatesEqual';
import { createRouter } from './helpers/createRouter';
import { extractParametersAndQuery } from './helpers/extractParametersAndQuery';
import { isRedirectRoute } from './helpers/isRedirectRoute';
import { parseURL } from './helpers/parseURL';
import { stringifySearchQuery } from './helpers/stringifySearchQuery';

interface GoToOptions {
  url: string;
  replaceCurrentURL?: boolean;
  transition?: boolean;
  transitionMessage?: string;
}

interface GoToExternalOptions {
  url: string;
  openInNewTab?: boolean;
}

// Since route navigation can now effectively be paused (prevented)
// or awaitable (meaning we can preload some data) we use nprogress
// to render a tiny progressbar at the top of the page
// but only for routing navigation that takes longer than a certain threshold.
//
// This means:
// Every usual route navigation still is instant and will not show the progressbar at all.
// Not even a blink.
// But should the navigation take longer than the here defined threshold in ms,
// we will show progressbar and complete it once the navigation has been completed.
//
// This should give our users an indicator that something is happening,
// it it takes a bit of time, but will not make the UI flicker if something is instant.
const ONLY_SHOW_PROGRESS_BAR_AFTER_DELAY = 150;
nprogress.configure({
  minimum: 0.1,
  showSpinner: false,
});

type Listener = (state: RouterSubscribeState) => void;
type Unsubscribe = () => void;
type Subscribe = (listener: Listener) => Unsubscribe;

export type AnyRouterService = RouterService<any, any, any, any, any>;

export class RouterService<
  Routes extends Record<string, Route<Parameter, Query, Store> | RedirectRoute<Parameter, Query>>,
  RouteName extends Extract<keyof Routes, string>,
  Parameter extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
  Store extends RouteStore | undefined,
> {
  @observable
  inTransition = false;

  @observable
  transitionMessage?: string;

  // if `_currentRoute` is undefined it means the router has not been initialized yet
  // and `isReady` will be false
  @observable
  private _currentRoute?: CurrentRoute<Routes, RouteName, Parameter, Query, Store>;

  @observable
  private hasStarted = false;

  private router: Router;
  private routes: Routes;
  private fallbackRoute: RouteName;
  private tryNavigationWhenPausedCallback?: TryNavigationWhenPausedCallback;

  constructor(options: RouterServiceOptions<Routes, RouteName>) {
    makeObservable(this);

    this.router = createRouter<Routes, RouteName>({
      routes: options.routes,
      fallbackRoute: options.fallbackRoute,
      getTryNavigationWhenPausedCallback: this.getTryNavigationWhenPausedCallback,
    });
    this.router.subscribe(this.syncCurrentRoute);

    this.routes = options.routes;
    this.fallbackRoute = options.fallbackRoute;

    if (IS_DEBUG) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (window as any).router = this;
    }
  }

  @computed
  get title() {
    if (!this._currentRoute) {
      return '';
    }

    return this._currentRoute.title;
  }

  @computed
  get currentRoute() {
    throwIfNullable('RouterService.currentRoute', this._currentRoute);

    // this is a simple wrapper around _currentRoute which can be nullable
    // if the router is unitialized. Since the application cannot be entered anyways
    // before the router is ready. This is a safe helper to get rid of the `undefined` type.
    return this._currentRoute;
  }

  @computed
  get currentRouteComponents() {
    const name = this.currentRoute.name;

    const route = this.getRouteByName(name);

    if (isRedirectRoute(route)) {
      throw new Error(`Route ${name} is a RedirectRoute and has not components!`);
    }

    // this is a workaround for _currentRoute.components, since they are mobx
    // observables and you cannot render them directly. You would need to use
    // toJS, which will create a new object every time and therefore start a
    // remount/rerender which lead to a performance problem.
    // using the route directly we can just return the components from there
    // instead.
    return route.components;
  }

  @computed
  get isReady() {
    return this._currentRoute !== undefined && this.hasStarted;
  }

  @computed
  get currentURL() {
    if (!this.isReady) {
      return '';
    }

    return `${this.currentRoute.pathname}${this.currentRoute.search}${this.currentRoute.hash}`;
  }

  @action
  setTransition = (transition: boolean) => {
    this.inTransition = transition;
  };

  @action
  setTransitionMessage = (message?: string) => {
    this.transitionMessage = message;
  };

  // This method is necessary so we can sync data from our stores back to the URL
  // without triggering (!) a route change, because it would otherwise cause an unnecessary loop.
  // So basically this a counter part of syncDataFromURL <> syncDataToURL.
  //
  // It's only possible to update the query of a current URL without navigation on purpose.
  // Changing a route parameter will always trigger a new navigation.
  @action
  updateQueryWithoutNavigation = (query: Record<string, unknown>) => {
    const { url: parsedURL, query: parsedQuery } = parseURL(window.location.href);

    // replace current origin from parsed url
    const pathname = parsedURL.replace(window.location.origin, '');

    // merge & stringify existing search query & given query
    const search = stringifySearchQuery({
      ...parsedQuery,
      ...query,
    });

    const url = `${pathname}${search}`;
    const nextState = produce(this.router.getState(), (draft) => {
      draft.path = url;
      draft.params = {
        ...draft.params,
        ...query,
      };
    });

    window.history.pushState(nextState, '', url);

    // update current route state
    if (this._currentRoute) {
      this._currentRoute.query = { ...this._currentRoute.query, ...query };
      this._currentRoute.search = window.location.search;
      this._currentRoute.hash = window.location.hash;
    }
  };

  // This is the function passed to the router.subscribe() method.
  // It's effectively our internal listener that gets called every time a successful route change
  // appeared so we can sync it with augmented data to our router service.
  @action
  private syncCurrentRoute = () => {
    const { name } = this.router.getState();
    const { parameters, query } = extractParametersAndQuery({
      routeName: name,
      router5: this.router,
      state: this.router.getState(),
    });

    const route = this.getRouteByName(name);
    if (isRedirectRoute(route)) {
      return;
    }

    const {
      type,
      title,
      path,
      meta,
      components,
      preload,
      hasScopeData,
      scope,
      layout,
      getBreadcrumbs,
      getStore,
    } = route;

    // create breadcrumbs
    const breadcrumbs = getBreadcrumbs ? getBreadcrumbs({ parameters, query }) : undefined;

    // update current route
    this._currentRoute = {
      type,
      name: name as unknown as RouteName,
      get title() {
        return title();
      },
      pathname: window.location.pathname,
      search: window.location.search,
      hash: window.location.hash,

      pattern: path,
      scope,
      layout,
      hasScopeData,
      parameters,
      meta,
      query,
      components,
      preload,
      breadcrumbs,
      getStore,
    };
  };

  /**
   * Start the actual routing. Needs to be called after router has been setup
   * in the Registry. Otherwise the routing would start while Registry setup
   * was not yet completed, which mean the router middleware will start and
   * try to route to the currentRoute, activate all lifecycle functions on
   * them.
   *
   * In there, a call `Registry.get('router') might happen. Now since the
   * Registry setup for router is not yet completed (it is starting router5)
   * Registry will not have yet a router and will create one.
   *
   * While creating a router it will start `router5` which will start the
   * middleware, .. and on and on and on. (you see what i mean)
   *
   * The only working solution is to separate creation of the service and
   * starting it.
   */
  start = () => {
    if (this.router.isStarted()) {
      return;
    }

    // show progress bar at first start
    // but also only after delay
    let isProgressBarVisible = false;
    window.setTimeout(() => {
      if (!this.hasStarted) {
        isProgressBarVisible = true;
        nprogress.start();
      }
    }, ONLY_SHOW_PROGRESS_BAR_AFTER_DELAY);

    this.router.start(
      action(() => {
        this.hasStarted = true;

        // hide bar if visible
        if (isProgressBarVisible) {
          nprogress.done();
        }
      }),
    );
  };

  getRouteByURL = (url: string) => {
    const state = this.router.matchUrl(url);
    throwIfNullable(`RouterService.getRouteByURL("${url}")`, state);

    return this.getRouteByName(state.name);
  };

  isRouteURL = (url: string): boolean => {
    const state = this.router.matchUrl(url);
    if (state) {
      return true;
    }

    return false;
  };

  isActiveRouteURL = (url: string): boolean => {
    // create state from url
    const state = this.router.matchUrl(url);
    if (!state) {
      return false;
    }

    // use active state
    const activeState = this.router.matchUrl(document.location.href);
    if (!activeState) {
      return false;
    }

    return areStatesEqual({
      router5: this.router,
      state1: state,
      state2: activeState,
      ignoreQueryParameters: false,
    });
  };

  isActiveRoutePath = (route: AnyRoutePath): boolean => {
    const state = this.router.matchPath(route.pattern);
    const { pattern, name } = this.currentRoute;

    if (state) {
      // just check if the name is the same and the pattern as well
      return state.name === name && state.path === pattern;
      // return this.router.isActive(state.name, state.params); // not working
      // since param is :clientId and not the actual id
    }

    return false;
  };

  // This is used by elements/PreventNavigation
  // to prevent the user from leaving the current route when there are unsaved changes
  pauseNavigation = (callback: TryNavigationWhenPausedCallback) => {
    if (this.tryNavigationWhenPausedCallback) {
      throw new Error('Navigation is already paused!');
    }

    // this callback will be called once the user tries to navigate
    // despite the navigation being paused
    // @see createRouterMiddleware.ts
    this.tryNavigationWhenPausedCallback = callback;

    // unpause navigation
    return () => {
      this.tryNavigationWhenPausedCallback = undefined;
    };
  };

  subscribe: Subscribe = (function_) => this.router.subscribe(function_) as Unsubscribe;

  goToExternal = (options: GoToExternalOptions) => {
    const { url, openInNewTab } = options;

    if (openInNewTab) {
      const tab = window.open(url, '_blank');
      if (tab) {
        tab.focus();
      }
      return;
    }

    if (window.location) {
      (window.location as any) = url;
    }
  };

  goTo = async (options: GoToOptions): Promise<boolean> => {
    const { url, replaceCurrentURL, transition, transitionMessage } = options;

    // if new version is available and user clicks on any link => reload that page to make sure we have the latest version
    const { newVersionAvailable } = Registry.get('appStore');
    if (newVersionAvailable && !IS_DEV && !IS_TEST) {
      this.goToExternal({ url });
      return true;
    }

    if (this.currentURL === url) {
      this.setTransition(false);
      this.setTransitionMessage();
      return true;
    }

    if (transition === true) {
      this.setTransition(true);
    }

    if (transitionMessage) {
      this.setTransitionMessage(transitionMessage);
    }

    const { name: foundRouteName, params: foundRouteParameters } = this.router.matchUrl(url) ?? {};
    const targetRoute = foundRouteName ?? String(this.fallbackRoute);

    const targetParameters = foundRouteParameters ?? {};
    const config = {
      replace: foundRouteName ? replaceCurrentURL ?? false : false,
    };

    let isProgressBarVisible = false;
    let isNavigationResolved = false;
    const progressBarDelay = new Promise((resolve) => {
      window.setTimeout(resolve, ONLY_SHOW_PROGRESS_BAR_AFTER_DELAY);
    });

    const navigation = new Promise<boolean>((resolve) => {
      const done = (value: boolean) => {
        isNavigationResolved = true;
        this.setTransition(false);
        this.setTransitionMessage();
        resolve(value);
      };

      this.router.navigate(
        targetRoute,
        targetParameters,
        config,
        (error?: { code: string; reason?: string }) => {
          if (error) {
            // router5 will not perform the navigation if the same route state
            // is provided. Instead it will output a `SAME_STATES` error, which we
            // deliberately want to ignore although the navigation did not happen.
            if (error.code === 'SAME_STATES') {
              done(true);
              return;
            }

            // navigation was cancelled be the user
            if (error.code === 'TRANSITION_ERR' && error.reason === 'CANCELLED') {
              done(false);
              return;
            }

            // an actual error happened while transitioning
            done(false);
            return;
          }

          done(true);
        },
      );
    });

    // wait until either progress bar should be shown or navigation is done
    await Promise.race([progressBarDelay, navigation]);

    // if navigation is not resolved yet -> show progress bar
    if (!isNavigationResolved) {
      isProgressBarVisible = true;
      nprogress.start();
    }

    // wait until navigation is resolved
    const result = await navigation;

    // stop progress bar if it was started
    if (isProgressBarVisible) {
      nprogress.done();
    }

    return result;
  };

  private getTryNavigationWhenPausedCallback = () => {
    return this.tryNavigationWhenPausedCallback;
  };

  private getRouteByName = (name: string) => {
    const route = this.routes[name];
    throwIfNullable(`RouterService.getRouteByName("${name}")`, route);

    return route;
  };
}
