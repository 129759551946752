import { CircularProgress } from '@mui/material';

import { LoadingWrapper } from './styles/LoadingWrapper.style';

interface Properties {
  size?: number;
}

export const LazyPlaceholder: React.FC<Properties> = ({ size = 14 }) => (
  <LoadingWrapper>
    <CircularProgress size={size} />
  </LoadingWrapper>
);
