import { AtrigamUniverseNodeName } from '@atrigam/atrigam-types';

import { RouteParameters, RouteQuery } from '../../services/Router/Router.types';
import { createRoutePath } from '../../services/Router/helpers/createRoutePath';

export interface Parameters extends RouteParameters {
  universe: AtrigamUniverseNodeName;
}

export const pattern = '/universeRegistrations/:universe';

export const UniverseRegistrationPath = createRoutePath<Parameters, RouteQuery>({ pattern });
