import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';

import { reactive } from '../../helpers/reactive';
import { AtrigamTheme } from '../../typings/atrigamTheme';

const PREFIX = 'GenericButton';

const classes = {
  root: `${PREFIX}-root`,
};

export interface GenericButtonProperties extends Omit<ButtonProps, 'color'> {
  loading?: boolean;
  target?: string;
  color?: ButtonProps['color'] | 'tertiary';
}

const GenericButtonComponent: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  GenericButtonProperties
> = (
  { loading = false, startIcon, disabled, color, variant = 'contained', ...properties },
  reference,
) => {
  const theme = useTheme<AtrigamTheme>();

  if (color === 'tertiary') {
    const EnhancedButton = styled(Button)(() => {
      return {
        [`& .${classes.root}`]: {
          color: theme.palette.tertiary.contrastText,
          backgroundColor: variant === 'contained' ? theme.palette.tertiary.main : '',
          borderColor: variant === 'outlined' ? theme.palette.tertiary.dark : '',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:hover': {
            backgroundColor:
              variant === 'contained' ? theme.palette.tertiary.dark : theme.palette.tertiary.light,
            borderColor: variant === 'outlined' ? theme.palette.tertiary.main : '',
          },
        },
      };
    });

    return (
      <EnhancedButton
        {...properties}
        ref={reference}
        variant={variant}
        startIcon={loading ? <CircularProgress size={24} /> : startIcon}
        disabled={loading ? true : disabled}
        classes={{
          root: classes.root,
        }}
      />
    );
  }

  return (
    <Button
      {...properties}
      ref={reference}
      variant={variant}
      color={color}
      startIcon={loading ? <CircularProgress size={24} /> : startIcon}
      disabled={loading ? true : disabled}
    />
  );
};

export const GenericButton = reactive(React.forwardRef(GenericButtonComponent));
