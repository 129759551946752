import { CssBaseline } from '@mui/material';

import { reactive } from '../../helpers/reactive';

import { AnimationsStyles } from './styles/Animations.style';
import { GlobalStyles } from './styles/Global.style';

/*
  Multi-Mounts only might happen in development due to hot reloading
  and in production there are no warnings at all -> fine to leave it always suppressed
*/
const GlobalStyleCollectionComponent = () => {
  return (
    <>
      <CssBaseline />
      <GlobalStyles suppressMultiMountWarning />
      <AnimationsStyles suppressMultiMountWarning />
    </>
  );
};

export const GlobalStyleCollection = reactive(GlobalStyleCollectionComponent);
