import { Router as Router5, State } from 'router5';

import { UnknownObject } from '../../../typings/utils';

import { extractParameterKeysFromRouteNode } from './extractParameterKeysFromRouteNode';
import { extractParameterKeysFromRouteStateMeta } from './extractParameterKeysFromRouteStateMeta';
import { extractParameterKeysFromSearchQuery } from './extractParameterKeysFromSearchQuery';

interface Options {
  routeName: string;
  router5: Router5;
  state: State;
}

interface Result {
  parameters: UnknownObject;
  query: UnknownObject;
}

// router5 treats both route params and search query params
// as the same thing and returns them both together in `params`
// however we want to seperate route params and search query segments
// so we need to remove all search query keys from the mixed params
export const extractParametersAndQuery = (options: Options): Result => {
  const parameterKeys = extractParameterKeys(options);
  const { state } = options;

  const parameters = parameterKeys.reduce((collection, key) => {
    collection[key] = state.params[key];

    return collection;
  }, {} as UnknownObject);

  const query = Object.keys(state.params)
    .filter((key) => !parameterKeys.includes(key))
    .reduce((collection, key) => {
      collection[key] = state.params[key];

      return collection;
    }, {} as UnknownObject);

  return { parameters, query };
};

const extractParameterKeys = ({ state, router5, routeName }: Options) => {
  // try to get parameterKeys directly from router5 routeNode (best possibility)
  let parameterKeys = extractParameterKeysFromRouteNode({ router5, routeName });
  if (parameterKeys.length > 0) {
    return parameterKeys;
  }

  // no params, try to get them from router5 route state (second best possibility)
  parameterKeys = extractParameterKeysFromRouteStateMeta(state);
  if (parameterKeys.length > 0) {
    return parameterKeys;
  }

  // still no params, try to get them from the search query (worst possibility)
  return extractParameterKeysFromSearchQuery(state);
};
