import { TypographyWeight } from '../Typography.types';

interface Options {
  weight?: TypographyWeight;
}

export const getFontWeight = ({ weight }: Options) => {
  switch (weight) {
    case 'light': {
      return 300;
    }
    case 'medium': {
      return 500;
    }
    case 'bold': {
      return 600;
    }
    default: {
      return 400;
    }
  }
};
