import { AtrigamUser, UID } from '@atrigam/atrigam-types';

import { createAtrigamUserPath } from '../firestore/paths/createAtrigamUserPath';
import { WatcherFunction } from '../types/watchers.types';
import { watcherServiceEu } from '../watcherService';

interface Options {
  uid: UID;
}

export const watchUser: WatcherFunction<AtrigamUser, Options> = ({ uid, onUpdate, onError }) => {
  const firestoreService = watcherServiceEu.get();

  const path = createAtrigamUserPath({ uid });

  return firestoreService.subscribeToDocument<AtrigamUser>({
    path,
    onSnapshot: (snapshot, key) => {
      const user = snapshot.data();
      void onUpdate(user, key);
    },
    onError,
  });
};
