import { InvertColors as ThemeSwitcherIcon } from '@mui/icons-material';
import { IconButton, Theme, useMediaQuery } from '@mui/material';
import React from 'react';

import { ThemeContext } from '../../../../../../contexts/ThemeContext';
import { reactive } from '../../../../../../helpers/reactive';

const ThemeSwitcherComponent: React.FC = () => {
  const { theme: currentTheme, toggleTheme } = React.useContext(ThemeContext);

  const isSmaller = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  if (isSmaller) {
    return null;
  }

  return (
    <IconButton
      onClick={toggleTheme}
      aria-label={`Toggle Theme to ${currentTheme === 'light' ? 'Dark Mode' : 'Light Mode'}`}
      className="themeSwitcher"
      size="large"
    >
      <ThemeSwitcherIcon />
    </IconButton>
  );
};

export const ThemeSwitcher = reactive(ThemeSwitcherComponent);
