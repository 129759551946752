import { SnackbarProvider } from 'notistack';
import styled, { css } from 'styled-components';

import { reactive } from '../../../../helpers/reactive';
import { ThemedStyledComponent } from '../../../../themes/Themes.types';

const StyledSnackbarProvider = styled.div(
  ({ theme }: ThemedStyledComponent) => css`
    & .SnackbarItem-variantSuccess {
      background-color: ${theme.palette.success.main};
      color: ${theme.palette.success.contrastText};

      button {
        color: ${theme.palette.success.contrastText};
      }
    }

    & .SnackbarItem-variantError {
      background-color: ${theme.palette.error.main};
      color: ${theme.palette.error.contrastText};

      button {
        color: ${theme.palette.error.contrastText};
      }
    }

    & .SnackbarItem-variantWarning {
      background-color: ${theme.palette.warning.main};
      color: ${theme.palette.warning.contrastText};

      button {
        color: ${theme.palette.warning.contrastText};
      }
    }

    & .SnackbarItem-variantInfo {
      background-color: ${theme.palette.info.main};
      color: ${theme.palette.info.contrastText};

      button {
        color: ${theme.palette.info.contrastText};
      }
    }
  `,
);

const ThemedSnackbarProviderComponent: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <StyledSnackbarProvider id="fop">
      <SnackbarProvider maxSnack={4}>{children}</SnackbarProvider>
    </StyledSnackbarProvider>
  );
};

export const ThemedSnackbarProvider = reactive(ThemedSnackbarProviderComponent);
