import { throwIfNullable } from '@atrigam/atrigam-types';

import {
  Route,
  RouteOptions,
  RouteParameters,
  RouteQuery,
  RouteScopeData,
  RouteStore,
  RouteTypes,
} from '../Router.types';

import { getRouteComponents } from './getRouteComponents';
import { getRouteLayout } from './getRouteLayout';
import { getRouteScopeData } from './getRouteScopeData';
import { preloadComponentsForRoute } from './preloadComponentsForRoute';

interface CreateRoute {
  // no params, no query, no store
  (options: RouteOptions<undefined, undefined, undefined>): Route<undefined, undefined, undefined>;

  // only params
  <Parameters extends RouteParameters>(
    options: RouteOptions<Parameters, undefined, undefined>,
  ): Route<Parameters, undefined, undefined>;

  // only query
  <Query extends RouteQuery>(
    options: RouteOptions<undefined, Query, undefined>,
  ): Route<undefined, Query, undefined>;

  // params + query, no store
  <Parameters extends RouteParameters, Query extends RouteQuery>(
    options: RouteOptions<Parameters, Query, undefined>,
  ): Route<Parameters, Query, undefined>;

  // only store
  <Store extends RouteStore>(
    options: RouteOptions<undefined, undefined, Store>,
  ): Route<undefined, undefined, Store>;

  // params + store, no query
  <Parameters extends RouteParameters, Store extends RouteStore>(
    options: RouteOptions<Parameters, undefined, Store>,
  ): Route<Parameters, undefined, Store>;

  // query + store, no params
  <Query extends RouteQuery, Store extends RouteStore>(
    options: RouteOptions<undefined, Query, Store>,
  ): Route<undefined, Query, Store>;

  // params + query + store
  <Parameters extends RouteParameters, Query extends RouteQuery, Store extends RouteStore>(
    options: RouteOptions<Parameters, Query, Store>,
  ): Route<Parameters, Query, Store>;
}

export const createRoute: CreateRoute = <
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
  Store extends RouteStore | undefined,
>(
  options: RouteOptions<Parameters, Query, Store>,
): Route<Parameters, Query, Store> => {
  let routeName: string | undefined;

  const {
    pattern,
    scope,
    title,
    meta,
    canEnter,
    onBeforeEnter,
    onAfterLeave,
    syncDataFromURL,
    preloadableComponents,
    getBreadcrumbs,
    getStore,
  } = options;

  const layout = getRouteLayout({ scope });
  const availableScopeData = getRouteScopeData({ scope });
  const components = getRouteComponents({ layout, routeComponents: options.components });

  return {
    type: RouteTypes.Route,
    path: pattern,
    scope,
    title,
    meta,
    canEnter,
    onBeforeEnter,
    onAfterLeave,
    syncDataFromURL,
    getBreadcrumbs,
    getStore,
    layout,
    components,
    hasScopeData: (scopeData: RouteScopeData) => {
      return availableScopeData.includes(scopeData);
    },

    preload: () => {
      preloadComponentsForRoute({ components, preloadableComponents });
    },

    get name() {
      throwIfNullable('Router.createRoute::Route.name', routeName);
      return routeName;
    },

    // this will be called to set the route name
    // @see createRouter.ts
    setName: (name: string) => {
      routeName = name;
    },
  };
};
