import { logoutUser } from '../../api/logoutUser';
import { Registry } from '../../services/Registry/Registry';
import { RouteScope } from '../../services/Router/Router.types';
import { createRedirect } from '../../services/Router/helpers/createRedirect';
import { createRedirectRoute } from '../../services/Router/helpers/createRedirectRoute';

import { pattern } from './logout.path';

export const LogoutRoute = createRedirectRoute({
  pattern,
  scope: RouteScope.LoggedIn,
  onRedirect: async () => {
    const userStore = Registry.get('userStore');
    if (userStore.isAuthenticated) {
      await logoutUser({ redirect: false });
      Registry.get('router').setTransition(false);
    }

    return createRedirect<undefined, undefined>({
      name: 'LoginRoute',
      params: undefined,
    });
  },
});
