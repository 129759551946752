import { Timestamp } from '../utilities/date.types';

import { createAtrigamDatabaseUniverse } from './helpers/createAtrigamDatabaseUniverse';
import { createAtrigamDatabaseUniverseEnvironment } from './helpers/createAtrigamDatabaseUniverseEnvironment';
import { createAtrigamUniverseAreaTaskFlow } from './helpers/createAtrigamUniverseAreaTaskFlow';
import { AtrigamObjectName } from './objectModel.types';
import { AtrigamInteractionImage } from './taskFlow.interactions.types';
import { AtrigamTaskFlowName, AtrigamUniverseAreaTaskFlow } from './taskFlow.types';
import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamRole,
  AtrigamUniverseName,
} from './universe.types';
import { Email as AtrigamEmail, AtrigamPhoneNumber, UID } from './user.types';
import { AtrigamWorkItem, AtrigamWorkItemId } from './workItem.types';

// constants

export const ATRIGAM_PROFILE_UNIVERSE = 'Atrigam' as AtrigamUniverseName;
export const ATRIGAM_PROFILE_AREA = 'Platform' as AtrigamAreaName;
export const ATRIGAM_PROFILE_TASKFLOW = 'Profile' as AtrigamTaskFlowName;
/** @deprecated use ATRIGAM_PROFILE_TASKFLOW instead */
export const ATRIGAM_PROFILE_FLOW = ATRIGAM_PROFILE_TASKFLOW;
export const ATRIGAM_PROFILE_OBJECT_NAME = 'user' as AtrigamObjectName;
export const ATRIGAM_PROFILE_ENVIRONMENT = AtrigamEnvironment.Production;
export const ATRIGAM_PROFILE_ROLE = 'User' as AtrigamRole;

export const ATRIGAM_PROFILE_UNIVERSE_AREA_FLOW = createAtrigamUniverseAreaTaskFlow({
  universe: ATRIGAM_PROFILE_UNIVERSE,
  area: ATRIGAM_PROFILE_AREA,
  taskFlow: ATRIGAM_PROFILE_TASKFLOW,
});

export const ATRIGAM_PROFILE_DATABASE_UNIVERSE = createAtrigamDatabaseUniverse({
  universe: ATRIGAM_PROFILE_UNIVERSE,
});

export const ATRIGAM_PROFILE_DATABASE_UNIVERSE_ENVIRONMENT =
  createAtrigamDatabaseUniverseEnvironment({
    environment: ATRIGAM_PROFILE_ENVIRONMENT,
    universe: ATRIGAM_PROFILE_UNIVERSE,
  });

// types

export enum AtrigamSalutation {
  Mister = 'Mr.',
  Misses = 'Mrs.',
}

export type AtrigamUserProfile = AtrigamUserProfileEmail | AtrigamUserProfilePhoneNumber;

export interface AtrigamUserProfileEmail extends UserProfileBase {
  email: AtrigamEmail;
}

export interface AtrigamUserProfilePhoneNumber extends UserProfileBase {
  phoneNumber: AtrigamPhoneNumber;
}

type UserProfileBase = Partial<AtrigamWorkItem> &
  Partial<AtrigamUserProfileInvite> & {
    company?: string;
    demoRequestDone?: boolean;
    firstname?: string;
    lastname?: string;
    jobtitle?: string;
    ownUniverses?: AtrigamUserProfileOwnUniverses;
    profileFromInvite?: boolean; // indicate that this profile was created based on an invite
    profilePicture?: AtrigamUserProfileProfilePicture;
    salutation?: AtrigamSalutation;
    termsAndConditionsAccepted?: boolean;
    tutorialDone?: boolean;
    uid: UID;
    verifyEmailLater?: Timestamp;
  };

export interface AtrigamUserProfileInvite {
  inviteEnv: AtrigamEnvironment;
  inviteFlow: AtrigamUserProfileInviteFlow;
  inviteObject: AtrigamUserProfileInviteObject;
  createdThrough: 'invite';
  invitedBy: string;
}

export interface AtrigamUserProfileInviteFlow {
  universe: AtrigamUniverseName;
  area: AtrigamAreaName;
  flow: AtrigamTaskFlowName;
  flowName: AtrigamTaskFlowName; // ?
  version: number;
  mainObject: AtrigamObjectName;
  myRole: AtrigamRole;
  myRoles: Record<AtrigamRole, AtrigamRole>;
}

export type AtrigamUserProfileInviteObject = Record<
  AtrigamEnvironment,
  AtrigamUserProfileInviteObjectEnvironment
>;

export type AtrigamUserProfileInviteObjectEnvironment = Record<
  AtrigamUniverseAreaTaskFlow,
  AtrigamUserProfileInviteObjectEnvironmentFlow
>;

export interface AtrigamUserProfileInviteObjectEnvironmentFlow {
  uid: UID;
  nodeName: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
}

export type AtrigamUserProfileOwnUniverses = Record<AtrigamUniverseName, string>;

export interface AtrigamUserProfileProfilePicture {
  profilePicture: AtrigamInteractionImage;
}
