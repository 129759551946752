import React from 'react';

import { Registry } from '../../services/Registry/Registry';

export const useWindowTitle = () => {
  const { title } = Registry.get('router');

  React.useEffect(() => {
    // const newTitle = router.isReady ? router.currentRoute.title : '';
    const documentTitle = ['Atrigam', title].filter(Boolean).join(' · ');

    // set document title
    document.title = documentTitle;
  }, [title]);
};
