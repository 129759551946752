import { getApp } from 'firebase/app';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';

import { BACKUP_APP_NAME } from '../atrigam-backup.types';

export const getCurrentFirestoreBackupUserQuery = (): Promise<User | null> => {
  const auth = getAuth(getApp(BACKUP_APP_NAME));
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject,
    );
  });
};
