import { AnyDate } from '../date.types';
import { isDate } from '../guards/isDate';
import { isDay } from '../guards/isDay';
import { isFirestoreClientTimestampObject } from '../guards/isFirestoreClientTimestampObject';
import { isFirestoreServerTimestampObject } from '../guards/isFirestoreServerTimestampObject';
import { isFirestoreTimestamp } from '../guards/isFirestoreTimestamp';

import { getDateTimeFromDate } from './getDateTimeFromDate';
import { getDateTimeFromDay } from './getDateTimeFromDay';
import { getDateTimeFromFirestoreTimestampLikeObject } from './getDateTimeFromFirestoreTimestampLikeObject';
import { getDateTimeFromISO } from './getDateTimeFromISO';
import { isDateTime } from './isDateTime';

export const getDateTime = (input: AnyDate) => {
  if (isDate(input)) {
    return getDateTimeFromDate(input);
  }

  if (isDateTime(input)) {
    return input;
  }

  if (isFirestoreTimestamp(input)) {
    return getDateTimeFromDate(input.toDate());
  }

  if (isFirestoreClientTimestampObject(input)) {
    return getDateTimeFromFirestoreTimestampLikeObject(input);
  }

  if (isFirestoreServerTimestampObject(input)) {
    return getDateTimeFromFirestoreTimestampLikeObject({
      seconds: input._seconds,
      nanoseconds: input._nanoseconds,
    });
  }

  if (isDay(input)) {
    return getDateTimeFromDay(input);
  }

  return getDateTimeFromISO(input);
};
