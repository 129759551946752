import React from 'react';

import { reactive } from '../../helpers/reactive';

import { GenericButton, GenericButtonProperties } from './GenericButton';

const ErrorButtonComponent: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  GenericButtonProperties
> = ({ variant = 'contained', ...properties }, reference) => {
  return <GenericButton ref={reference} variant={variant} color={'error'} {...properties} />;
};

export const ErrorButton = reactive(React.forwardRef(ErrorButtonComponent));
