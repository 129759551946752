import {
  initializeRegistry,
  Instanceable,
  MockableRegistryType,
} from '@atrigam/atrigam-service-registry';

import { IS_DEBUG, IS_TEST } from '../../helpers/mode';
import { UserPageStore } from '../../pages/User/stores/User.page.store';
import { AppStore } from '../../stores/AppStore/App.store';
import { UserStore } from '../../stores/UserStore/User.store';
import { LocalStorage } from '../LocalStorage/LocalStorage';
import { AnyRouterService } from '../Router/Router.service';
import { SnackbarService } from '../Snackbar/Snackbar.service';
import { TrackingService } from '../Tracking/Tracking.types';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type ServicesAndStores = {
  // app services
  router: Instanceable<AnyRouterService>;
  snackbar: Instanceable<SnackbarService>;
  storage: Instanceable<LocalStorage>;
  // only for dev
  tracking: Instanceable<TrackingService>;

  // app stores
  appStore: Instanceable<AppStore>;
  userStore: Instanceable<UserStore>;

  // page stores
  userPageStore: Instanceable<UserPageStore>;
};

export const Registry = initializeRegistry<ServicesAndStores>(IS_TEST);

// `MockableRegistry` can only be used in testing.
// `initializeRegistry()` ensures the appropriate methods are available
// for testing and non-testing modes. However we need to force the types to be correct
export const MockableRegistry = Registry as MockableRegistryType<ServicesAndStores>;

// expose Registry to `window` when debugging
if (IS_DEBUG) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  (window as any).Registry = Registry;
}
