import React from 'react';

export interface PageLoadingContextOptions {
  loading: boolean;
  message?: string;
}

interface Context {
  isPageLoading: boolean;
  pageLoadingMessage?: string;
  setPageLoading: (options: PageLoadingContextOptions) => void;
}

export const PageLoadingContext = React.createContext<Context>({
  isPageLoading: false,
  pageLoadingMessage: undefined,
  setPageLoading: (_options: PageLoadingContextOptions) => {
    /* structure */
  },
});
