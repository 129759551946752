import { MAIN_ID } from '../../../../helpers/getMainElement';
import { reactive } from '../../../../helpers/reactive';
import { Registry } from '../../../../services/Registry/Registry';

import { MainWrapper } from './styles/MainWrapper.style';

const MainComponent: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const router = Registry.get('router');
  const meta = router.currentRoute.meta;
  const noPadding = meta?.noPadding ?? false;

  return (
    <MainWrapper id={MAIN_ID} $noPadding={noPadding}>
      {children}
    </MainWrapper>
  );
};

export const Main = reactive(MainComponent);
