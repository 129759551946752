type Disposer = () => void;

export class DestructibleService {
  disposers: Disposer[] = [];

  _destruct = () => {
    // call all disposers
    const disposers = [...this.disposers];
    disposers.forEach((dispose) => {
      dispose();
    });
    this.disposers = [];
  };
}
