import { UID } from '@atrigam/atrigam-types';
import { getDoc } from 'firebase/firestore';

import { getUserQueryReference } from '../queryReferences/getUser.query.reference';

interface Options {
  uid: UID;
}

export const getUserQuery = async ({ uid }: Options) => {
  const reference = getUserQueryReference({ uid });
  const document = await getDoc(reference);
  return document.data();
};
