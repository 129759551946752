import { FirestoreWatcherHandler } from '@atrigam/atrigam-service-firebase-watcher';
// TODO - don't re-export type definitions
export type { FirebaseWatcherServiceOptions } from '@atrigam/atrigam-service-firebase-watcher';

export * from './clientSDK.constants.js';
export * from './queries/index.js';
export * from './types/atrigam-admin.js';
export * from './watchers/index.js';

export const watcherServiceAdmin = FirestoreWatcherHandler();
