import { AtrigamUser, throwIfNullable } from '@atrigam/atrigam-types';
import { getUserProfileFlowModelQuery, getUserQuery } from '@atrigam/server-functions-eu-client';

import { UserPageAsync } from '../../pages/User/User.page.async';
import { userPageStoreFactory } from '../../pages/User/stores/User.page.store.factory';
import { Registry } from '../../services/Registry/Registry';
import { RouteScope } from '../../services/Router/Router.types';
import { createRedirect } from '../../services/Router/helpers/createRedirect';
import { createRoute } from '../../services/Router/helpers/createRoute';
import { UsersListPath } from '../usersList/usersList.path';

import { Parameters, pattern } from './user.path';

let user: AtrigamUser | undefined;

export const UserRoute = createRoute<Parameters>({
  title: () => 'User Administration',
  getBreadcrumbs: ({ parameters, query }) => {
    const { uid } = parameters as unknown as Parameters;

    return [
      {
        label: 'Users',
        link: UsersListPath.getLink({ params: {}, query }),
      },
      {
        label: uid,
      },
    ];
  },
  components: {
    main: UserPageAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,
  canEnter: async ({ parameters: { uid } }) => {
    user = await getUserQuery({ uid });

    if (!user) {
      return createRedirect({ name: 'UsersListRoute', params: {} });
    }

    return true;
  },
  onBeforeEnter: async () => {
    throwIfNullable('user cannot be undefined', user);

    const model = await getUserProfileFlowModelQuery();
    throwIfNullable('user profile model cannot be undefined', model);

    userPageStoreFactory.reset();
    userPageStoreFactory.set({ user, model });

    Registry.get('userPageStore');
  },
  onAfterLeave: () => {
    userPageStoreFactory.reset();
    Registry.clear('userPageStore');
  },
});
