import { UnhandledCaseError } from '@atrigam/atrigam-types';

import { reactive } from '../helpers/reactive';
import { Registry } from '../services/Registry/Registry';
import { RouteLayout } from '../services/Router/Router.types';

import { DefaultLayout } from './DefaultLayout/DefaultLayout';
import { UnauthorizedLayout } from './UnauthorizedLayout/UnauthorizedLayout';
import { useWindowTitle } from './hooks/useWindowTitle';

const LayoutsComponent: React.FC = () => {
  useWindowTitle();

  const router = Registry.get('router');

  if (!router.isReady || router.inTransition) {
    return null;
  }

  const layout = router.currentRoute.layout;

  switch (layout) {
    case RouteLayout.Unauthorized: {
      return <UnauthorizedLayout />;
    }

    case RouteLayout.Default: {
      return <DefaultLayout />;
    }

    default: {
      throw new UnhandledCaseError(layout);
    }
  }
};

export const Layouts = reactive(LayoutsComponent);
