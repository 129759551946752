import {
  RedirectRoute,
  Route,
  RouteParameters,
  RouteQuery,
  RouteStore,
  RouteTypes,
} from '../Router.types';

export const isRedirectRoute = <
  Parameters extends RouteParameters | undefined,
  Query extends RouteQuery | undefined,
  Store extends RouteStore | undefined,
>(
  route: Route<Parameters, Query, Store> | RedirectRoute<Parameters, Query>,
): route is RedirectRoute<Parameters, Query> => route.type === RouteTypes.RedirectRoute;
