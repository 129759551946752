import {
  AtrigamFirestorePlatformStatusVersions,
  AtrigamApplicationVersionPath,
} from '@atrigam/atrigam-types';

interface Options {
  app: keyof AtrigamFirestorePlatformStatusVersions;
}

export const createAtrigamApplicationVersionPath = ({
  app,
}: Options): AtrigamApplicationVersionPath => `/platform/status/versions/${app}`;
