import { IS_DEBUG } from '../../../helpers/mode';
import { SentryConfig, SentryService } from '../Sentry.service';

export let sentry = new SentryService({ config: false });

interface HasSentry {
  sentry: SentryService;
}

export const initializeSentry = (config: SentryConfig) => {
  sentry = new SentryService({ config });

  if (IS_DEBUG) {
    (window as unknown as HasSentry).sentry = sentry;
  }
};
