import React from 'react';

import { Registry } from '../../../services/Registry/Registry';
import { isRedirectRoute } from '../../../services/Router/helpers/isRedirectRoute';

interface Options {
  to: string;
}

const DELAY = 50;

// Each route exposes a .preload() method that preloads all async components specified for that route.
// This is a non-blocking action and will make navigation feel a lot faster.
//
// Async components are also being loaded once they were rendered (React.lazy).
// Additionally we pre-load those components when a user hovers for at some threshold ms over a route link.
//
// This leans on the same pattern that for example https://getquick.link/
// and https://instant.page/ etc. use to improve perceived performance of a page.
// It's usually some tens of ms we can be early here, but that's enough to preload those components
// and prevent us from showing a loading indicator in the first place.
//
// There is no need to flag when the preload function has already been called
// because calling it multiple times will not result in multiple network requests.
// Once a lazy import() has been executed, it will not be re-fetched.
// This is a default webpack/browser behavior.
export const usePreloading = (options: Options) => {
  const { to } = options;
  const router = Registry.get('router');
  const isRoute = React.useMemo(() => router.isRouteURL(to), [router, to]);
  const timeoutReference = React.useRef<number>();

  const onMouseOver = React.useCallback(() => {
    if (!isRoute) {
      return;
    }

    // preload route components when user hovers for at least `DELAY` ms
    // over the link to improve experienced speed
    timeoutReference.current = window.setTimeout(() => {
      const route = router.getRouteByURL(to);
      if (isRedirectRoute(route)) {
        return;
      }
      route.preload();
    }, DELAY);

    return () => window.clearTimeout(timeoutReference.current);
  }, [isRoute, timeoutReference, router, to]);

  const onMouseOut = React.useCallback(() => {
    if (!isRoute) {
      return;
    }

    window.clearTimeout(timeoutReference.current);
  }, [isRoute, timeoutReference]);

  return { onMouseOver, onMouseOut };
};
