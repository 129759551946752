import { Router as Router5, State } from 'router5';

import { extractParameterKeysFromRouteNode } from './extractParameterKeysFromRouteNode';

interface Options {
  router5: Router5;
  state1: State;
  state2: State;
  ignoreQueryParameters?: boolean;
}

/**
 * this is a fixed version for router 5 areStatesEqual which is not working with param arrays correctly
 *
 * @see https://github.com/router5/router5/blob/master/packages/router5/modules/core/state.ts:areStatesEqual
 */
export const areStatesEqual = ({
  router5,
  state1,
  state2,
  ignoreQueryParameters = true,
}: Options) => {
  if (state1.name !== state2.name) return false;

  const state1Parameters = ignoreQueryParameters
    ? extractParameterKeysFromRouteNode({ router5, routeName: state1.name })
    : Object.keys(state1.params);
  const state2Parameters = ignoreQueryParameters
    ? extractParameterKeysFromRouteNode({ router5, routeName: state2.name })
    : Object.keys(state2.params);

  return (
    state1Parameters.length === state2Parameters.length &&
    state1Parameters.every((p) => {
      // special case array: in js arrays are object so [1] === [1] will return false.
      // workaround is use JSON.stringify but only if we have an actual array.
      if (Array.isArray(state1.params[p]) && Array.isArray(state2.params[p])) {
        return JSON.stringify(state1.params[p]) === JSON.stringify(state2.params[p]);
      }

      return state1.params[p] === state2.params[p];
    })
  );
};
