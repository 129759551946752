import {
  RouteComponents,
  RouteOptionsComponents,
  RouteScopesWithUnauthorizedLayout,
} from '../Router.types';

interface Options<Scope extends RouteScopesWithUnauthorizedLayout> {
  routeComponents: RouteOptionsComponents<Scope>;
}

export const getRouteComponentsForUnauthorizedLayout = <
  Scope extends RouteScopesWithUnauthorizedLayout,
>(
  options: Options<Scope>,
): RouteComponents<RouteScopesWithUnauthorizedLayout> => {
  const { routeComponents } = options;

  return {
    // Unauthorized layouts only have a required main slot
    main: routeComponents.main,

    // every other slot cannot be set
    breadcrumbs: undefined,
    header: undefined,
    navi: undefined,
  };
};
