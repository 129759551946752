import { ArrowBack as ArrowBackIcon, Menu as MenuIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

import { reactive } from '../../../../../../helpers/reactive';
import { NaviContext } from '../../../../contexts/NaviContext';

const NaviToggleComponent: React.FC = () => {
  const { isOpen, toggleNavi } = React.useContext(NaviContext);
  return (
    <IconButton onClick={toggleNavi} size="large">
      {isOpen ? <ArrowBackIcon /> : <MenuIcon />}
    </IconButton>
  );
};

export const NaviToggle = reactive(NaviToggleComponent);
