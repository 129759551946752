import { RouteParameters, RouteQuery } from '../../services/Router/Router.types';
import { createRoutePath } from '../../services/Router/helpers/createRoutePath';

export const pattern = '/activityLog';

export interface Query extends RouteQuery {
  tab?: string;
}

export const ActivityLogPath = createRoutePath<RouteParameters, Query>({ pattern });
