import { AtrigamUser, AtrigamUserPath, UID } from '@atrigam/atrigam-types';
import { APP_NAME_EU } from '@atrigam/server-functions-eu-client';
import { getApp } from 'firebase/app';
import {
  DocumentData,
  DocumentReference,
  doc,
  getDocFromCache,
  getFirestore,
} from 'firebase/firestore';

interface Options {
  uid: UID;
}

export const getUserCacheQuery = async ({ uid }: Options) => {
  const database = getFirestore(getApp(APP_NAME_EU));

  const path: AtrigamUserPath = `/users/${uid}`;
  const reference = doc(database, path) as DocumentReference<AtrigamUser, DocumentData>;

  try {
    const document = await getDocFromCache(reference);
    return document.data();
  } catch {
    return null;
  }
};
