import { FallbackProps } from 'react-error-boundary';

import { reactive } from '../../../../helpers/reactive';

// eslint-disable-next-line import/no-unassigned-import
import './ErrorFallback.css';

const ErrorFallbackComponent: React.FC<FallbackProps> = ({ resetErrorBoundary }) => (
  <div className="ErrorContainer">
    <div className="ErrorContainer-Outer">
      <div className="ErrorContainer-Paper">
        <div className="ErrorContainer-Logo">
          <img src="/logo.svg" alt="logo" height="30" width="90" />
        </div>

        <p>
          <strong>Oops! An error occured!</strong>
        </p>

        <p>
          A crash report has been sent to the developers. They are on it and fix the bug as soon as
          possible.
        </p>

        <p>
          If you feel like joining in, contact us at:{' '}
          <a href="https://atrigam.com/contact">https://atrigam.com/contact</a>
        </p>

        <p className="ErrorContainer-LastRow">
          With a bit of luck, you can also&nbsp;&nbsp;
          <button color="primary" onClick={resetErrorBoundary} className="ErrorContainer-Button">
            Log in again
          </button>
        </p>
      </div>
    </div>
  </div>
);

export const ErrorFallback = reactive(ErrorFallbackComponent);
