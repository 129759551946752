import { UniverseRegistrationListPageAsync } from '../../pages/UniverseRegistrationList/UniverseRegistrationList.page.async';
import { RouteScope } from '../../services/Router/Router.types';
import { createRoute } from '../../services/Router/helpers/createRoute';

import { pattern } from './universeRegistrationList.path';

export const UniverseRegistrationListRoute = createRoute({
  title: () => 'Universe Registrations',
  components: {
    main: UniverseRegistrationListPageAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,
  meta: {
    noPadding: true,
  },
});
