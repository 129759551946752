import { reactive } from '../../helpers/reactive';
import { ErrorButton } from '../../primitive/Button/ErrorButton';
import { TertiaryButton } from '../../primitive/Button/TertiaryButton';
import { GenericModal } from '../../primitive/GenericModal/GenericModal';

interface Properties {
  deleteLabel?: string;
  message?: string;
  onAfterAll?: () => void;
  onCancel?: () => void;
  onDelete: () => void;
  title: string;
  trigger: (properties: {
    openModal: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  }) => React.ReactNode;
}

const DeleteModalComponent: React.FC<Properties> = ({
  deleteLabel,
  message,
  onAfterAll,
  onCancel: handleCancel,
  onDelete,
  title,
  trigger,
}) => {
  const label = deleteLabel ?? 'Delete';

  return (
    <GenericModal
      message={message}
      onAfterAll={onAfterAll}
      onCancel={handleCancel}
      onSubmit={onDelete}
      title={title}
      trigger={trigger}
      actions={({ onCancel, onSubmit }) => (
        <>
          <TertiaryButton onClick={onCancel} aria-label="Cancel">
            Cancel
          </TertiaryButton>
          <ErrorButton onClick={onSubmit} autoFocus variant="text" aria-label="Delete">
            {label}
          </ErrorButton>
        </>
      )}
    />
  );
};

export const DeleteModal = reactive(DeleteModalComponent);
