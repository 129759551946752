import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';
import {
  APP_NAME_ADMIN as ADMIN_APP_NAME,
  watcherServiceAdmin,
} from '@atrigam/server-functions-admin-client';
import { APP_NAME_EU, watcherServiceEu } from '@atrigam/server-functions-eu-client';
import { getApp } from '@firebase/app';
import { getAuth, signOut } from 'firebase/auth';

import { BACKUP_APP_NAME } from '../firestore/atrigam-backup/atrigam-backup.types';
import { getReducedUserDataSet } from '../helpers/getReducedUserDataSet';
import { LoginPath } from '../routes/login/login.path';
import { Registry } from '../services/Registry/Registry';
import { SentryCategories } from '../services/Sentry/Sentry.types';
import { sentry } from '../services/Sentry/helpers/initializeSentry';

interface Options {
  redirect?: boolean;
}

export const logoutUser = async ({ redirect = true }: Options) => {
  const userStore = Registry.get('userStore');
  userStore.setChangingLoginState(true);

  sentry.addBreadcrumb({
    category: SentryCategories.Router,
    message: `Logging out`,
    data: getReducedUserDataSet(userStore.user),
    level: 'info',
  });

  await track({
    event: AtrigamAnalyticEvents.NAVIGATION_Logout,
    screen: AtrigamAnalyticScreens.Navigation,
  });

  const router = Registry.get('router');
  router.setTransition(true);
  userStore.stopWatchers();

  // additional stores to clear

  // clear user data (will also clear local storage)
  userStore.clearUser();

  watcherServiceEu.get().unsubscribeAll();
  watcherServiceAdmin.get().unsubscribeAll();

  const euAuth = getAuth(getApp(APP_NAME_EU));
  const adminAuth = getAuth(getApp(ADMIN_APP_NAME));
  const backupAuth = getAuth(getApp(BACKUP_APP_NAME));

  void signOut(backupAuth);
  void signOut(adminAuth);
  void signOut(euAuth);
  userStore.setChangingLoginState(false);

  if (!redirect) {
    return;
  }

  // redirect to login
  void router.goTo({
    url: LoginPath.getLink({ params: {}, query: {} }),
    replaceCurrentURL: true,
  });
};
