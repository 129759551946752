import nprogress from 'nprogress/nprogress.css?inline';
import { createGlobalStyle, css } from 'styled-components';

import { ThemedStyledComponent } from '../../../themes/Themes.types';

export const GlobalStyles = createGlobalStyle<ThemedStyledComponent>(
  ({ theme }) => css`
    ${nprogress};

    #nprogress {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: ${theme.zIndex.snackbar};

      .bar {
        background: ${theme.palette.primary.main};
      }
    }
  `,
);
