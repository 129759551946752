import { AllRouteComponents, PreloadableComponent } from '../Router.types';

const tryPreload = (maybeReactLazyComponent: PreloadableComponent) => {
  if (typeof maybeReactLazyComponent === 'object') {
    try {
      // React.lazy() components expose a `_ctor()` method that executes the loading once
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call,  @typescript-eslint/no-unsafe-member-access
      (maybeReactLazyComponent as any)._ctor();
    } catch {}
  }
};

interface Options {
  components: AllRouteComponents;
  preloadableComponents?: Record<string, PreloadableComponent>;
}

export const preloadComponentsForRoute = (options: Options) => {
  const { components, preloadableComponents } = options;

  // todo: preload layout components
  // const layout = getRouteLayout({ scope })

  // check route components for lazy react components and try to preload them
  (Object.keys(components) as (keyof typeof components)[]).forEach((key) => {
    const component = components[key];

    // nothing to preload
    if (component === undefined || typeof component === 'function') {
      return;
    }

    tryPreload(component);
  });

  // check preloadable components and try to preload them
  if (preloadableComponents) {
    Object.keys(preloadableComponents).forEach((key) => {
      const component = preloadableComponents[key];

      tryPreload(component);
    });
  }
};
