import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '../../../themes/Themes.types';

export const AppVersionWrapper = styled.div<ThemedStyledComponent>(
  () => css`
    cursor: default;
    opacity: 0.3;
  `,
);
