import { getApp } from 'firebase/app';
import { User, onAuthStateChanged, getAuth } from 'firebase/auth';

import { APP_NAME_ADMIN } from '../clientSDK.constants.js';

export const getCurrentFirestoreAdminUserQuery = (): Promise<User | null> => {
  const app = getApp(APP_NAME_ADMIN);

  const auth = getAuth(app);
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject,
    );
  });
};
