import React from 'react';

import { Registry } from '../../../services/Registry/Registry';

interface HasFinishLoading {
  finishLoading: () => void;
}

export const useRemoveInitialLoader = () => {
  const router = Registry.get('router');

  React.useEffect(() => {
    if (!router.isReady) {
      return;
    }

    const bodyElement = document.querySelector('body');
    if (bodyElement && (bodyElement as unknown as HasFinishLoading).finishLoading) {
      (bodyElement as unknown as HasFinishLoading).finishLoading();
    }
  }, [router.isReady]);
};
