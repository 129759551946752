import { Box, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import { reactive } from '../../../../helpers/reactive';
import { AppVersion } from '../../../../primitive/AppVersion/AppVersion';
import { Logo } from '../../../../primitive/Logo/Logo';
import { Typography } from '../../../../primitive/Typography/Typography.style';
import { AtrigamTheme } from '../../../../typings/atrigamTheme';

import { LockApp } from './features/LockApp/LockApp';
import { NaviToggle } from './features/NaviToggle/NaviToggle';
import { ThemeSwitcher } from './features/ThemeSwitcher/ThemeSwitcher';
import { UserAvatar } from './features/UserAvatar/UserAvatar';
import { AppVersionWrapper } from './styles/AppVersionWrapper.style';
import { Content } from './styles/Content.style';
import { HeaderWrapper } from './styles/HeaderWrapper.style';

const HeaderComponent: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const theme = useTheme<AtrigamTheme>();
  const breakXs = useMediaQuery(theme.breakpoints.down('sm'));
  const logoWidth = breakXs ? 80 : 120;
  const logoStyle = React.useMemo(
    () => ({ paddingLeft: breakXs ? 0 : 10, paddingTop: 0 }),
    [breakXs],
  );

  return (
    <Box display="flex" flexDirection="column">
      <HeaderWrapper id="app-bar" position="fixed" color="default">
        <Toolbar>
          <NaviToggle />
          <Logo width={logoWidth} style={logoStyle} />
          <Box ml={2}>
            {!breakXs && (
              <Typography variant="h4" weight="light" align="center" color="textSecondary">
                Administration
              </Typography>
            )}
          </Box>
          <AppVersionWrapper>
            <AppVersion />
          </AppVersionWrapper>
          <Content>{children}</Content>
          {!breakXs && <LockApp />}
          <ThemeSwitcher />
          <UserAvatar />
        </Toolbar>
      </HeaderWrapper>
    </Box>
  );
};

export const Header = reactive(HeaderComponent);
