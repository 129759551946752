import { AtrigamAppEnvironment, setupTracking } from '@atrigam/atrigam-tracking';
import { AtrigamApps } from '@atrigam/atrigam-types';

import { getBrowserInfo } from '../../helpers/getBrowserInfo';
import { IS_DEV, IS_PROD, IS_TEST } from '../../helpers/mode';

interface Options {
  appVersion: string;
}

// introduce appEnvironment
let appEnvironment = AtrigamAppEnvironment.Production;
if (IS_DEV) {
  appEnvironment = AtrigamAppEnvironment.Development;
} else if (IS_TEST) {
  appEnvironment = AtrigamAppEnvironment.Testing;
}

export const initializeTracking = ({ appVersion }: Options) => {
  setupTracking({
    app: AtrigamApps.Administration,
    appVersion,
    appEnvironment,
    Browser: getBrowserInfo(),
    uid: 'unregistered',
    isEnabled: IS_PROD,
  });
};
