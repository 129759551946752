import { Theme } from '@mui/material';
import { TypographyVariant } from '@mui/material/styles';

import { TypographySize } from '../Typography.types';

interface Options {
  size?: TypographySize;
  theme: Theme;
  variant?: TypographyVariant | 'inherit' | 'srOnly';
}

export const getFontSize = ({ size, variant, theme }: Options) => {
  let multiplier;

  switch (size) {
    case 'sm': {
      multiplier = 0.8;
      break;
    }
    case 'md': {
      multiplier = 1.2;
      break;
    }
    case 'lg': {
      multiplier = 1.5;
      break;
    }
    case 'xl': {
      multiplier = 2;
      break;
    }
    case 'xxl': {
      multiplier = 3;
      break;
    }
    default: {
      multiplier = 1;
      break;
    }
  }

  // edge case
  if (variant === 'inherit' || variant === 'srOnly') {
    return `calc(${String(theme.typography.fontSize)}px * ${multiplier})`;
  }

  const defaultSize =
    variant && theme.typography[variant]
      ? theme.typography[variant].fontSize
      : String(theme.typography.fontSize) + 'px';

  return `calc(${String(defaultSize)} * ${multiplier})`;
};
