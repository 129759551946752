import { createAtrigamUniverseNodeName } from '../helpers/createAtrigamUniverseNodeName';
import { AtrigamObjectName } from '../objectModel.types';
import {
  AtrigamEnvironment,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
} from '../universe.types';
import {
  ATRIGAM_PROFILE_ENVIRONMENT,
  ATRIGAM_PROFILE_UNIVERSE,
  ATRIGAM_PROFILE_OBJECT_NAME,
} from '../user.profile.types';

interface Options {
  environment: AtrigamEnvironment;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName | AtrigamUniverseNodeName;
}

const ATRIGAM_PROFILE_UNIVERSE_LOWERCASE = createAtrigamUniverseNodeName(ATRIGAM_PROFILE_UNIVERSE);

export const isAtrigamUserProfilePath = ({ environment, objectName, universe }: Options) => {
  if (environment !== ATRIGAM_PROFILE_ENVIRONMENT) {
    return false;
  }

  if (universe !== ATRIGAM_PROFILE_UNIVERSE && universe !== ATRIGAM_PROFILE_UNIVERSE_LOWERCASE) {
    return false;
  }

  return objectName === ATRIGAM_PROFILE_OBJECT_NAME;
};
