import { ActivityLogPageAsync } from '../../pages/ActivityLog/ActivityLog.page.async';
import { RouteScope } from '../../services/Router/Router.types';
import { createRoute } from '../../services/Router/helpers/createRoute';

import { pattern } from './activityLog.path';

export const ActivityLogRoute = createRoute({
  title: () => 'Activity Log',
  components: {
    main: ActivityLogPageAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,
  meta: {},
});
