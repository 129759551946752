// firestore EU
const firestoreEUConfig = {
  apiKey: 'AIzaSyA9pfdDaEJZ6RhU2GDkmMfGncXuxsAfTrk',
  authDomain: 'atrigam-eu.firebaseapp.com',
  databaseURL: 'https://atrigam-eu.firebaseio.com',
  projectId: 'atrigam-eu',
  storageBucket: 'atrigam-eu-universe-data',
  messagingSenderId: '694979860485',
  appId: '1:694979860485:web:58759e2d4e185f40da246c',
  persistence: true,
};

// firestore Admin
const firestoreAdminConfig = {
  apiKey: 'AIzaSyBU3cfQV_qnHjFrYWapS1mw4aUNCupTJBs',
  authDomain: 'atrigam-admin.firebaseapp.com',
  projectId: 'atrigam-admin',
  storageBucket: 'atrigam-admin.appspot.com',
  messagingSenderId: '542189670073',
  persistence: false,
  databaseURL: 'https://atrigam-admin.firebaseio.com',
};

// Backup
const firestoreBackupConfig = {
  apiKey: 'AIzaSyD-t8UBGeXoKnNFdQwNTto4TCXK7Zm1eNs',
  authDomain: 'atrigam-backup.firebaseapp.com',
  projectId: 'atrigam-backup',
  persistence: false,
  databaseURL: 'https://atrigam-backup.firebaseio.com',
};

// Universes Data via Firestore
export const firestoreConfig = {
  firestoreEUConfig,
  firestoreAdminConfig,
  firestoreBackupConfig,
};
