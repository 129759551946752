import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamTaskFlowName,
  AtrigamUniverseName,
} from '@atrigam/atrigam-types';
import { ref } from 'firebase/database';

import { getAtrigamModelsDatabase } from '../helpers/getAtrigamModelsDatabase';
import { createAtrigamModelsModelFlowPath } from '../paths/createAtrigamModelsModelFlowPath';

interface Options {
  area: AtrigamAreaName;
  environment: AtrigamEnvironment;
  taskFlow: AtrigamTaskFlowName;
  universe: AtrigamUniverseName;
}

export const getFlowModelQueryReference = (options: Options) => {
  const database = getAtrigamModelsDatabase();

  const path = createAtrigamModelsModelFlowPath(options);
  return ref(database, path);
};
