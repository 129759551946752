import { AtrigamFirestorePlatformStatusDocument, throwIfNullable } from '@atrigam/atrigam-types';

import { createAtrigamFirestorePlatformStatusPath } from '../firestore/paths/createAtrigamFirestorePlatformStatusPath';
import { WatcherFunction } from '../types/watchers.types';
import { watcherServiceEu } from '../watcherService';

export const watchPlatformStatus: WatcherFunction<AtrigamFirestorePlatformStatusDocument> = ({
  onError,
  onUpdate,
}) => {
  const firestoreService = watcherServiceEu.get();
  const path = createAtrigamFirestorePlatformStatusPath();

  return firestoreService.subscribeToDocument<AtrigamFirestorePlatformStatusDocument>({
    path,
    onSnapshot: (snapshot, key) => {
      const document = snapshot.data();
      throwIfNullable('platform status is not available', document);

      void onUpdate(document, key);
    },
    onError,
  });
};
