import { AtrigamPhoneNumber, Email } from '@atrigam/atrigam-types';

interface Options {
  company?: string;
  email?: Email | null;
  firstname?: string;
  lastname?: string;
  phoneNumber?: AtrigamPhoneNumber | null;
}

export const getName = ({ firstname, lastname, company, email, phoneNumber }: Options) => {
  const displayNameParts: string[] = [];

  if (firstname) {
    displayNameParts.push(firstname);
  }

  if (lastname) {
    displayNameParts.push(lastname);
  }

  let displayName = displayNameParts.join(' ');
  if (company) {
    if (displayName.length > 0) {
      displayName += ', ';
    }
    displayName += company;
  }

  // nothing
  if (displayName.length === 0) {
    return email ?? phoneNumber ?? ''; // last one is just for typescript
  }

  return displayName;
};
