import { throwIfNullable } from '@atrigam/atrigam-types';
import { detect as detectBrowser } from 'detect-browser';

type Browser = NonNullable<ReturnType<typeof detectBrowser>>;

let browser: Browser;
export const getBrowser = (): Browser => {
  if (!browser) {
    const detectedBrowser = detectBrowser();
    throwIfNullable('detectedBrowser', detectedBrowser);

    browser = detectedBrowser;
  }

  return browser;
};
