import {
  AtrigamDatabaseUniverse,
  AtrigamUniverseId,
  AtrigamUniverseName,
  AtrigamUniverseNodeName,
} from '../universe.types';

interface Options {
  universe: AtrigamUniverseName | AtrigamUniverseNodeName | AtrigamUniverseId;
}

const UNIVERSE_DB_PREFIX = 'atrigam';

export const createAtrigamDatabaseUniverse = ({ universe }: Options) =>
  `${UNIVERSE_DB_PREFIX}-${universe.toLowerCase()}` as AtrigamDatabaseUniverse;
