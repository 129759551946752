import { create } from 'mobx-persist';

import { Registry } from '../services/Registry/Registry';

interface Options {
  jsonify: boolean; // set to true if you have plain objects that are recursive
}

export const createMobxHydrator = ({ jsonify }: Options) => {
  // use localforage for persistance
  const storage = Registry.get('storage').getStorage();

  return create({
    storage,
    jsonify, // if you use AsyncStorage, here shoud be true
    debounce: 300,
  });
};
