import { Path } from 'path-parser';

import { RouteParameters } from '../Router.types';

interface Options {
  allowNonSSL?: boolean;
  pattern: string;
}

// path-parser is the path parsing library router5 is using underneath,
// see https://router5.js.org/guides/path-syntax
//
// It mostly allows for the exact same syntax
// e.g. /something/:clientId for named parameters.
//
// One major difference though is, that all parameters are required per route path.
// /something/:clientId/:filter? for an optional filter parameter is not possible anymore.
// Instead optional parameters need to be moved to the query part of the route.
// So the route path remains /something/:clientId but it can also have ?filter=... etc.
export const createPathBuilder = <Parameters extends RouteParameters | undefined>({
  allowNonSSL = false,
  pattern,
}: Options) => {
  // we're not allowed to use insecure http links anyways
  // because of our CSP headers
  if (!allowNonSSL && pattern.startsWith('http://')) {
    throw new Error(`Please use the secure https protocol for link "${pattern}"`);
  }

  if (pattern.startsWith('/') && pattern.includes('?')) {
    throw new Error(
      `Using an optional route parameter is not allowed in "${pattern}". Please use a query instead.`,
    );
  }

  return (options?: Parameters): string => {
    if (pattern.startsWith('https://') || pattern.startsWith('http://')) {
      const { origin, pathname } = new URL(pattern);

      return `${origin}${Path.createPath(pathname).build(options)}`;
    }

    return Path.createPath(pattern).build(options);
  };
};
