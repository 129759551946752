import {
  AtrigamAppStatus,
  AtrigamApplicationVersion,
  AtrigamFirestorePlatformStatusDocument,
  throwIfNullable,
  type FirestoreTimestamp,
} from '@atrigam/atrigam-types';
import { watchApplicationVersion, watchPlatformStatus } from '@atrigam/server-functions-eu-client';
import { action, computed, makeObservable, observable } from 'mobx';

import { getGlobalState } from '../../helpers/getGlobalState';

import { isSemverGreater } from './helpers/isSemverGreater';

export class AppStore {
  @observable
  appStatus?: AtrigamAppStatus;

  @observable
  appVersion: string;

  @observable
  estimatedDowntimeEnd?: FirestoreTimestamp;

  @observable
  status?: string;

  @observable
  newVersionAvailable?: string;

  @observable
  termsAndConditions = '';

  constructor() {
    makeObservable(this);

    const { appVersion } = getGlobalState();
    this.appVersion = appVersion;
    watchPlatformStatus({ onUpdate: this.setPlatformStatus });
    watchApplicationVersion({ onUpdate: this.setApplicationVersion, app: 'administration' });
  }

  @computed
  get isLocked() {
    return this.appStatus && this.appStatus === AtrigamAppStatus.Locked;
  }

  @action
  setPlatformStatus = (platform?: AtrigamFirestorePlatformStatusDocument) => {
    throwIfNullable('platform status is not available', platform);

    this.appStatus = platform.appStatus;
    this.estimatedDowntimeEnd = platform.estimatedDowntimeEnd;
    this.status = platform.status;
  };

  @action
  setApplicationVersion = (appVersion?: AtrigamApplicationVersion) => {
    if (appVersion && isSemverGreater(this.appVersion, appVersion.version)) {
      this.newVersionAvailable = appVersion.version;
    }
  };

  @action
  setLocked = (locked: boolean) => {
    this.appStatus = locked ? AtrigamAppStatus.Locked : AtrigamAppStatus.Open;
  };
}
