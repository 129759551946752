/* eslint-disable @typescript-eslint/no-unsafe-return */
import { MockableRegistryType, Services, ServiceCreator, ServiceInstance } from '../Registry.types';

import { DestructibleService } from './DestructibleService';
import { createRegistry } from './createRegistry';

type GetMock<S extends Services> = <K extends keyof S>(key: K) => ServiceInstance<K, S>;

export const createMockableRegistry = <S extends Services>(
  testing: true,
): MockableRegistryType<S> => {
  const internalRegistry = createRegistry<S>(testing);
  const mockCreators = new Map<keyof S, ServiceCreator<keyof S, S>>();
  const mockInstances = new Map<keyof S, ServiceInstance<keyof S, S>>();

  if (!testing) {
    throw new Error(`createMockableRegistry() can only be called in a test environment.`);
  }

  const getMock: GetMock<S> = (key) => {
    const createInstance = mockCreators.get(key);
    if (!createInstance) {
      throw new Error(`Registry mock "${key as string}" was not set yet.`);
    }

    if (!mockInstances.get(key)) {
      mockInstances.set(key, createInstance());
    }

    const instance = mockInstances.get(key);
    if (!instance) {
      throw new Error(`Registry mock "${key as string}" failed to instantiate.`);
    }

    return instance;
  };

  const mock: MockableRegistryType<S>['mock'] = (key, creator) => {
    // clear previous mocks
    restore(key);

    // create new mock
    mockCreators.set(key, creator);

    return getMock(key);
  };

  const restore: MockableRegistryType<S>['restore'] = (key) => {
    // call `_destruct` on instance if it exists before clearing it
    const mockedInstance = mockInstances.get(key);
    if (mockedInstance && typeof (mockedInstance as DestructibleService)._destruct === 'function') {
      (mockedInstance as DestructibleService)._destruct();
    }

    mockCreators.delete(key);
    mockInstances.delete(key);
  };

  // override internal registry method
  const get: MockableRegistryType<S>['get'] = (key) => {
    // let a mocked value overwrite existing values
    if (mockCreators.has(key)) {
      return getMock(key);
    }

    return internalRegistry.get(key);
  };

  const hasCreator: MockableRegistryType<S>['hasCreator'] = (key) => {
    return mockCreators.has(key) || internalRegistry.hasCreator(key);
  };

  const hasInstance: MockableRegistryType<S>['hasInstance'] = (key) => {
    return mockInstances.has(key) || internalRegistry.hasInstance(key);
  };

  const clear: MockableRegistryType<S>['clear'] = (key) => {
    internalRegistry.clear(key);
    mockInstances.delete(key);
  };

  const remove: MockableRegistryType<S>['remove'] = (key) => {
    clear(key);
    internalRegistry.remove(key);
    mockCreators.delete(key);
  };

  return {
    get,
    hasCreator,
    hasInstance,
    remove,

    // pass through to internal registry
    set: internalRegistry.set,
    clear,

    // testing
    mock,
    restore,
  };
};
