import { Typography as TypographyBase, TypographyTypeMap } from '@mui/material';
import styled from 'styled-components';

import { ThemedStyledComponent } from '../../themes/Themes.types';

import {
  TypographyColorBrightness,
  TypographySize,
  // TypographyColor,
  TypographyWeight,
} from './Typography.types';
import { getFontSize } from './helpers/getFontSize';
import { getFontWeight } from './helpers/getFontWeight';

interface Properties {
  colorBrightness?: TypographyColorBrightness;
  size?: TypographySize;
  weight?: TypographyWeight;
}

export const Typography = styled(TypographyBase)<TypographyTypeMap<Properties>['props']>`
  font-weight: ${({ weight }) => getFontWeight({ weight })};
  font-size: ${({
    size,
    variant,
    theme,
  }: TypographyTypeMap<Properties>['props'] & ThemedStyledComponent) =>
    getFontSize({ size, variant, theme })};
`;
