import { throwIfNullable } from '@atrigam/atrigam-types';

export const MAIN_ID = 'main';

export const getMainElement = () => {
  const $main = document.querySelector(`#${MAIN_ID}`);
  throwIfNullable('#main element', $main);

  return $main;
};
