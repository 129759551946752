import { HomePageAsync } from '../../pages/Home/Home.page.async';
import { RouteScope } from '../../services/Router/Router.types';
import { createRoute } from '../../services/Router/helpers/createRoute';

import { pattern } from './home.path';

export const HomeRoute = createRoute({
  title: () => 'Home',
  components: {
    main: HomePageAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,
});
