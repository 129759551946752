/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

const SERVICE_WORKER_INTERVAL_TIMEOUT = 30_000; // every 30 seconds

interface Options {
  serviceWorkerRegistration: React.MutableRefObject<ServiceWorkerRegistration | undefined>;
}

export const useServiceWorkerUpdate = ({ serviceWorkerRegistration }: Options) => {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      r &&
        setInterval(async () => {
          if (!(!r.installing && navigator)) {
            return;
          }

          if ('connection' in navigator && !navigator.onLine) {
            return;
          }

          try {
            // make sure we are online
            const resp = await fetch(swUrl, {
              cache: 'no-store',
              headers: {
                cache: 'no-store',
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'cache-control': 'no-cache',
              },
            });

            if (resp?.status === 200) {
              await r.update();
            }
          } catch {}
        }, SERVICE_WORKER_INTERVAL_TIMEOUT);

      if (r) {
        serviceWorkerRegistration.current = r;
      }
    },
    onRegisterError(error) {
      // eslint-disable-next-line no-console
      console.log('SW registration error', error);
    },
  });

  return {
    needRefresh,
    updateServiceWorker,
  };
};
