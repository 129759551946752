import type {
  AtrigamModelsModelFlow,
  AtrigamModelsModelFlowStepInteraction,
  AtrigamUser,
} from '@atrigam/atrigam-types';
import { action, computed, makeObservable, observable } from 'mobx';

interface Options {
  user: AtrigamUser;
  model: AtrigamModelsModelFlow;
}

export class UserPageStore {
  @observable
  user: AtrigamUser;

  @observable
  model: AtrigamModelsModelFlow;

  constructor({ user, model }: Options) {
    makeObservable(this);

    this.user = user;
    this.model = model;
  }

  @computed
  get interactions() {
    const interactions: AtrigamModelsModelFlowStepInteraction[] = [];

    Object.values(this.model.steps)
      .sort((a, b) => a.sequence - b.sequence)
      .forEach((step) => {
        if (!step.name || !step.interactions) {
          return;
        }

        Object.values(step.interactions)
          .sort((a, b) => a.sequence - b.sequence)
          .forEach((interaction) => {
            interactions.push(interaction);
          });
      });

    return interactions.filter(
      (interaction) =>
        !['Email', 'Phone Number', 'Upload Profile Picture'].includes(interaction.name),
    );
  }

  @action
  updateUser = (user: AtrigamUser) => {
    this.user = user;
  };
}
