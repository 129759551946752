import { configure as configureMobX } from 'mobx';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './app/App/App';
import { sentryConfiguration } from './configuration/sentry.config';
import { IS_DEV } from './helpers/mode';
import { initializeFirebase } from './services/FireBase/initializeFirebase';
// import reportWebVitals from './reportWebVitals';
import { Registry } from './services/Registry/Registry';
import { setupRegistry } from './services/Registry/setupRegistry';
import { initializeSentry } from './services/Sentry/helpers/initializeSentry';
import { initializeTracking } from './services/Tracking/initializeTracking';

// initialize Sentry first to catch all errors
initializeSentry(IS_DEV ? false : sentryConfiguration);

initializeFirebase();

const render = () => {
  const container = document.querySelector('#root');
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
};

const init = () => {
  // configure mobx
  // @see https://mobx.js.org/configuration.html
  configureMobX({
    enforceActions: 'observed',
    // todo disabled for now, need to investigate
    // computedRequiresReaction: true,
    // reactionRequiresObservable: true,
    // observableRequiresReaction: true,
    // disableErrorBoundaries: true,
  });

  initializeTracking({
    appVersion: window.__GLOBAL_STATE__.appVersion,
  });

  setupRegistry();

  // initialize activity before analytics
  // Registry.get('activity');

  // initialize analtyics
  // Registry.get('analytics');

  // watch app for if it is locked
  Registry.get('appStore');

  // make sure user is there
  Registry.get('userStore');

  // start router
  Registry.get('router').start();

  render();
};

init();
