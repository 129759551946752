import styled from 'styled-components';

import { ThemedStyledComponent } from '../../../../../../../themes/Themes.types';

export const MobileBackButtonWrapper = styled.div<ThemedStyledComponent>(
  ({ theme }: ThemedStyledComponent) => `
  margin-top: ${theme.spacing(0.5)};
  margin-left: ${theme.spacing(3)};
  
  ${theme.breakpoints.only('sm')} {
    margin-top: ${theme.spacing(0.625)};
  };

  ${theme.breakpoints.up('md')} {
    display: none;
  };
`,
);
