/* eslint-disable unicorn/prefer-array-flat */
import { Router as Router5 } from 'router5';

interface Options {
  router5: Router5;
  routeName: string;
}

/**
 * This has been extracted from router5 internals `areStatesEqual`
 *
 * @see https://github.com/router5/router5/blob/master/packages/router5/modules/core/state.ts
 */
export const extractParameterKeysFromRouteNode = ({ router5, routeName }: Options) =>
  router5.rootNode.children
    // find all nodes for the name and with parser present
    .filter((node) => node.name === routeName && node.parser !== null)
    // get just the urlParams (here are the params stored)
    .map((segment) => segment.parser!.urlParams)
    // concat them together
    .reduce((parameters, p) => parameters.concat(p), []);
