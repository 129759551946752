import { AtrigamAppStatus } from '@atrigam/atrigam-types';
import { FormControlLabel, Switch } from '@mui/material';
import React from 'react';

import { updatePlatformStatusMutation } from '../../../../../../firestore/atrigam-eu/mutations/updatePlatformStatus.mutation';
import { reactive } from '../../../../../../helpers/reactive';
import { DeleteModal } from '../../../../../../patterns/DeleteModal/DeleteModal';
import { Typography } from '../../../../../../primitive/Typography/Typography.style';
import { sendTeamsMessageToDevChannel as sendTeamsMessageToDevelopmentChannel } from '../../../../../../services/MSTeams/MSTeams';
import { Registry } from '../../../../../../services/Registry/Registry';

interface Properties {
  showLabel?: boolean;
}

const LockAppComponent: React.FC<Properties> = ({ showLabel = true }) => {
  const { isLocked } = Registry.get('appStore');
  const { userOrFail: user } = Registry.get('userStore');

  const unlockPlatform = React.useCallback(async () => {
    await updatePlatformStatusMutation({ appStatus: AtrigamAppStatus.Open });

    await sendTeamsMessageToDevelopmentChannel({
      title: 'App has been unlocked.',
      message: `${user.name} has unlocked the platform.`,
    });
  }, [user.name]);

  const lockPlatform = React.useCallback(async () => {
    await updatePlatformStatusMutation({ appStatus: AtrigamAppStatus.Locked });

    await sendTeamsMessageToDevelopmentChannel({
      title: 'App has been LOCKED!',
      message: `${user.name} has locked the platform.`,
    });
  }, [user.name]);

  return (
    <DeleteModal
      onDelete={lockPlatform}
      title="Lock Platform"
      message="Do you really want to lock the platform?"
      deleteLabel="Lock Platform"
      trigger={({ openModal }) => (
        <FormControlLabel
          control={
            <Switch
              name="Lock App"
              checked={isLocked}
              onChange={() => (isLocked ? unlockPlatform() : openModal())}
              color="error"
            />
          }
          label={
            showLabel ? (
              isLocked ? (
                <Typography color="error">App is locked now!</Typography>
              ) : (
                'Lock App'
              )
            ) : (
              ''
            )
          }
          labelPlacement="start"
        />
      )}
    />
  );
};

export const LockApp = reactive(LockAppComponent);
