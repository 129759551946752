import { URI } from '@atrigam/atrigam-types';

interface Image {
  miduri?: URI; // biggest
  minuri?: URI; // medium
  thumburi?: URI; // smalles
  uri?: URI; // original
}

export const getSmallestFromImage = (image?: Image) => {
  if (!image) {
    return;
  }

  if (image.thumburi) {
    return image.thumburi;
  }

  if (image.minuri) {
    return image.minuri;
  }

  if (image.miduri) {
    return image.miduri;
  }

  return image.uri;
};
